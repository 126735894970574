import React from "react";
import styled from "styled-components";
import { FieldProps } from "./Field";
import Text from "./Text";
import { Color } from "../types";

export interface InlineInputProps
  extends FieldProps,
    React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  symbol?: string;
  boldLabel?: boolean;
  boldValue?: boolean;
  plaintext?: boolean;
  color?: Color;
}

export const StyledInlineInput = styled.label<{
  symbol?: string;
  $color?: Color;
  $boldValue?: boolean;
}>`
  align-items: center;
  color: ${({ $color }) => `var(--${$color || "primary"})`};
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  position: relative;
  width: 100%;

  * {
    color: ${({ $color }) => `var(--${$color || "primary"})`};
    flex: 0 1 auto;
  }

  input,
  .plaintext-value {
    background-color: var(--white);
    border: 1px solid ${({ $color }) => `var(--${$color || "primary"})`};
    color: ${({ $color }) => `var(--${$color || "primary"})`};
    border-radius: 0.25rem;
    box-sizing: border-box;

    font-weight: ${({ $boldValue }) => ($boldValue ? "400" : "300")};
    font-size: 1.1rem;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    width: calc(100px + 0.5rem);
  }

  .plaintext-value {
    border-color: transparent;
  }
`;

const InlineInput = ({
  id,
  label,
  symbol,
  boldLabel,
  boldValue,
  color,
  plaintext,
  type = "text",
  ...props
}: InlineInputProps) => {
  return (
    <StyledInlineInput
      htmlFor={id}
      symbol={symbol}
      $color={color}
      $boldValue={boldValue}
    >
      <Text
        color={color}
        style={{ paddingBottom: 0 }}
        weight={boldLabel ? 400 : 300}
        size="1rem"
      >
        {label}
      </Text>
      {plaintext ? (
        <div id={id} className="plaintext-value">
          {props.value}
        </div>
      ) : (
        <input id={id} type={type} {...props} />
      )}
    </StyledInlineInput>
  );
};

export default InlineInput;
