import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Amplify, { Auth, Hub } from "aws-amplify";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import { v4 as uuidv4 } from "uuid";
import { toast, ToastContainer } from "react-toastify/dist/react-toastify.umd";
import ahoy from "ahoy.js";
import Footer from "./components/Footer";
import useCookies from "./hooks/useCookies";
import awsconfig from "./aws-exports";
import { AppContext } from "./components/AppContext";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Routes from "./Routes";
import LoginModal from "./pages/modals/LoginModal";
import ContactUsModal from "./pages/modals/ContactUsModal";
import CostSegModal from "./pages/modals/CostSegModal";
import Layout from "./components/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add this import
import ScrollToTop from "./components/ScrollToTop";
import CookieConsentModal from "./pages/modals/CookieConsentModal";
import RouterScrollToTop from "./components/RouterScrollToTop";
import "react-toastify/dist/ReactToastify.css";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");

const [productionRedirectSignOut, localRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(",");

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        ),
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        )
  },
  ssr: true
});

const StyledApp = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: 300;
  }
  h6 {
    font-weight: 200;
    font-size: 1rem;
    padding: 1rem;
  }
  @media (max-width: 800px) {
    .CookieConsent {
      display: block !important;
      // bottom: 2rem !important;
      div {
        margin: 1rem !important;
        flex: 0 !important;
      }
    }
  }
`;

ahoy.debug(true);

// Update the trackPageView function type definition
const trackPageView = (
  userId: string | null | undefined,
  anonUserId: string | null | undefined
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const properties = {
    // Basic URL Info
    url: window.location.href,
    path: window.location.pathname,
    title: document.title,
    referrer: document.referrer,
    search_query: searchParams.toString(),
    referring_domain: new URL(document.referrer || window.location.href)
      .hostname,

    // Device & Browser Info
    user_agent: navigator.userAgent,
    browser_language: navigator.language,
    browser_languages: navigator.languages?.join(","),
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    viewport_width: window.innerWidth,
    viewport_height: window.innerHeight,
    pixel_ratio: window.devicePixelRatio,
    is_mobile: /Mobile|Android|iOS|iPhone|iPad|iPod/i.test(navigator.userAgent),

    // Connection Info
    connection_type: (navigator as any).connection?.effectiveType || undefined,
    connection_speed: (navigator as any).connection?.downlink || undefined,

    // Time Info
    timestamp: new Date().toISOString(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,

    // Platform Info
    platform: "web",
    os_platform: navigator.platform,
    vendor: navigator.vendor,

    // User Context
    user_id: userId || undefined,
    anon_user_id: anonUserId || undefined,

    // Page Performance
    page_load_time:
      window.performance?.timing?.loadEventEnd -
      window.performance?.timing?.navigationStart,

    // Session Info
    is_new_session: !document.referrer,

    // UTM Parameters
    utm_source: searchParams.get("utm_source") || undefined,
    utm_medium: searchParams.get("utm_medium") || undefined,
    utm_campaign: searchParams.get("utm_campaign") || undefined,
    utm_term: searchParams.get("utm_term") || undefined,
    utm_content: searchParams.get("utm_content") || undefined
  };

  ahoy.trackView(properties);
};

function App() {
  const { setUser, user } = useContext(AppContext);
  const [formData, setFormData] = useState();
  const [anonUserId, setAnonUserId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const { getCookie, setCookie, getAllCookies } = useCookies();

  const cookies: Record<string, string> = {};
  getAllCookies().forEach(([name, val]) => {
    cookies[name] = val;
  });
  useEffect(() => {
    console.log("just triggering a change");
    const isMounted = { current: true }; // Flag to track if the component is mounted

    const authListener = Hub.listen("auth", ({ payload: { event, data } }) => {
      if (!isMounted.current) return; // Prevent state updates if unmounted

      switch (event) {
        case "signIn": {
          setUser(data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }

          break;
        }
        case "signOut":
          setUser(undefined);
          break;
        case "customOAuthState": {
          setFormData(JSON.parse(data));
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
          break;
        }
        default:
          break;
      }

      switch (event) {
        default: {
          console.log("Hub", data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
        }
      }
    });

    Auth.currentAuthenticatedUser()
      .then((newUser) => {
        if (isMounted.current) {
          console.log({ user: newUser });
          setUser(newUser);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          console.log(err);
        }
      });

    return () => {
      isMounted.current = false; // Set flag to false on unmount
      Hub.remove("auth", authListener); // Clean up the listener
    };
  }, []);

  useEffect(() => {
    const initializeUserId = async () => {
      try {
        // Check if user is authenticated
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          setUserId(currentUser.attributes.sub);
          setAnonUserId(null); // Clear anonUserId when user is authenticated
        } else {
          // For unauthenticated users, use or create an anon_user_id
          let existingAnonUserId = getCookie("anon_user_id");
          if (!existingAnonUserId) {
            existingAnonUserId = uuidv4();
            setCookie("anon_user_id", existingAnonUserId);
            setCookie("str_pred_query_count", "0");
          }
          setAnonUserId(existingAnonUserId);
          setUserId(null);
        }
      } catch (error) {
        // Handle unauthenticated state
        let existingAnonUserId = getCookie("anon_user_id");
        if (!existingAnonUserId) {
          existingAnonUserId = uuidv4();
          setCookie("anon_user_id", existingAnonUserId);
          setCookie("str_pred_query_count", "0");
        }
        setAnonUserId(existingAnonUserId);
        setUserId(null);
      }
    };

    initializeUserId();
  }, [user]); // Add user as dependency to reinitialize when auth state changes

  useEffect(() => {
    async function updateUser() {
      await Auth.updateUserAttributes(user, formData as any);
    }
    if (formData && user) {
      updateUser();
    }
  }, [formData, user]);

  useEffect(() => {
    const font = new FontFaceObserver("Source Sans Pro", {
      weight: 400
    });

    font.load().then(() => {
      document.body.classList.add("font-loaded");
    });
  }, []);

  useEffect(() => {
    const mounted = { current: true };

    // Check for Prerender bot
    const isPrerenderBot = navigator.userAgent.includes(
      "Prerender (+https://github.com/prerender/prerender)"
    );

    // Exit early if it's the Prerender bot
    if (isPrerenderBot) {
      // No cleanup needed for prerender bot
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      return () => {};
    }

    if (userId || anonUserId) {
      const landingInfo = JSON.parse(
        localStorage.getItem("landingInfo") || "{}"
      );
      const referringUrl = document.referrer || window.location.href;
      const referringDomain = new URL(referringUrl).hostname;

      ahoy.configure({
        urlPrefix: "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1",
        visitsUrl: "/websitevisits",
        eventsUrl: "/websitevisits/events",
        cookieDomain: isLocalhost ? null : "getchalet.com",
        trackVisits: true,
        withCredentials: false,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        visitParams: {
          // User identification (existing)
          user_id: userId || undefined,
          anon_user_id: anonUserId || undefined,

          // Traffic source
          referrer: document.referrer,
          referring_domain: referringDomain,
          landing_page: landingInfo.landing_path,
          utm_source: landingInfo.utm_source,
          utm_medium: landingInfo.utm_medium,
          utm_campaign: landingInfo.utm_campaign,
          utm_term: landingInfo.utm_term,
          utm_content: landingInfo.utm_content,

          // Technology
          browser: navigator.userAgent,
          browser_language: navigator.language,
          operating_system: navigator.platform,
          device_type: /Mobile|Android|iOS|iPhone|iPad|iPod/i.test(
            navigator.userAgent
          )
            ? "mobile"
            : "desktop",
          screen_resolution: `${window.screen.width}x${window.screen.height}`,
          viewport_size: `${window.innerWidth}x${window.innerHeight}`,
          pixel_ratio: window.devicePixelRatio,

          // We'll need to get location data from an IP geolocation service
          // This would typically be handled server-side or through a separate API call
          // country: undefined,  // To be filled server-side
          // region: undefined,   // To be filled server-side
          // city: undefined,     // To be filled server-side
          // latitude: undefined, // To be filled server-side
          // longitude: undefined,// To be filled server-side

          // Additional context
          timestamp: new Date().toISOString(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      });

      // Start tracking
      console.log("Starting Ahoy tracking...");
      ahoy.start();

      if (mounted.current) {
        ahoy.trackView({
          url: window.location.href,
          path: window.location.pathname,
          title: document.title,
          user_id: userId || undefined,
          anon_user_id: anonUserId || undefined
        });

        ahoy.track("app_loaded", {
          timestamp: new Date().toISOString(),
          environment: process.env.NODE_ENV,
          user_agent: navigator.userAgent,
          language: navigator.language,
          platform: "web",
          user_id: userId || undefined,
          anon_user_id: anonUserId || undefined
        });
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [userId, anonUserId]);

  // Add this useEffect for landing info tracking
  useEffect(() => {
    const { location } = window;
    const searchParams = new URLSearchParams(location.search);
    const { pathname } = location;
    const market = pathname.split("/")[2]; // Adjust this based on your route structure

    const landingInfo = {
      landing_path: pathname,
      path: pathname,
      subquery: market,
      utm_source: searchParams.get("utm_source") || "",
      utm_medium: searchParams.get("utm_medium") || "",
      utm_campaign: searchParams.get("utm_campaign") || "",
      utm_term: searchParams.get("utm_term") || "",
      utm_content: searchParams.get("utm_content") || ""
    };

    // Preserve existing data if it exists
    const existingInfo = JSON.parse(
      localStorage.getItem("landingInfo") || "{}"
    );

    // Only update UTM params if they exist in the current URL
    const newInfo = {
      ...existingInfo,
      ...landingInfo,
      // Preserve existing UTM params if new ones are empty
      utm_source:
        searchParams.get("utm_source") || existingInfo.utm_source || "",
      utm_medium:
        searchParams.get("utm_medium") || existingInfo.utm_medium || "",
      utm_campaign:
        searchParams.get("utm_campaign") || existingInfo.utm_campaign || "",
      utm_term: searchParams.get("utm_term") || existingInfo.utm_term || "",
      utm_content:
        searchParams.get("utm_content") || existingInfo.utm_content || "",
      landing_path: existingInfo.landing_path || pathname
    };

    localStorage.setItem("landingInfo", JSON.stringify(newInfo));
  }, []); // Run once on mount to capture initial landing

  // Add this useEffect for path updates
  useEffect(() => {
    const handleRouteChange = () => {
      const existingInfo = JSON.parse(
        localStorage.getItem("landingInfo") || "{}"
      );
      localStorage.setItem(
        "landingInfo",
        JSON.stringify({
          ...existingInfo,
          path: window.location.pathname
        })
      );
    };

    // Listen for route changes
    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <Layout>
      <StyledApp>
        <Helmet>
          <link rel="canonical" href="https://www.getchalet.com" />
          <title>
            Chalet | Short-Term &amp; Vacation Rental Investment Marketplace
          </title>
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content="Chalet is the largest resource hub for everything short-term rentals.
              Find Airbnb Realtors and other STR Service providers. Invest in Short-Term (Airbnb) rentals with confidence"
          />
          <meta
            property="og:title"
            content="Chalet | Short-Term &amp; Vacation Rental Investment Marketplace"
          />
          <meta
            property="og:description"
            content="Chalet empowers investors with data insights and industry experts.
              Find Realtors, Property Managers, Airbnb laws, and other resources with our all-in-one platform."
          />
          <meta property="og:image" content="" />
          <meta property="og:url" content="https://www.getchalet.com" />
          <meta property="og:site_name" content="Chalet" />
        </Helmet>
        <ToastContainer position="bottom-right" hideProgressBar />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <RouterScrollToTop />
          <Navbar />
          <Hero />
          <Routes />
          {/* <Delayed> */}
          {/* <div> */}
          {!("cookies" in cookies) && <CookieConsentModal />}
          {/* </div> */}
          {/* </Delayed> */}
          <ContactUsModal />
          <CostSegModal />
          <LoginModal />
          <Footer />
        </BrowserRouter>
        {/* <ScrollToTop /> */}
      </StyledApp>
    </Layout>
  );
}

export default App;
