import { axisBottom } from "d3-axis";
import { select } from "d3-selection";
import React from "react";
import styled from "styled-components";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export interface XAxisProps {
  xScale: any;
  yAxisZero?: number;
}

const StyledXAxis = styled.g`
  .tick text {
    color: var(--primary);
    font-size: 0.75rem;
  }
  path,
  line {
    stroke: transparent;
  }
`;

const XAxis = ({
  xScale,
  yAxisZero,
  ...props
}: XAxisProps): React.ReactElement => {
  const axisRef: React.RefObject<any> = React.useRef();

  React.useEffect(() => {
    if (!isNullOrUndefined(axisRef.current)) {
      select(axisRef.current).call((g) =>
        g.call(
          axisBottom(xScale)
            .ticks(xScale.range()[1] / 70)
            .tickSizeOuter(0)
        )
      );
    }
  }, [axisRef.current, xScale, yAxisZero]);

  return (
    <StyledXAxis
      ref={axisRef}
      transform={`translate(0, ${yAxisZero})`}
      {...props}
    />
  );
};

export default XAxis;
