import React, { useContext } from "react";
import { AppContext } from "../../../components/AppContext";

export interface AnalyticsWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  marginTop?: boolean;
}

export default function AnalyticsWrapper({
  children,
  marginTop = true,
  ...props
}: AnalyticsWrapperProps) {
  const { mobileSize } = useContext(AppContext);
  let styleTop;

  if (marginTop) {
    if (mobileSize) {
      styleTop = "153px";
    } else {
      styleTop = "153px";
    }
  }

  return (
    <div
      {...props}
      style={{
        ...props.style,
        background: "var(--white)",
        marginTop: styleTop
      }}
    >
      {children}
    </div>
  );
}
