import React from "react";

function useCombinedRefs<T>(
  ...refs: (React.RefObject<T> | React.Ref<T> | React.MutableRefObject<T>)[]
): React.RefObject<T> | undefined {
  const targetRef: React.RefObject<T> = React.useRef() as React.RefObject<T>;

  React.useEffect(() => {
    refs.forEach(
      (ref: React.RefObject<T> | React.Ref<T> | React.MutableRefObject<T>) => {
        if (!ref) return;

        if (typeof ref === "function") {
          ref(targetRef.current);
        } else {
          // eslint-disable-next-line
          (ref.current as any) = targetRef.current;
        }
      }
    );
  }, [refs]);

  return targetRef;
}

export default useCombinedRefs;
