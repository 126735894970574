import React from "react";
import styled from "styled-components";

interface ListProps {
  items: React.ReactNode[];
  type?: "bullet" | "number" | "disclosure-closed" | "none";
  keyExtractor?: (item: React.ReactNode, index: number) => string;
}

const StyledList = styled.ul<{
  $type: "bullet" | "number" | "disclosure-closed" | "none";
}>`
  list-style-type: ${(props) => {
    switch (props.$type) {
      case "bullet":
        return "disc";
      case "number":
        return "decimal";
      case "disclosure-closed":
        return "disclosure-closed";
      default:
        return "none";
    }
  }};
  padding-left: 2rem;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--primary);
`;

const List: React.FC<ListProps> = ({
  items,
  type = "bullet",
  keyExtractor
}) => {
  return (
    <StyledList $type={type}>
      {items.map((item, index) => (
        <ListItem
          key={keyExtractor ? keyExtractor(item, index) : `list-item-${index}`}
        >
          {item}
        </ListItem>
      ))}
    </StyledList>
  );
};

export default List;
