import React, { HTMLAttributes, useContext, useEffect } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import { AppContext } from "./AppContext";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
  onClickOutside?: boolean;
  isOpen?: boolean;
  toggle(): void;
}

const StyledModal = styled.div<{ $mobileSize?: boolean }>`
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  left: 50%;
  max-height: 95vh;
  max-width: 85vw;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1061;
  @media (max-width: 800px) {
    max-width: 100vw;
    // max-height: 100vh;
    // padding: 1rem;
    border-radius: 0;
  }
`;

const ModalBody = styled.div<{ $mobileSize?: boolean }>`
  max-height: calc(95vh - 2rem);
  padding: 0rem;
  position: relative;
  z-index: 3000;
  @media (max-width: 800px) {
    // padding: 1rem;
  }
`;

const ModalBackdrop = styled.div`
  background-color: var(--primary);
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 1060;
`;

// const CloseButton = styled.button`
//   cursor: pointer;
//   color: var(--quaternary);
//   margin-left: auto;
//   display: block;
//   font-size: 2rem;
//   font-weight: 500;
//   background: none;
//   position: absolute;
//   right: 0rem;
//   top: 0rem;
//   z-index: 4000;
//   outline: none;
//   border: none;
//   padding: 1rem;
//
//   &:hover {
//     background: var(--primary);
//     color: var(--white);
//   }
// `;

const Modal = ({
  children,
  isOpen,
  onClickOutside,
  toggle,
  ...props
}: ModalProps) => {
  const { mobileSize } = useContext(AppContext);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  return (
    <Portal>
      {isOpen && (
        <>
          <ModalBackdrop onClick={onClickOutside ? toggle : undefined} />
          <StyledModal $mobileSize={mobileSize} {...props}>
            {/* <CloseButton
              type="button"
              onClick={onClickOutside ? toggle : undefined}
            >
              &#10005;
            </CloseButton> */}
            <ModalBody $mobileSize={mobileSize}>{children}</ModalBody>
          </StyledModal>
        </>
      )}
    </Portal>
  );
};

Modal.defaultProps = {
  onClickOutside: true,
  isOpen: false
};

export default Modal;
