import styled from "styled-components";
import { Color } from "../types";

export interface LinkButtonProps {
  case?: "lowercase" | "uppercase" | "initial";
  color?: Color;
}

const LinkButton = styled.button<LinkButtonProps>`
  background: transparent;
  border: none;
  color: var(--${(props) => props.color});
  font-weight: 300;
  text-transform: ${(props) => props.case};
  outline: none;
  padding: 0;
  text-decoration: underline;
  font-size: 1.3rem;

  &:hover {
    color: var(--quinary);
    cursor: pointer;
  }
`;

LinkButton.defaultProps = {
  color: Color.primary
};

export default LinkButton;
