import React from "react";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { Color } from "../../../types";

const SignInWithGoogle = () => {
  const history = useHistory();
  const signIn = () => {
    try {
      localStorage.setItem("prevLoc", history.location.pathname);
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Button
      // color={Color.primary}
      style={{
        width: "100%",
        maxWidth: "350px",
        background: "var(--white)",
        color: "var(--primary)",
        fontWeight: "700"
      }}
      onClick={signIn}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/google_logo_1.png`}
        alt="google"
        style={{ marginRight: "0.5rem" }}
      />
      Continue with Google
    </Button>
  );
};

export default SignInWithGoogle;
