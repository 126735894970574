import { axisBottom, axisLeft } from "d3-axis";
import { select } from "d3-selection";
import React from "react";
import styled from "styled-components";

export interface GridlinesProps {
  height: number;
  width: number;
  numXLines?: number;
  numYLines?: number;
  xScale?: any;
  yScale?: any;
}

const StyledGridlines = styled.g`
  line {
    stroke: var(--gray);
    shape-rendering: crispEdges;
    transition: all 332ms;
  }

  path {
    stroke-width: 0;
  }
`;

const Gridlines = ({
  height,
  numXLines,
  numYLines,
  width,
  xScale,
  yScale
}: GridlinesProps): React.ReactElement => {
  const xGrid: React.RefObject<any> = React.useRef();
  const yGrid: React.RefObject<any> = React.useRef();

  const buildLines = (): void => {
    if (xScale && xGrid.current) {
      select(xGrid.current).call(
        axisBottom(xScale)
          .ticks(numXLines || xScale.range()[1] / 70)
          .tickSize(-height)
          .tickFormat("" as any)
      );
    }
    if (yScale && yGrid.current) {
      select(yGrid.current).call(
        axisLeft(yScale)
          .ticks(numYLines || yScale.range()[0] / 70)
          .tickSize(-width)
          .tickFormat("" as any)
      );
    }
  };

  React.useEffect(() => {
    buildLines();
  }, [height, width]);

  return (
    <>
      {xScale && (
        <StyledGridlines ref={xGrid} transform={`translate(0,${height})`} />
      )}
      {yScale && <StyledGridlines ref={yGrid} />}
    </>
  );
};

export default Gridlines;
