import styled from "styled-components";
import React from "react";
import { useLocation } from "react-router-dom";

const StyledHero = styled.div<{ isHomepage: boolean }>`
  background-image: url(${process.env.PUBLIC_URL}/images/homepage-house.webp);
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  height: ${(props) => (props.isHomepage ? "320px" : "320px")};
  z-index: ${(props) => (props.isHomepage ? 0 : -1)};

  @media (max-width: 768px) {
    bottom: 0px;
    height: 150px;
    z-index: ${(props) => (props.isHomepage ? 1 : -1)};
  }
`;

const Hero = () => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";

  return <StyledHero className="hero" isHomepage={isHomepage} />;
};

export default Hero;
