import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Text from "../components/Text";
import Select, { OptionProp } from "../components/Select";
import Field from "../components/Field";
import useCookies from "../hooks/useCookies";
import Button from "../components/Button";
import { Color } from "../types";
import { AppContext } from "../components/AppContext";
import Flex from "../components/Flex";
import SplitView from "../components/SplitView";
import HR from "../components/HR";

const interestedInOptions = [
  { label: "Buying an Airbnb rental", value: "buying_airbnb_rental" },
  { label: "Connecting with an Airbnb realtor", value: "connect_to_realtor" },
  {
    label: "Connecting with a property manager",
    value: "connect_to_property_manager"
  },
  { label: "Airbnb market analysis", value: "market_analysis" },

  // { label: "Property Management", value: "property_management" },
  {
    label: "Connect with Airbnb cleaners",
    value: "airbnb_cleaners"
  },
  {
    label: "Services that help me self-manage my Airbnb rental",
    value: "managing_myself"
  },
  { label: "Airbnb listing setup", value: "airbnb_listing_setup" },
  { label: "Rental Arbitrage", value: "rental_arbitrage" },
  { label: "1031 exchange", value: "1031_exhange" },
  { label: "Free consultation", value: "free_consultation" }
];
const activeMarkets = [
  { label: "Phoenix/Scottsdale, AZ", value: "phoenix_az" },
  { label: "Atlanta, GA", value: "atlanta_ga" },
  { label: "Dallas, TX", value: "dallas_tx" },
  { label: "Palm Springs, CA", value: "palm-springs_ca" },
  { label: "Fort Lauderdale, FL", value: "ft-lauderdale_fl" },
  { label: "Summit County, CO", value: "summit-county_co" },
  { label: "Palm Beach County, FL", value: "palm-beach_fl" },
  { label: "Miami, FL", value: "miami_fl" },
  { label: "Memphis, TN", value: "memphis_tn" },
  { label: "Philadelphia, PA", value: "philadelphia_pa" },
  { label: "San Diego, CA", value: "san-diego_ca" },
  { label: "Other", value: "other" }
];
const defaultForm = {
  name: "",
  phone: "",
  email: "",
  market: "",
  interestedIn: "",
  consent: true
};
const TurnoverServices = () => {
  const { mobileSize } = useContext(AppContext);
  const { getAllCookies } = useCookies();
  const [contactUs, setContactUs] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [errors, setErrors] = useState<any>({
    name: false,
    phone: false,
    email: false,
    market: false,
    title: false,
    notes: false
    // consent: true
  });
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form, setForm] = useState(defaultForm);
  const { name, phone, email, market, interestedIn, consent } = form;

  useEffect(() => {
    async function addContact() {
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name,
            email,
            phone,
            market,
            interested_in: interestedIn,
            consent,
            sourcePage: "turnover-services"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
          requestOptions
        );

        setThankYou(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error scheduling consultation:", error);
      }
      setContactUs(false);
    }
    if (contactUs) {
      addContact();
    }
  }, [contactUs, form]);
  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };
  const ConsentLabel = () => {
    return (
      <div
        style={{
          fontWeight: 300,
          fontSize: "0.7rem",
          marginTop: "-0.9rem",
          marginBottom: "2rem"
        }}
      >
        By submitting this form, you agree to receive subsequent emails and
        third-party marketing communications from Chalet pursuant to our{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          style={{
            cursor: "pointer",
            display: "inline-block",
            textDecoration: "underline"
          }}
        >
          Privacy Policy
        </Link>
        , which you may opt out of, or unsubscribe from, at any time.
      </div>
    );
  };
  return (
    <div style={{ paddingTop: mobileSize ? "120px" : "100px" }}>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/turnover-services"
        />
        <title>Chalet | Find An Airbnb Cleaning Service</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Impress your guests by working with Chalet's 
          network of turnover services and Airbnb cleaners. 
          Fully vetted partners that are tailored to your needs"
        />
        <meta
          property="og:title"
          content="Chalet | Find An Airbnb Cleaning Service"
        />
        <meta
          property="og:description"
          content="Impress your guests by working with Chalet's 
          network of turnover services and Airbnb cleaners. 
          Fully vetted partners that are tailored to your needs"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/turnover-services"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Text
        weight={400}
        style={{
          fontSize: mobileSize ? "1.5rem" : "2rem",
          padding: mobileSize ? "1.2rem" : "2rem",
          textAlign: "center",
          display: "block",
          color: "var(--primary)"
        }}
      >
        Top Rated Airbnb Cleaners on Your Management Team
      </Text>
      <SplitView
        style={{
          padding: mobileSize ? "1rem" : "2rem",
          paddingTop: "1rem",
          backgroundColor: "rgba(255,255,255, 0.8)",
          maxWidth: mobileSize ? "100vw" : "85vw",
          margin: "1rem auto"
        }}
      >
        <div style={{ paddingBottom: "1rem" }}>
          {!thankYou && (
            <>
              <div style={{ width: mobileSize ? "calc(100% - 0rem)" : "100%" }}>
                <Field
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={name}
                  invalid={errors.name}
                  onChange={onChange}
                />
                <Field
                  name="phone"
                  placeholder="Phone"
                  type="text"
                  value={phone}
                  invalid={errors.phone}
                  onChange={onChange}
                />
                <Field
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  invalid={errors.email}
                  onChange={onChange}
                />
                <Select
                  name="interestedIn"
                  options={interestedInOptions}
                  style={{ fontSize: "1rem" }}
                  placeholder="I am interested in ..."
                  value={interestedInOptions.find(
                    (option) => option.label === interestedIn
                  )}
                  invalid={errors.interestedIn}
                  onChange={onChange}
                />
                <Select
                  name="market"
                  options={activeMarkets}
                  style={{ fontSize: "1rem" }}
                  placeholder="Select Market ..."
                  value={activeMarkets.find(
                    (option) => option.label === market
                  )}
                  invalid={errors.market}
                  onChange={onChange}
                />
                {/* <TextArea
                  name="other"
                  placeholder="Please let us know which market you are interested in and we will contact you soon"
                  style={{ minHeight: mobileSize ? "40px" : "128px" }}
                  value={notes}
                  onChange={onChange}
                  tabIndex={0}
                /> */}
                <ConsentLabel />
                {Object.values(errors).some((value) => value) && (
                  <Text
                    color={Color.danger}
                    style={{
                      display: "block",
                      padding: "0.5rem",
                      textAlign: "center",
                      width: "100%"
                    }}
                  >
                    Fields{" "}
                    {Object.entries(errors)
                      .map(([key, value]) => (value ? key : undefined))
                      .filter((str) => str !== undefined)
                      .join(", ")}{" "}
                    are missing values
                  </Text>
                )}
                <Button
                  isLoading={contactUs}
                  style={{ marginBottom: "1rem", width: "100%" }}
                  onClick={() => allowContactUs() && setContactUs(true)}
                  data-id="modal_contact_us_message_us_button"
                >
                  Contact Us
                </Button>
              </div>
            </>
          )}
          {thankYou && (
            <Flex
              style={{
                alignItems: "stretch",
                flexDirection: "column",
                padding: mobileSize ? "0.5rem" : "3rem",
                textAlign: "center",
                minHeight: "70vh"
              }}
            >
              <Text
                weight={400}
                color={Color.quaternary}
                style={{ fontSize: "1.5rem" }}
              >
                Thank you for contacting us!
              </Text>
              <Text style={{ fontSize: "1.5rem" }}>
                A Chalet Market Specialist will reach out to you soon.
              </Text>
            </Flex>
          )}
        </div>
        <div>
          {!thankYou && (
            <>
              <Text
                block
                size="1.3rem"
                align="center"
                weight={300}
                style={{ lineHeight: "2.5rem" }}
                // lineHeight="4.5rem"
              >
                When self-managing an Airbnb, it&apos;s essential to work with
                an expert cleaning crew that understands the unique requirements
                for short-term rental properties. A typical cleaning crew does
                not consider Airbnb essentials like restocking inventory or
                changing and cleaning dirty linens.
              </Text>
              <HR $color={Color.quinary} />
              <Text
                block
                size="2rem"
                align="center"
                weight={400}
                style={{ lineHeight: "2.5rem" }}
                // lineHeight="4.5rem"
              >
                Let Chalet find the right cleaning partner for you at no charge.
              </Text>
              <HR $color={Color.quinary} />
              <Text
                block
                size="1.3rem"
                align="center"
                weight={300}
                style={{ lineHeight: "2.5rem" }}
                // lineHeight="4.5rem"
              >
                Chalet vetted cleaners have the know how to not only provide a
                spotless clean but also turnover the home specifically with
                Airbnb guests in mind.
              </Text>

              {/* <Text
                block
                size="1.3rem"
                align="center"
                weight={300}
                style={{ lineHeight: "2.7rem" }}
                lineHeight="3.5rem"
              >
                We make our{" "}
                <Text
                  weight={700}
                  color={Color.primary}
                  style={{ fontSize: "1.5rem" }}
                >
                  $$
                </Text>{" "}
                through our vendor network. This means our Airbnb Investment Consulting
                service is available to investors like you at{" "}
                <Text
                  weight={700}
                  color={Color.primary}
                  style={{ fontSize: "1.5rem" }}
                >
                  $0 cost.
                </Text>
              </Text> */}
              {/* <Text style={{display: "block"}}>
                - Market Analysis - Airbnb/VRBO listings setup - Property
                Management
              </Text> */}
            </>
          )}
        </div>
      </SplitView>
    </div>
  );
};

export default TurnoverServices;
