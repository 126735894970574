import React from "react";
import styled from "styled-components";
import { Color } from "../types";

const StyledLoader = styled.div<{ $color?: Color }>`
  display: flex;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.15rem;
    border: 0.15rem solid var(--${(props) => props.$color});
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--${(props) => props.$color}) transparent transparent
      transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = ({ color = Color.white }: { color?: Color }) => {
  return (
    <StyledLoader $color={color}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </StyledLoader>
  );
};

export default Loader;
