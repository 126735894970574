import { max } from "d3-array";
import { scaleBand, ScaleBand, scaleLinear, ScaleLinear } from "d3-scale";
import { line as d3Line, stack } from "d3-shape";
import throttle from "lodash.throttle";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import SVG, {
  ChartTooltipWrapper,
  defaultChartProps,
  DefaultChartProps
} from "./SVG";
import getItemWidth from "../../utils/getItemWidth";
import Bar from "./Bar";
import XAxis from "./XAxis";
import YAxis from "./YAxis";
import Gridlines from "./Gridlines";
import Line from "./Line";
import { Color } from "../../types";
import { AppContext } from "../AppContext";

export interface StackedBarChartProps extends DefaultChartProps {
  data: {
    year: string;
    values: {
      name: string;
      value: number;
    }[];
  }[];
  line: {
    name: string;
    values: number[];
  }[];
}

const stackColors = ["secondary", "quinary", "primary"];

const getContentWidth = ({
  width,
  marginLeft,
  marginRight
}: StackedBarChartProps & { width: number }): number =>
  width - marginLeft! - marginRight!;

const getContentHeight = ({
  height,
  marginBottom,
  marginTop
}: StackedBarChartProps & { height: number }): number => {
  const calcHeight = height - marginBottom! - marginTop!;
  return calcHeight;
};

const getXScale = (
  props: StackedBarChartProps & { width: number }
): ScaleBand<string> => {
  const { data } = props;
  const values = data.map((d) => d.year);

  return scaleBand()
    .domain(values)
    .range([0, getContentWidth(props)])
    .padding(0.1);
};

const getYScale = (
  props: StackedBarChartProps & { height: number }
): ScaleLinear<number, number> => {
  const { data } = props;
  const allValues: number[] = [];
  data.forEach((d) => {
    let total = 0;
    d.values.forEach((s) => {
      total += s.value;
    });
    allValues.push(total);
  });
  const maxVal = max(allValues) as number;
  // const minVal = min(allValues) as number;

  return scaleLinear()
    .domain([0, maxVal])
    .range([getContentHeight(props), 0]);
};

const getLineYScale = (
  props: StackedBarChartProps & { height: number }
): ScaleLinear<number, number> => {
  const { line } = props;
  const allValues: number[] = [];
  line.forEach((dLine) => {
    dLine.values.forEach((d) => allValues.push(d));
  });
  const maxVal = max(allValues) as number;

  return scaleLinear()
    .domain([0, maxVal])
    .range([getContentHeight(props), 0]);
};

const StyledTooltipWrapper = styled(ChartTooltipWrapper)<{ $color: string }>`
  span {
    color: var(--${(props) => props.$color});
  }
`;

const StackedBarChart = (props: StackedBarChartProps) => {
  const { data, line, marginLeft, marginTop } = props;
  const { mobileSize } = React.useContext(AppContext);
  const [{ height, width }, setSVGDimensions] = useState({
    height: 0,
    width: 0
  });
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [tooltipData, setTooltipData] = React.useState<
    { name: string; value: number }[]
  >([]);
  const [lineLocation, setLineLocation] = React.useState(0);

  const getSVGWidth = (svgHeight: number, svgWidth: number): void => {
    setSVGDimensions({
      height: svgHeight,
      width: svgWidth
    });
  };

  const getLeftMargin = (): number => {
    let marginWidth = 0;
    const allValues: number[] = [];
    data.forEach((s) => {
      s.values.forEach((d) => allValues.push(d.value));
    });
    allValues.forEach((d) => {
      const dWidth = getItemWidth(String(d.toFixed(0)));
      if (dWidth > marginWidth) {
        marginWidth = dWidth;
      }
    });

    return marginLeft! + marginWidth + 8;
  };

  const mapProps = React.useMemo(
    (): StackedBarChartProps & { height: number; width: number } => ({
      ...props,
      data,
      line,
      height,
      marginLeft: getLeftMargin(),
      marginTop,
      width
    }),
    [data, height, width]
  );

  const xScale = getXScale(mapProps);
  const yScale = getYScale(mapProps);
  const lineYScale = getLineYScale(mapProps);

  const path = React.useMemo(
    () =>
      d3Line<number>()
        .x((d, idx) => (xScale(data[idx]?.year) || 0) + xScale.bandwidth() / 2)
        .y((d) => {
          return lineYScale(d) as number;
        }),
    [xScale, yScale]
  );
  const contentHeight = useMemo(() => getContentHeight(mapProps), [height]);
  const contentWidth = useMemo(() => getContentWidth(mapProps), [width]);

  const onMouseEnter = (): void => {
    setShowTooltip(true);
  };

  const onMouseLeave = (
    e: React.MouseEvent | React.FocusEvent | React.TouchEvent
  ): void => {
    const { relatedTarget } = e as React.MouseEvent;
    if (
      relatedTarget &&
      (relatedTarget === window ||
        !e.currentTarget.contains(relatedTarget as any))
    ) {
      setShowTooltip(false);
    }
  };

  const onMouseMove = throttle(
    (e: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>): void => {
      e.persist();
      const { clientX, target, touches } = e as any;
      const length = data.length - 1;
      const xLocation = clientX || touches[0].clientX;
      let index = Math.floor(
        (xLocation - target.getBoundingClientRect().left - getLeftMargin()) /
          xScale.bandwidth()
      );
      if (index > length) {
        index = length;
      }
      if (index !== -1) {
        setTooltipData([
          {
            name: data[index].year,
            value: undefined
          },
          {
            name: line[0].name,
            value: new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD"
            }).format(line[0].values[index])
          },
          ...data[index].values
            .map((val) => ({
              name: val.name,
              value: new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
              }).format(val.value)
            }))
            .reverse()
        ] as any);
        setLineLocation(xScale(data[index].year)! + xScale.bandwidth() / 2);
      }
    },
    10
  );

  return (
    <SVG
      height={height}
      getSVGWidth={getSVGWidth}
      width={width}
      marginLeft={getLeftMargin()}
      marginTop={marginTop}
      showTooltip={showTooltip}
      tooltipData={tooltipData.map((d, idx) => (
        <StyledTooltipWrapper
          key={d.name}
          $color={
            idx !== 0
              ? [...stackColors, "primary"][stackColors.length - (idx - 1)]
              : ""
          }
        >
          <span className="name">{d.name}</span>
          <span className="value">{d.value}</span>
        </StyledTooltipWrapper>
      ))}
    >
      <Gridlines height={contentHeight} width={contentWidth} yScale={yScale} />
      {data.map((group) => {
        const groupData: any = {};
        group.values.forEach((val) => {
          groupData[val.name] = val.value;
        });
        const bars = stack().keys(Object.keys(groupData))([groupData]);
        return bars.map((bar: any, idx: number) => {
          return (
            <Bar
              key={`${group.year}-${bar.key}`}
              color={stackColors[idx]}
              x={xScale(group.year)}
              y={yScale(bar[0][1])}
              width={xScale.bandwidth()}
              height={Math.abs(yScale(bar[0][0]) - yScale(bar[0][1]))}
            />
          );
        });
      })}
      {!mobileSize &&
        line.map((d, index) => {
          const key = `line-${index}`;
          return (
            <Line
              key={key}
              d={path(d.values) as string}
              index={index}
              color={Color.primary}
            />
          );
        })}
      {showTooltip && (
        <line
          x1={lineLocation}
          x2={lineLocation}
          y1={0}
          y2={contentHeight}
          stroke="#333333"
          className="tooltip-line"
        />
      )}
      <XAxis xScale={xScale} yAxisZero={contentHeight} />
      <YAxis yScale={yScale} xAxisZero={0} />
      <rect
        fill="none"
        height={contentHeight}
        width={contentWidth}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onTouchStart={onMouseEnter}
        onTouchEnd={onMouseLeave}
        onTouchMove={onMouseMove}
        style={{
          pointerEvents: "all"
        }}
      />
    </SVG>
  );
};

StackedBarChart.defaultProps = {
  ...defaultChartProps,
  data: [],
  line: []
};

export default StackedBarChart;
