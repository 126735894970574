import styled, { css } from "styled-components";
import Text from "./Text";
import { Color } from "../types";

export interface SectionProps {
  color?: Color;
}

const Section = styled.div<SectionProps>`
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  margin: 0rem auto 0rem auto;
  max-width: 95vw;
  padding: 5rem 2rem;
  position: relative;
  width: 100%;
  overflow: hidden;
  ${(props) =>
    props.color &&
    css`
      background-color: var(--${props.color});
      color: var(--white);
      ${Text} {
        color: var(--white);
      }
    `}
  // @media (min-width: 1400px) {
  //   max-width: 1200px;
  // }
  @media (max-width: 800px) {
    // max-width: 100vw;
    padding: 3rem 1rem;
    border-radius: 0;
  }
`;

export default Section;
