import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Field from "../components/Field";
import Button from "../components/Button";

import { OptionProp } from "../components/Select";
import useCookies from "../hooks/useCookies";
import Flex from "../components/Flex";
import HR from "../components/HR";
import Image from "../components/Image";
import Group from "../components/Group";
import { Color } from "../types";
import SplitView from "../components/SplitView";
import Header from "../components/Header";

const defaultForm = {
  listingId: "",
  email: "",
  consent: true
};

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  // height: 100vh;
  width: 100vw;
  padding: 5rem 7.5vw 0;
  display: block;
  // width: 50%;
  // justify-content: center;
  // align-items: start;
  // line-height: 1;
  // padding: 5rem 0;
  background: url(./images/mountain_cabin.webp);
  background-repeat: no-repeat;
  background-position-y: 15rem;
  // background-position-x: 100%;
  background-size: cover;

  h1 {
    font-weight: 300;
    font-size: 2.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }

  .first ${Text} {
    white-space: nowrap;
    font-size: 2.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
    display: block;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    align-items: flex-start;
    margin-top: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: none;
    h3 {
      font-size: 1.8rem;
      ${Text} {
        font-size: 1.8rem;
      }
    }
    .second {
      // display: none;

      h3 {
        font-size: 1rem;
      }
      ${Text} {
        font-size: 1rem !important;
      }
    }
    .first {
      h1 {
        margin: 0;
        text-align: center;
        font-size: 2.2rem;
        line-height: 2.2rem;
      }
      ${Text} {
        white-space: initial;
        text-align: center;
        // font-size: 3.5rem;
        font-size: 2.2rem;
        padding-bottom: 0;
        line-height: 2.2rem;
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;
const ConsentLabel = () => {
  return (
    <div
      style={{
        fontWeight: 300,
        fontSize: "0.7rem",
        marginTop: "-0.9rem",
        marginBottom: "2rem"
      }}
    >
      By submitting this form, you agree to receive subsequent emails and
      third-party marketing communications from Chalet pursuant to our{" "}
      <Link
        to="/privacy-policy"
        target="_blank"
        style={{
          cursor: "pointer",
          display: "inline-block",
          textDecoration: "underline"
        }}
      >
        Privacy Policy
      </Link>
      , which you may opt out of, or unsubscribe from, at any time.
    </div>
  );
};

const Home = () => {
  const { getAllCookies } = useCookies();

  const { mobileSize } = useContext(AppContext);
  const [isContactUs, setContactUs] = useState(false);
  const bodyRef = useRef<HTMLDivElement>();
  const [titleGenerated, setTitleGenerated] = useState(false);
  const [errors, setErrors] = useState<any>({
    listingId: false,
    phone: false,
    email: false,
    market: false,
    interestedIn: false,
    notes: false
    // consent: true
  });
  const [newTitles, setNewTitles] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [explanation, setExplanation] = useState("");
  const [audienceScores, setAudienceScores] = useState({
    families: 0,
    couples: 0,
    groups: 0
  });
  //   let newTitle = "";
  //   let newDescription = "";
  //   const explanation = "";
  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const [form, setForm] = useState(defaultForm);
  const { listingId, email, consent } = form;

  useEffect(() => {
    async function addContact() {
      // eslint-disable-next-line
    console.log("name", name);
      // eslint-disable-next-line
    console.log("Consent", consent);
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            airbnbUrl: listingId,
            email,
            consent,
            ...cookies,
            sourcePage: "listing-title-generator"
          })
        };
        // const apiData = await API.post("customers", "/contacts", data);
        const apiData = await fetch(
          "  https://gpvf4ni8qj.execute-api.us-west-2.amazonaws.com/v1/generate-listing-title",
          requestOptions
        );

        const result = await apiData.json();

        setNewTitles(result.newTitles);
        setNewDescription(result.newDescription);
        setExplanation(result.explanation);
        setAudienceScores(result.audienceScores);
        setTitleGenerated(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
    console.log("error generating listing title:", error);
      }
      setContactUs(false);
    }
    if (isContactUs) {
      setContactUs(true);
      addContact();
    }
  }, [isContactUs, form]);

  const allowContactUs = () => {
    const isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }

        return true;
      }
    );
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }
    return isEveryElementFilled;
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.persist();
    const { name: n, value } = e.target as HTMLInputElement;
    // eslint-disable-next-line
    console.log("On change name", n);
    // eslint-disable-next-line
    console.log("On change value", value);
    let inputName = n;
    let inputValue = value;
    if (!inputName) {
      inputName = (e as any).name;
      inputValue = ((e as any).value as OptionProp).label as string;
    }
    setForm((prevState) => ({
      ...prevState,
      [inputName]: inputValue
    }));
  };
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/listing-title-generator"
        />
        <title>
          Boost Your Airbnb Listing&apos;s Rankings with FREE AI-Powered SEO
          Optimization
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Unlock the potential of your Airbnb rental with our AI-driven SEO optimization tool. Elevate your listing, attract more guests, and maximize your revenue. Sign up today for better bookings"
        />
        <meta
          property="og:title"
          content="Boost Your Airbnb Listing's Rankings with AI-Powered SEO Optimization"
        />
        <meta
          property="og:description"
          content="Unlock the potential of your Airbnb rental with our AI-driven SEO optimization tool. Elevate your listing, attract more guests, and maximize your revenue. Sign up today for better bookings"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.getchalet.com" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div style={{ maxWidth: mobileSize ? "100%" : "100%" }}>
          <div className="first">
            <h1
              style={{
                lineHeight: mobileSize ? "2.6rem" : "3rem",
                textAlign: "center",
                fontWeight: 300
              }}
            >
              Improve Your Airbnb Rankings
              {/* <Text
                style={{
                  display: mobileSize ? "initial" : "block",
                  fontWeight: mobileSize ? 300 : 400
                }}
              >
                {" "}
                
              </Text> */}
            </h1>
          </div>
          <div className="second">
            <h3
              style={{
                fontSize: mobileSize ? "1.3rem" : "2rem",
                display: mobileSize ? "none" : "block",
                color: "var(--quinary)",
                fontWeight: 300,
                lineHeight: "2.2rem",
                textAlign: "center"
              }}
            >
              High Converting Listing Titles & Descriptions Generated by AI
            </h3>
          </div>
          <SplitView
            split="2:3"
            style={{
              // alignItems: "center",
              marginTop: "3rem",
              padding: "2rem",
              background: "var(--white)",
              borderRadius: "0.5rem",
              boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)"
            }}
          >
            <div
              className="third"
              style={{ display: mobileSize ? "none" : "initial" }}
            >
              <Text
                style={{
                  display: "block",
                  fontSize: "1rem",
                  padding: mobileSize ? "1rem" : "1rem"
                }}
              >
                Are you a short-term rental host looking to skyrocket your
                occupancy rates, increase your daily rates, and maximize your
                revenue? Look no further!
              </Text>
              <Text
                style={{
                  display: "block",
                  // fontWeight: 400,
                  padding: mobileSize ? "1rem" : "1rem",
                  fontSize: "1rem",
                  color: "var(--primary)"
                }}
              >
                Introducing{" "}
                <Text
                  style={{
                    // display: "block",
                    fontWeight: 400,
                    // padding: mobileSize ? "1rem" : "1rem",
                    fontSize: "1.2rem",
                    color: "var(--quinary)"
                  }}
                >
                  FREE Airbnb SEO Optimizer
                </Text>{" "}
                – the ultimate tool that harnesses the power of the latest
                generative AI and ChatGPT technology to transform your property
                listing into a magnet for guests.
              </Text>
              <Header> How It Works</Header>
              <ul
                style={{
                  display: mobileSize ? "block" : "block",
                  padding: "1rem",
                  marginTop: mobileSize ? "initial" : "initial"
                }}
              >
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    // lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Simple Input:
                  </Text>{" "}
                  Just enter your Airbnb listing URL and your email address. Our
                  tool will securely analyze your listing data and reviews to
                  understand what guests are seeking.
                  {/* <a href="#aboutchalet">NO COST</a> */}
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    AI Magic:
                  </Text>{" "}
                  Our advanced AI algorithm will analyze your existing title,
                  description, and guest reviews to gain insights into guest
                  preferences and interests. It will also tell you how your
                  listing is performing for different target audience
                  categories.
                  {/* <a href="#aboutchalet">NO COST</a> */}
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>

                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    Creative Suggestions:
                  </Text>{" "}
                  Within moments, receive three compelling title ideas and
                  refreshed descriptions tailored to captivate potential guests
                  and boost your listing&apos;s appeal.
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
                <li
                  style={{
                    fontWeight: 300,
                    fontSize: "1rem",
                    lineHeight: "2.3rem",
                    listStyleType: "none",
                    textAlign: "left",
                    position: "relative",
                    // marginLeft: mobileSize ? "0" : "40px",
                    marginBottom: mobileSize ? "0.5rem" : "1.5rem"
                  }}
                >
                  <Text
                    style={{ color: "var(--quaternary)", fontSize: "1.3rem" }}
                  >
                    SEO Optimized:
                  </Text>{" "}
                  Our expertly crafted titles and descriptions are designed to
                  improve your Airbnb search rankings, ensuring that your
                  property shines in search results and attracts more bookings.
                  {/* <span
                    style={{
                      display: "block",
                      position: "absolute",
                      width: "25px",
                      height: "25px",
                      top: mobileSize ? "7px" : "5px",
                      left: mobileSize ? "-30px" : "-40px",
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/chalet_roof.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat"
                    }}
                  /> */}
                </li>
              </ul>
            </div>
            <div
              style={{
                padding: mobileSize ? "3rem 2rem !important" : "3rem 2rem",
                background: "var(--white)",
                borderRadius: mobileSize ? "initial" : "0.5rem",
                boxShadow: mobileSize ? "none" : "0 0 43px rgba(0, 0, 0, 0.1)"
              }}
            >
              {!titleGenerated && (
                <>
                  <div
                    style={{
                      width: mobileSize ? "calc(100% - 0rem)" : "100%",
                      borderRadius: "0.5rem"
                    }}
                  >
                    <Header size={2} style={{ color: "var(--primary)" }}>
                      FREE Airbnb SEO Optimizer <Text />
                    </Header>
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Enter Your Airbnb URL
                    </Text>
                    <Field
                      name="listingId"
                      placeholder="https://www.airbnb.com/rooms/54034904"
                      type="text"
                      value={listingId}
                      invalid={errors.listingId}
                      onChange={onChange}
                    />
                    <Text
                      block
                      style={{
                        marginBottom: "0.5rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        paddingBottom: 0
                      }}
                    >
                      Your Email
                    </Text>
                    <Field
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      invalid={errors.email}
                      onChange={onChange}
                    />
                    <ConsentLabel />
                    {Object.values(errors).some((value) => value) && (
                      <Text
                        color={Color.danger}
                        style={{
                          display: "block",
                          padding: "0.5rem",
                          textAlign: "center",
                          width: "100%"
                        }}
                      >
                        Fields{" "}
                        {Object.entries(errors)
                          .map(([key, value]) => (value ? key : undefined))
                          .filter((str) => str !== undefined)
                          .join(", ")}{" "}
                        are missing values
                      </Text>
                    )}
                    <Button
                      isLoading={isContactUs}
                      style={{
                        marginBottom: "2rem",
                        width: "100%",
                        background: "var(--primary)"
                      }}
                      onClick={() => allowContactUs() && setContactUs(true)}
                      data-id="modal_contact_us_message_us_button"
                    >
                      Generate NEW Title & Description
                    </Button>
                  </div>
                </>
              )}
              {titleGenerated && (
                <Flex
                  style={{
                    alignItems: "stretch",
                    flexDirection: "column",
                    // padding: mobileSize ? "0.5rem" : "3rem",
                    textAlign: "center",
                    maxHeight: mobileSize ? "initial" : "70vh",
                    // display: mobileSize ? "block" : "initial",
                    overflow: mobileSize ? "initial" : "auto"
                  }}
                >
                  <Header size={2} style={{ color: "var(--quinary)" }}>
                    FREE Airbnb SEO Optimizer
                  </Header>
                  <Flex
                    style={{
                      flexDirection: mobileSize ? "column" : undefined,
                      justifyContent: "space-between",
                      fontSize: "1.25rem",
                      marginBottom: "2rem",
                      display: mobileSize ? "block" : "flex"
                    }}
                  >
                    <div
                      style={{
                        maxWidth: mobileSize ? "initial" : "60%",
                        alignItems: "start"
                      }}
                    >
                      <Text
                        weight={400}
                        color={Color.primary}
                        style={{
                          fontSize: "1.5rem",
                          textAlign: "left",
                          display: "block",
                          paddingBottom: "0"
                          //   paddingBottom: "1rem"
                        }}
                      >
                        New Title Suggestions
                      </Text>
                      <HR $color={Color.quinary} />
                      <ul style={{ paddingLeft: 0, margin: 0 }}>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Title 1:{" "}
                          <Text color={Color.quinary}>{newTitles[0]}</Text>
                        </li>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Title 2:{" "}
                          <Text color={Color.quinary}>{newTitles[1]}</Text>
                        </li>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Title 3:{" "}
                          <Text color={Color.quinary}>{newTitles[2]}</Text>
                        </li>
                      </ul>
                    </div>
                    <div style={{ marginTop: mobileSize ? "2rem" : "initial" }}>
                      <Text
                        weight={400}
                        color={Color.primary}
                        style={{
                          fontSize: "1.5rem",
                          textAlign: "left",
                          display: "block",
                          paddingBottom: "0"
                          //   paddingBottom: "1rem"
                        }}
                      >
                        Target Audience
                      </Text>
                      <HR $color={Color.quinary} />
                      <ul style={{ paddingLeft: 0, margin: 0 }}>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Families:{" "}
                          <Text color={Color.quinary}>
                            {audienceScores.families}/10
                          </Text>
                        </li>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Groups:{" "}
                          <Text color={Color.quinary}>
                            {audienceScores.groups}/10
                          </Text>
                        </li>
                        <li
                          style={{ listStyleType: "none", textAlign: "left" }}
                        >
                          Couples:{" "}
                          <Text color={Color.quinary}>
                            {audienceScores.couples}/10
                          </Text>
                        </li>
                      </ul>
                    </div>
                  </Flex>

                  <Text
                    weight={400}
                    color={Color.primary}
                    style={{
                      fontSize: "1.5rem",
                      textAlign: "left",
                      paddingBottom: "1rem"
                    }}
                  >
                    New Description for your property
                  </Text>
                  <HR $color={Color.quinary} />
                  <Text>{newDescription}</Text>
                  <Text
                    weight={400}
                    color={Color.primary}
                    style={{
                      fontSize: "1.5rem",
                      textAlign: "left",
                      paddingBottom: "1rem"
                    }}
                  >
                    Explanation of analysis
                  </Text>
                  <HR $color={Color.quinary} />
                  <Text>{explanation}</Text>
                </Flex>
              )}
            </div>
          </SplitView>
        </div>
        <div style={{ display: "none", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
    </>
  );
};

export default Home;
