import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, defaults } from "chart.js";
import styled from "styled-components";
import { Colors } from "../../../components/GlobalStyle";
import Flex from "../../../components/Flex";
import { Font } from "../../../components/Text";
import { AppContext } from "../../../components/AppContext";

ChartJS.register(ArcElement, Tooltip);

defaults.font.family = Font.SourceSansPro;

const ChartTitle = styled(Flex)`
  align-items: center;
  color: var(--primary);
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;

  & > div {
    color: var(--primary);
    &:first-child {
      font-size: 1.5rem;
    }
    &:last-child {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    flex: 1 1 100%;
    width: 100%;

    & > div {
      color: var(--primary);
      &:first-child {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
  }
`;

const getAspectRatio = (
  largeScreen: boolean,
  mobileSize: boolean,
  isZipcode?: boolean,
  fullWidth?: boolean
) => {
  if (largeScreen) return 1.85;
  if (mobileSize) return 1.85;
  if (isZipcode && !largeScreen && !mobileSize) return 1.1;
  if (fullWidth) return 2.15;
  return 1.3;
};

export default function GaugeChart({
  data,
  title,
  $isZipcode,
  $fullWidth
}: {
  data: number[];
  title?: string[];
  $isZipcode?: boolean;
  $fullWidth?: boolean;
}) {
  const { mobileSize, largeScreen } = useContext(AppContext);

  return (
    <>
      {title && (
        <ChartTitle>
          {title.map((str) => (
            <div key={str}>{str}</div>
          ))}
        </ChartTitle>
      )}
      <Doughnut
        data={{
          datasets: [
            {
              label: "Annual Revenue",
              data,
              backgroundColor: [Colors.sixth, Colors.secondary]
            }
          ]
        }}
        options={{
          aspectRatio: getAspectRatio(
            largeScreen,
            mobileSize,
            $isZipcode,
            $fullWidth
          ),
          cutout: "100%",
          // responsive: true,
          elements: {
            arc: {
              borderRadius: 12,
              borderWidth: 0
            }
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10
            }
          },
          plugins: {
            datalabels: {
              display: false
            }
          },
          rotation: 202,
          circumference: 315
        }}
      />
    </>
  );
}
