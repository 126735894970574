import uniqueId from "lodash.uniqueid";
import React, { HTMLAttributes, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Popover, { Placement } from "../Popover";
import { StyledNavbarItem } from "./NavbarItem";
import { Color } from "../../types";
import Button, { ButtonType, StyledButton } from "../Button";

export interface NavbarMenuProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  href: string;
}

export const NavbarMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 700;

  ${StyledNavbarItem},
  ${StyledButton} {
    color: var(--${Color.primary});
    border-bottom: 1px solid var(--${Color.primary});
    border-radius: 0;
    font-weight: 700;
    // border-bottom: none;
    // border-top: none;
    margin: 0;
    // padding: 0.1rem;

    > a {
      color: var(--${Color.primary});
    }
  }
`;

const NavbarMenu = ({ children, href, title }: NavbarMenuProps) => {
  const id = useRef(uniqueId("navbar-menu-"));
  const history = useHistory();
  const elementRef = useRef<HTMLDivElement>();
  const popoverRef = useRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    history.push(href);
  };
  const handleMouseOver = (e: any) => {
    if (e.type === "mouseenter") {
      setIsOpen(true);
    } else if (
      e.type === "mouseleave" &&
      e.relatedTarget &&
      (e.relatedTarget === window ||
        (!elementRef.current?.contains(e.relatedTarget) &&
          !popoverRef.current?.contains(e.relatedTarget)))
    ) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button
        type={ButtonType.link}
        id={id.current}
        ref={elementRef}
        onClick={handleClick}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOver}
        style={{
          color: "var(--primary)",
          fontWeight: 400,
          fontSize: "1.2rem"
        }}
      >
        {title}{" "}
        <span
          style={{
            padding: "0.1rem",
            marginTop: "-0.1rem",
            color: "var(--quinary)",
            fontSize: "1rem"
          }}
        >
          &#9660;
        </span>
      </Button>
      <Popover
        color={Color.primary}
        isOpen={isOpen}
        placement={Placement.bottomStart}
        target={id.current}
        ref={popoverRef}
        onMouseLeave={handleMouseOver}
      >
        <NavbarMenuList>{children}</NavbarMenuList>
      </Popover>
    </>
  );
};

export default NavbarMenu;
