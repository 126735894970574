import React, { useContext, useEffect, useCallback } from "react";
import CookieConsent from "react-cookie-consent";

import { AppContext } from "../../components/AppContext";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const CookieConsentModal = () => {
  const { mobileSize, setOpenCookieConsentModal } = useContext(AppContext);
  const waitBeforeShow = 70;
  useEffect(() => {
    // eslint-disable-next-line
    console.log("waitBeforeShow", waitBeforeShow);
    setTimeout(() => {
      // eslint-disable-next-line
    console.log("set timeout is called");
      setOpenCookieConsentModal(true);
    }, waitBeforeShow);
  }, [waitBeforeShow]);

  const handleAcceptCookie = useCallback(() => {
    const storeWebisteVisotor = async () => {
      try {
        const requestOptions = {
          method: "POST",
          // headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            // ...cookies
            status: "closed"
          })
        };
        const apiData = await fetch(
          "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/websitevisitors",
          requestOptions
        );
        // eslint-disable-next-line
    console.log("api data", apiData);
      } catch (e) {
        // eslint-disable-next-line
    console.log("error hitting the api", e);
      }
      setOpenCookieConsentModal(false);
    };

    storeWebisteVisotor();
  }, []);
  return (
    <CookieConsent
      location="bottom"
      buttonText="Close"
      // declineButtonText="Decline All"
      cookieName="cookies"
      // enableDeclineButton
      // debug
      // onAccept={handleAcceptCookie}
      onAccept={handleAcceptCookie}
      style={{
        background: "var(--white)",
        padding: mobileSize ? "0" : "0rem",
        fontSize: mobileSize ? "1.2rem" : "1.2rem",
        color: "var(--quinary)",
        boxShadow: "0 0 43px rgba(0, 0, 0, 0.1)"
      }}
      buttonStyle={{
        background: "var(--primary)",
        color: "var(--white)",
        fontSize: "1rem"
      }}
      declineButtonStyle={{
        background: "none",
        color: "var(--quinary)",
        fontSize: "0.8rem"
        // textDecoration: "underline"
      }}
      expires={150}
    >
      {/* This website uses cookies to enhance the user experience.{" "} */}
      <span style={{ fontSize: "0.8rem", display: "block" }}>
        By continuing to use our site, you agree to our Terms of Service and
        <a href="/privacy-policy" target="_blank">
          {" "}
          Privacy Policy
        </a>
        . You can learn more about how we use cookies by reviewing our Privacy
        Policy
      </span>
    </CookieConsent>
  );
};

export default CookieConsentModal;
