import styled, { css } from "styled-components";

const Image = styled.img<{ $mobileSize?: boolean }>`
  border-radius: 0.25rem;
  padding: 0rem;

  ${(props) =>
    props.$mobileSize &&
    css`
      padding: 0;
    `};
`;

Image.defaultProps = {
  loading: "lazy"
};

export default Image;
