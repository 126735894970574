import React from "react";
import { PopupButton } from "@typeform/embed-react";
import { Helmet } from "react-helmet";
import Image from "../components/Image";
import "../styles/Comparison.scss";
import { HorizontalDivider, VerticalDivider } from "../components/Divider";

const CompTable = ({
  className,
  featureProp,
  title
}: {
  className?: string;
  featureProp: {
    name: string;
    chalet: boolean;
    rabbu: boolean;
    airdna: boolean;
    bnbcalc: boolean;
    link?: string;
  }[];
  title?: string;
}) => {
  const classes = className;

  const features = featureProp;

  return (
    <div className={classes}>
      <h2>{title}</h2>
      <div>
        <table>
          <thead>
            <tr>
              <th style={{ visibility: "hidden" }}>.</th>
              <th className="chaletcol">
                <div>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/getchalet.svg`}
                    alt="Chalet"
                    id="chaletimage"
                  />
                </div>
              </th>
              <th className="rabbucol">
                <div>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/rabbu.svg`}
                    alt="Rabbu"
                    id="chaletimage"
                  />
                </div>
              </th>
              <th className="airdnacol">
                <div>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/airdna.svg`}
                    alt="Airdna"
                    id="chaletimage"
                  />
                </div>
              </th>
              <th className="bnbcalccol">
                <div>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/bnbcalc.svg`}
                    alt="BnbCalc"
                    id="chaletimage"
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {features.map((feature, index) => (
              <tr key={0}>
                <td className="feature">
                  {feature.link ? (
                    <a
                      href={feature.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {feature.name}
                    </a>
                  ) : (
                    feature.name
                  )}
                </td>
                <td>{feature.chalet && <span className="tick">✓</span>}</td>
                <td>{feature.rabbu && <span className="tick">✓</span>}</td>
                <td>{feature.airdna && <span className="tick">✓</span>}</td>
                <td>{feature.bnbcalc && <span className="tick">✓</span>}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Comparison = () => {
  const compareFeatures = [
    {
      name: "Market Investor Guides",
      link: "/analytics/investor-guides",
      chalet: true,
      rabbu: true,
      airdna: true,
      bnbcalc: false
    },
    {
      name: "Revenue Calculator",
      link: "/analytics/str-calculator",
      chalet: true,
      rabbu: true,
      airdna: true,
      bnbcalc: true
    },
    {
      name: "Rental Regulation Guides",
      link: "/analytics/rental-regulations",
      chalet: true,
      rabbu: false,
      airdna: false,
      bnbcalc: true
    },
    {
      name: "Platform of For-Sale STR Listings",
      link: "/airbnbs-for-sale",
      chalet: true,
      rabbu: true,
      airdna: true,
      bnbcalc: false
    }
  ];

  const networkFeatures = [
    {
      name: "Top Real Estate Partner Agents",
      link: "/real-estate-agents",
      chalet: true,
      rabbu: false,
      airdna: false,
      bnbcalc: false
    },
    {
      name: "DSCR Lenders",
      chalet: true,
      rabbu: true,
      airdna: true,
      bnbcalc: true
    },
    {
      name: "Property Managers",
      link: "/property-management",
      chalet: true,
      rabbu: false,
      airdna: true,
      bnbcalc: false
    },
    {
      name: "Interior Designers",
      chalet: true,
      rabbu: false,
      airdna: false,
      bnbcalc: false
    },
    {
      name: "Airbnb Cleaners",
      chalet: true,
      rabbu: false,
      airdna: false,
      bnbcalc: false
    }
  ];

  return (
    <div id="comparison-page">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.getchalet.com/chalet-vs-others"
        />
        <title>
          Chalet vs Rabbu vs Airdna vs Bnbcalc | Short-Term Rental Investment
          Tools Comparison
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Compare Chalet with other short-term rental platforms. See how we stack up against competitors in terms of market analysis, tools, and expert network."
        />
        <meta
          property="og:title"
          content="Compare Chalet with Leading Short-Term Rental Investment Platforms"
        />
        <meta
          property="og:description"
          content="See how Chalet compares to other STR platforms. Access free tools, market data, and expert network of STR professionals."
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/chalet-vs-others"
        />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <div id="comparison-top">
        <div id="top_content">
          <div id="first">
            <h1>More Than a Short-Term Rental Data Analytics Tool</h1>
          </div>
          <div id="second">
            <h2>Chalet is your personal team of STR experts</h2>
          </div>
          <div id="third">
            <PopupButton
              id="lMhk4Fej"
              shareGaInstance="true"
              hidden={{
                source_page: "/(banner-chat-with-chalet-str-expert)"
              }}
              data-id="talk-to-str-expert"
              className="talk-to-str-expert_button_top btn-consult"
            >
              Connect With a STR Expert
            </PopupButton>
          </div>
        </div>
        <Image
          src={`${process.env.PUBLIC_URL}/images/bikers.svg`}
          alt="Decoration"
          id="bikers"
        />
      </div>
      <div id="other-platforms">
        <div className="platforms-flex">
          <h2>How Does Chalet Compare to Other STR Platforms?</h2>
          <div className="platforms-tables">
            <CompTable
              className="comp-table"
              featureProp={compareFeatures}
              title="Short-Term Rental Tools and Resources"
            />
            <CompTable
              className="comp-table"
              featureProp={networkFeatures}
              title="Referral Network of STR Pros"
            />
            <Image
              src={`${process.env.PUBLIC_URL}/images/comparetable.svg`}
              alt="Comparison Table"
              id="compareimage"
            />
          </div>
        </div>
      </div>
      <div id="why-chalet">
        {/* Top Section */}
        <div className="top-section">
          <h2>Why Chalet?</h2>
          <p>Learn why investors are choosing Chalet in 2025</p>
        </div>

        {/* Main Content Section */}
        <div className="absolute-section">
          <div className="content-section">
            <div className="card">
              <div className="card-number">1</div>
              <h3>STR Expert Partners</h3>
              <p>
                Our partners have been hand-picked by our team because they are
                the best in STR in their market. Partnering with an STR expert
                is the difference between learning football from Tom Brady or
                just winging it with Jimmy from around the block.
              </p>
            </div>
            <div className="card">
              <div className="card-number">2</div>
              <h3>Real-time Data</h3>
              <p>
                We&apos;re constantly receiving updated information about our
                markets and properties. We&apos;re hard at work to get you
                real-time updates, so you can make the most informed decisions
                based on current market conditions. Say goodbye to skewed and
                outdated data 👋.
              </p>
            </div>
            <div className="card">
              <div className="card-number">3</div>
              <h3>Verified STR Listings</h3>
              <p>
                All property listings promoted by Chalet are verified STRs with
                real performance results, not an optimistic forecast with all
                favorable market conditions applied.
              </p>
            </div>
            <div className="card">
              <div className="card-number">4</div>
              <h3>$0 Cost to Investors</h3>
              <p>
                We earn from our referral partners, not from our investors. All
                of our tools, resources, and referrals are completely free for
                investors.
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="bottom-section" />
      </div>
      <div
        id="home-statistics"
        style={{
          // backgroundImage: `url('${process.env.PUBLIC_URL}/images/about2.webp')`,
          background: "var(--sixth)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-black">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in short-term rental assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-black">short-term rentals</span>
        </div>
      </div>
    </div>
  );
};

export default Comparison;
