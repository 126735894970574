import React from "react";
import styled from "styled-components";
import Flex from "../../../components/Flex";
import Tooltip from "../../../components/Tooltip";

const GrossYieldWrapper = styled(Flex)`
  align-items: center;
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative; // this is important for absolute positioning

  .gross-yield-title {
    color: var(--primary);
    font-size: 1.8rem;
    font-weight: 400;
    white-space: nowrap;
  }

  .gross-yield {
    color: var(--primary);
    font-size: 2.5rem;
    font-weight: 700;
  }

  .gross-yield-ranking {
    color: var(--secondary);
    font-size: 1.375rem;
    text-decoration: underline;
  }
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

const TooltipIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--primary);
  color: var(--quinary);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;

export default function GrossYield({
  grossYield,
  ranking
}: {
  grossYield: string;
  ranking?: string;
}) {
  return (
    <GrossYieldWrapper>
      <TooltipContainer>
        <Tooltip
          content={
            <div
              style={{
                fontSize: "1.14rem",
                background: "var(--white)",
                padding: "1rem",
                borderRadius: "0.25rem"
                // boxShadow: "0 2px 10px rgba(0,0,0,0.1)"
              }}
            >
              <p>
                Gross yield is the total annual revenue generated by a property
                divided by its market value, expressed as a percentage.
              </p>
              <p>
                It provides a quick way to compare the potential return on
                investment for different properties or markets, before
                accounting for expenses and taxes.
              </p>
            </div>
          }
        >
          <TooltipIcon>?</TooltipIcon>
        </Tooltip>
      </TooltipContainer>
      <div className="gross-yield-title">Gross Yield</div>
      <div className="gross-yield">{grossYield}%</div>
      {ranking && (
        <a
          href="/analytics/overview"
          target="_blank"
          rel="noopener noreferrer"
          className="gross-yield-ranking"
        >
          #{ranking} in the US
        </a>
      )}
    </GrossYieldWrapper>
  );
}
