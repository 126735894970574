import Skeleton from "react-loading-skeleton";
import React from "react";
import styled from "styled-components";
import Flex from "./Flex";
import Text from "./Text";
import { Colors } from "./GlobalStyle";

const SkeletonLoaderWrapper = styled(Flex)`
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  gap: 1rem;
  height: 400px;
  width: 100%;
  padding: 1rem 4rem;

  .react-loading-skeleton {
    &:nth-of-type(4n + 1) {
      width: 70% !important;
    }

    &:nth-of-type(4n + 2) {
      width: 90% !important;
    }

    &:nth-of-type(4n + 3) {
      width: 100% !important;
    }

    &:nth-of-type(4n + 4) {
      width: 85% !important;
    }
  }
`;

export default function SkeletonLoader() {
  return (
    <SkeletonLoaderWrapper>
      <Text style={{ padding: "0" }}>Loading...</Text>
      <Skeleton
        count={4}
        baseColor={Colors.gray}
        highlightColor={Colors.quinary}
        width="100%"
      />
      <Skeleton
        count={4}
        baseColor={Colors.gray}
        highlightColor={Colors.quinary}
        width="100%"
      />
      <Skeleton
        count={4}
        baseColor={Colors.gray}
        highlightColor={Colors.quinary}
        width="100%"
      />
    </SkeletonLoaderWrapper>
  );
}
