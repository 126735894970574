export function currencyNotation(num: number, decimal = 0) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    currency: "USD",
    style: "currency",
    maximumFractionDigits: decimal
  } as any);

  return numberFormatter.format(num);
}
export function percentNotation(num: number, decimal = 0) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    style: "percent",
    maximumFractionDigits: decimal
  } as any);

  return numberFormatter.format(num);
}

export function compactCurrencyNotation(num: number, decimal = 0) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    notation: "compact",
    currency: "USD",
    style: "currency",
    maximumFractionDigits: decimal
  } as any);

  return numberFormatter.format(num);
}
export function compactNotation(num: number, decimal = 0) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    notation: "compact",
    maximumFractionDigits: decimal
  } as any);

  return numberFormatter.format(num);
}

export function numberNotation(num: number, decimal = 0) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal
  } as any);

  return numberFormatter.format(num);
}
