import styled, { css } from "styled-components";
import React, { useRef } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

const StyledShareButton = styled.div`
  align-items: center;
  color: var(--sixth);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  gap: 0.25rem;
  text-decoration: underline;
  position: relative;
`;

const ShareMenu = styled.div<{ $center?: boolean }>`
  background: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 0 43px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 100%;
  height: 46px;
  min-width: 100%;
  z-index: 10000;

  ${(props) =>
    props.$center &&
    css`
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    `};
`;

const ShareButton = ({ style, center = true }: any) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  // const copyUrl = () => {
  //   navigator.clipboard.writeText(window.location.href);
  //   setIsOpen(true);
  //
  //   setTimeout(() => setIsOpen(false), 1000);
  // };

  React.useEffect(() => {
    if (isOpen) {
      const onOutsideClick = (e: MouseEvent) => {
        if (!menuRef.current?.contains(e.target as any)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", onOutsideClick, { capture: true });

      return () => {
        document.removeEventListener("mousedown", onOutsideClick, {
          capture: true
        });
      };
    }

    return undefined;
  }, [isOpen]);

  const url = window.location.href;

  return (
    <>
      <StyledShareButton
        id="share-button"
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        ref={menuRef}
        style={style}
      >
        Share
        <svg
          width="23"
          height="21"
          viewBox="0 0 23 21"
          fill="var(--sixth)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.43334 1.5C8.85676 1.5 9.20001 1.83579 9.20001 2.25C9.20001 2.61819 8.9288 2.92441 8.57115 2.98792L8.43334 3H3.83334C2.6378 3 1.65531 3.89233 1.54386 5.03331L1.53333 5.25V17.25C1.53333 18.4195 2.44549 19.3807 3.61183 19.4897L3.83334 19.5H16.1C17.2955 19.5 18.278 18.6076 18.3895 17.4667L18.4 17.25V15.75C18.4 15.3358 18.7433 15 19.1667 15C19.543 15 19.8561 15.2654 19.921 15.6152L19.9334 15.75V17.25C19.9334 19.2382 18.3516 20.8651 16.3521 20.992L16.1 21H3.83334C1.80093 21 0.137941 19.4527 0.0081538 17.4966L0 17.25V5.25C0 3.26178 1.58169 1.63494 3.5813 1.50798L3.83334 1.5H8.43334ZM14.379 0.866732C14.379 0.195054 15.0872 -0.187381 15.6307 0.0926736L15.7443 0.162961L15.8389 0.242476L22.7344 6.87425C23.0205 7.14935 23.0755 7.57271 22.8992 7.90475L22.8223 8.02452L22.7346 8.12268L15.839 14.7565C15.3556 15.2216 14.5899 14.9739 14.4152 14.3867L14.3871 14.2558L14.379 14.1324V10.9892L13.9839 11.024C13.6022 11.0639 13.2229 11.1221 12.8457 11.1986C10.4939 11.676 8.22554 12.8668 6.03001 14.7846C5.43288 15.3063 4.50903 14.8131 4.60724 14.0251C5.35283 8.04322 8.41346 4.67901 13.6292 4.12138L14.0346 4.08402L14.379 4.06136V0.866732ZM15.9123 2.41656V5.46384L14.147 5.58C11.734 5.76908 9.95495 6.59139 8.68358 7.93278C7.65774 9.01512 6.8769 10.5261 6.41967 12.5333C8.59014 10.942 10.8826 9.94481 13.3008 9.59697L13.8337 9.53104L15.9123 9.34835V12.5823L21.1965 7.49858L15.9123 2.41656Z"
            fill="var(--sixth)"
          />
        </svg>
        {isOpen && (
          <ShareMenu $center={center}>
            <FacebookShareButton url={url}>
              <FacebookIcon size={30} round />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <TwitterIcon size={30} round />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={30} round />
            </LinkedinShareButton>
            <RedditShareButton url={url}>
              <RedditIcon size={30} round />
            </RedditShareButton>
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={30} round />
            </WhatsappShareButton>
            <EmailShareButton url={url}>
              <EmailIcon size={30} round />
            </EmailShareButton>
          </ShareMenu>
        )}
      </StyledShareButton>
    </>
  );
};

export default ShareButton;
