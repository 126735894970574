import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import Field from "../../../components/Field";
import Text from "../../../components/Text";
import Button from "../../../components/Button";
import Flex from "../../../components/Flex";
import LinkButton from "../../../components/LinkButton";
import { Color } from "../../../types";
import { AppContext } from "../../../components/AppContext";

const ForgotPassword = ({ email, setForgotPassword, onChange }: any) => {
  const { mobileSize } = useContext(AppContext);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [submitNewPassword, setSubmitNewPassword] = useState(false);
  const [isForgotLoading, setIsForgotLoading] = React.useState(false);

  useEffect(() => {
    if (sentEmail) {
      setIsForgotLoading(true);
      Auth.forgotPassword(email)
        .then(() => {
          setIsForgotLoading(false);
        })
        .catch(() => {
          setIsForgotLoading(false);
        });
    }
  }, [sentEmail]);

  useEffect(() => {
    if (submitNewPassword) {
      setIsForgotLoading(true);
      Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
        .then(() => {
          setIsForgotLoading(false);
        })
        .catch(() => {
          setIsForgotLoading(false);
        });
    }
  }, [submitNewPassword]);

  const onCodeChange = (e: any) => {
    setVerificationCode(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };

  return (
    <>
      {!sentEmail && !submitNewPassword && (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <Text weight={600}>Reset password</Text>
          </div>
          <Field
            name="email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={onChange}
          />
          <Button
            isLoading={isForgotLoading}
            style={{ margin: "1rem 0 0.5rem", width: "100%" }}
            onClick={() => setSentEmail(true)}
          >
            Send verification code
          </Button>
        </>
      )}
      {sentEmail && (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <Text>Check your email </Text>
            <Text weight={600}>{` ${email} `}</Text>
            <Text>for a verification code and provide it below</Text>
          </div>
          <Field
            name="verificationcode"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={onCodeChange}
          />
          <Field
            name="newpassword"
            placeholder="New Password"
            type="password"
            value={newPassword}
            onChange={onPasswordChange}
          />
          <Button
            isLoading={isForgotLoading}
            style={{ margin: "1rem 0 0.5rem", width: "100%" }}
            onClick={() => {
              setSentEmail(false);
              setSubmitNewPassword(true);
            }}
          >
            Set new password
          </Button>
        </>
      )}
      {submitNewPassword && (
        <>
          <Flex
            style={{
              alignItems: "stretch",
              flexDirection: "column",
              padding: mobileSize ? "0.5rem" : "3rem",
              textAlign: "center"
            }}
          >
            <Text
              weight={400}
              color={Color.quaternary}
              style={{ fontSize: "1.5rem" }}
            >
              New password has been set!
            </Text>
            <Button
              style={{ margin: "1rem 0 0.5rem", width: "100%" }}
              onClick={() => {
                setSubmitNewPassword(false);
                setForgotPassword(false);
              }}
            >
              Login
            </Button>
          </Flex>
        </>
      )}
      {!submitNewPassword && (
        <Flex style={{ justifyContent: "center" }}>
          <LinkButton
            onClick={() => {
              if (sentEmail) {
                setSentEmail(false);
              } else {
                setForgotPassword(false);
              }
            }}
            style={{ fontSize: "1rem" }}
          >
            Go back
          </LinkButton>
        </Flex>
      )}
    </>
  );
};

export default ForgotPassword;
