import React, { useState, ChangeEvent, useEffect, useRef } from "react";

import css from "./MultiSelect.module.scss";

interface MultiSelectProps {
  options: string[];
  onApply: (selectedOptions: string[]) => void;
  placeholder?: string;
  single?: boolean;
  prefix?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  onApply,
  placeholder = "Select Options",
  single,
  prefix
}) => {
  const [tempSelectedOptions, setTempSelectedOptions] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [distinctOptions, setDistinctOptions] = useState<string[]>([]);
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setDistinctOptions(Array.from(new Set(options)));
  }, [options]);

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTempSelectedOptions(
      event.target.checked
        ? [...tempSelectedOptions, value]
        : tempSelectedOptions.filter((option) => option !== value)
    );
  };

  const handleSingleOptionChange = (value: string) => () => {
    const newOptions = [value];
    setTempSelectedOptions(newOptions);
    onApply(newOptions);
    setIsDropdownOpen(false);
  };

  const handleApply = () => {
    onApply(tempSelectedOptions);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      handleDropdownToggle();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setRef = React.useCallback(
    (node) => {
      if (node) {
        const {
          x: newX,
          y: newY,
          height,
          width: newWidth
        } = node.getBoundingClientRect();
        setX(newX);
        setY(newY + height);
        setWidth(newWidth);
      }

      // @ts-ignore
      dropdownRef.current = node;
    },
    [isDropdownOpen]
  );

  return (
    <div className={css.multiSelectContainer} ref={setRef}>
      <div
        className={css.inputField}
        role="button"
        tabIndex={0}
        onClick={handleDropdownToggle}
        onKeyDown={handleKeyDown}
        aria-labelledby="multi-select-label"
      >
        {prefix && <span className={css.prefix}>{prefix}:</span>}
        <input
          type="text"
          placeholder={placeholder}
          readOnly
          value={tempSelectedOptions.join(", ")}
        />
        <span
          className={`${css.arrow} ${isDropdownOpen ? css.up : css.down}`}
        />
      </div>
      {!single && isDropdownOpen && (
        <div
          className={css.dropdown}
          style={{ left: `${x}px`, top: `${y}px`, width: `${width}px` }}
        >
          <div className={css.optionsContainer}>
            {distinctOptions.map((option) => (
              <label key={option} htmlFor={option} className={css.optionLabel}>
                <input
                  type="checkbox"
                  id={option}
                  value={option}
                  checked={tempSelectedOptions.includes(option)}
                  onChange={handleOptionChange}
                />
                <span className={css.optionSpan}>{option}</span>
              </label>
            ))}
          </div>
          <button
            type="button"
            className={css.applyButton}
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      )}
      {single && isDropdownOpen && (
        <div
          className={css.dropdown}
          style={{ left: `${x}px`, top: `${y}px`, width: `${width}px` }}
        >
          <div className={css.optionsContainer}>
            {distinctOptions.map((option) => (
              <div
                role="presentation"
                key={option}
                className={css.optionLabel}
                onClick={handleSingleOptionChange(option)}
              >
                <span className={css.optionSpan}>{option}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
