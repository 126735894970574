import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Image from "../components/Image";
import Section from "../components/Section";
import Text from "../components/Text";
import SplitView from "../components/SplitView";
import { Color } from "../types";
import Button from "../components/Button";
import SectionFooter from "../components/SectionFooter";
import Group from "../components/Group";
import ContactUsModal from "./modals/ContactUsModal";

const Analyze = () => {
  const { mobileSize } = useContext(AppContext);
  const history = useHistory();

  const handleRegulationsClick = () => {
    history.push("/analytics/rental-regulations");
  };
  const handleInvestorGuidesClick = () => {
    history.push("/analytics/investor-guides");
  };
  const handleAirbnbCalculatorClick = () => {
    history.push("/analytics/str-calculator");
  };

  const handleContactUsClick = () => {
    window.location.href =
      "https://form.typeform.com/to/lMhk4Fej#market_of_interest=Not Specified&source_page=anaylze";
  };

  return (
    <div style={{ paddingTop: "208px" }}>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com/analyze" />
        <title>FREE Airbnb Rental Analysis/Tools You Need</title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Free Short-term rental analysis to get 
          you best ROI. Free Market Reports, Airbnb Profit 
          Calculator, Airbnb Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta
          property="og:title"
          content="FREE Airbnb Rental Analysis/Tools You Need"
        />
        <meta
          property="og:description"
          content="Free Short-term rental analysis to get 
          you best ROI. Free Market Reports, Airbnb 
          Profit Calculator, Airbnb Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.getchalet.com/analyze" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Header size={1}>Research</Header>
      <Section
        style={{
          marginBottom: "0rem",
          maxWidth: mobileSize ? "100%" : "100%",
          paddingLeft: "0rem",
          paddingRight: "0rem",
          paddingBottom: mobileSize ? "2rem" : "0"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            // m  axWidth: "1200px",
            // maxWidth: mobileSize ? "100%" : "85vw"
            padding: mobileSize ? "2rem" : 0
          }}
        >
          <div style={{ gridRowStart: mobileSize ? "1" : "inherit" }}>
            <Text block align="left">
              Whether you&apos;re a first-time Airbnb investor or looking to
              expand your real estate portfolio, your journey starts with market
              research. There are various factors specific to each investment
              market - AKA city/town/municipality - that will greatly impact
              your bottom line.
            </Text>
            <Text block align="left">
              But at Chalet, we&apos;ve crunched the numbers so you don&apos;t
              have to. We&apos;ve created tools that help you analyze the unique
              factors driving cash flow specific to each market. By
              understanding the market and your investor preferences, Chalet
              helps you find the best return on investment
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gridRowStart: mobileSize ? "1" : "inherit"
            }}
          />
        </Section>
        <Image
          src={`${process.env.PUBLIC_URL}/images/skyline.webp`}
          alt="Invest in Airbnb Rentals With Chalet"
          style={{
            padding: mobileSize ? "0rem" : "0rem",
            borderRadius: "0.25rem",
            maxWidth: mobileSize ? "100%" : "100%",
            width: "100%",
            display: "block",
            marginTop: "2rem"
          }}
          $mobileSize={mobileSize}
        />
      </Section>
      <Group>
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%"
            // padding: mobileSize ? "1rem" : 0
          }}
        >
          <Header
            size={2}
            // color={Color.primary}
          >
            Airbnb Regulations
          </Header>
          <SplitView
            split="3:2"
            style={{
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto",
              alignItems: "start"
            }}
          >
            <div
            // style={{ }}
            >
              <Text
                block
                align="left"

                // style={{ lineHeight: "3rem" }}
                // lineHeight="4.5rem"
              >
                The first step in analyzing any Airbnb market is to deeply
                understand its Airbnb-specific laws and regulations. Our team
                breaks down the legal jargon and provides investors with an
                easy-to-digest but thorough market regulations overview. Check
                out our Airbnb Rental Regulations page to see if we&apos;ve
                already analyzed your market of choice.
              </Text>
              {/* <HR $color={Color.quinary} /> */}
              <Text
                block
                align="left"
                // style={{ marginTop: "3rem" }}
                // lineHeight="4.5rem"
              >
                Don&apos;t see the market you&apos;re looking for? Or maybe
                you&apos;ve already researched regulations and want someone to
                provide clarity - we are here to help. Reach out now for a free
                regulations consultation with our team of short-term rental
                experts.
              </Text>
              <Button
                block
                style={{
                  margin: "2rem auto",
                  display: mobileSize ? "none" : "block"
                }}
                color={Color.primary}
                onClick={handleRegulationsClick}
                data-id="chalet-locations-fort-lauderdale_find-realtor_button"
              >
                Regulations
              </Button>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/scale.webp`}
              alt="Airbnb Regulations"
              style={{ padding: mobileSize ? "0rem" : "0rem" }}
              $mobileSize={mobileSize}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              style={{
                margin: "2rem auto",
                display: mobileSize ? "block" : "none"
              }}
              color={Color.primary}
              onClick={handleRegulationsClick}
              data-id="chalet-locations-fort-lauderdale_find-realtor_button"
            >
              Regulations
            </Button>
          </SectionFooter>
        </Section>
      </Group>
      <Group style={{ background: "var(--primary)" }}>
        <Section
          style={{
            maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.white}
          >
            Investor Guides
          </Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
              // margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit",
                paddingBottom: mobileSize ? "inherit" : "0",
                paddingTop: mobileSize ? "inherit" : "0"
              }}
            >
              <Text block align="left" color={Color.white}>
                Once you&apos;ve established that your market of choice has laws
                that are Airbnb-friendly, it&apos;s time to dig deeper into the
                viability of the market. There are many data points to consider
                when analyzing a market and the research can be cumbersome - so
                we&apos;ve done the analysis and packaged it in our FREE
                market-specific Investor Guides.
              </Text>
              <Text block align="left" color={Color.white}>
                Our FREE investor guides break down essential market conditions,
                projections, and economic trends. They help investors
                understand:
              </Text>
              <ul>
                <li
                  style={{
                    fontSize: "1.3rem",
                    color: "var(--white)",
                    fontWeight: 300
                  }}
                >
                  The health of the local real estate market
                </li>
                <li
                  style={{
                    fontSize: "1.3rem",
                    color: "var(--white)",
                    fontWeight: 300
                  }}
                >
                  The profitability of the local Airbnb market
                </li>
                <li
                  style={{
                    fontSize: "1.3rem",
                    color: "var(--white)",
                    fontWeight: 300
                  }}
                >
                  Market-specific projected revenue based on home type, bedroom
                  count, and budget
                </li>
                <li
                  style={{
                    fontSize: "1.3rem",
                    color: "var(--white)",
                    fontWeight: 300
                  }}
                >
                  Unique market variables to consider such as tax, insurance,
                  seasonality, crime, and more
                </li>
              </ul>
              <Button
                block
                style={{ margin: "2rem auto" }}
                color={Color.secondary}
                onClick={handleInvestorGuidesClick}
                data-id="analyze-cta2-investor-guide-button"
              >
                Investor Guides
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/graph.webp`}
                alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
                style={{
                  padding: "0rem",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "400px",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group>
        <Section
          style={{
            // maxWidth: "100%",
            maxWidth: mobileSize ? "100%" : "100%",
            background: "none",
            boxShadow: "none"
            // padding: mobileSize ? "1rem" : 0
          }}
        >
          <Header size={2}>Airbnb Calculator</Header>
          <SplitView
            split="2:3"
            style={{
              // alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/airbnb-calculator.webp`}
              alt="FREE Airbnb Calculator. Free Short-Term Rental Calculator"
              $mobileSize={mobileSize}
              style={{
                display: mobileSize ? "none" : "block",
                padding: mobileSize ? "1rem" : "0rem",
                borderRadius: "0.25rem"
              }}
            />
            <Image
              src={`${process.env.PUBLIC_URL}/images/airbnb-calculator-mobile.webp`}
              alt="FREE Airbnb Calculator. Free Short-Term Rental Calculator Mobile Version"
              $mobileSize={mobileSize}
              style={{
                display: mobileSize ? "block" : "none",
                padding: mobileSize ? "1rem" : "0rem",
                borderRadius: "0.25rem"
              }}
            />
            <div
              style={{ paddingTop: 0, paddingLeft: mobileSize ? 0 : "2rem" }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                // lineHeight="4.5rem"
              >
                Do you have a specific home in mind and want to find out the
                property&apos;s Airbnb revenue potential? We can help with that
                too. Our Airbnb Calculator uses data from live Airbnb listings
                to give projections on key investment factors such as:
              </Text>
              <ul>
                <li
                  style={{
                    color: "var(--primary)",
                    fontSize: "1.3rem",
                    fontWeight: 300
                  }}
                >
                  Annual revenue
                </li>
                <li
                  style={{
                    color: "var(--primary)",
                    fontSize: "1.3rem",
                    fontWeight: 300
                  }}
                >
                  Occupancy rate
                </li>
                <li
                  style={{
                    color: "var(--primary)",
                    fontSize: "1.3rem",
                    fontWeight: 300
                  }}
                >
                  Average daily rate
                </li>
                <li
                  style={{
                    color: "var(--primary)",
                    fontSize: "1.3rem",
                    fontWeight: 300
                  }}
                >
                  RevPAN(Revenue per available night)
                </li>
              </ul>
              <Text
                block
                align="left"
                style={{ lineHeight: "2.5rem" }}
                // lineHeight="4.5rem"
              >
                And beyond just the top-level numbers, our Airbnb Calculator
                allows you to input your unique variables such as maintenance
                costs, homeowners insurance costs, property tax, annual
                utilities, interest rate, and more.
              </Text>
              <Button
                block
                style={{ margin: "2rem auto" }}
                color={Color.primary}
                onClick={handleAirbnbCalculatorClick}
                data-id="analyze-cta3-airbnb-calculator-button"
              >
                Airbnb Calculator
              </Button>
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group>
        <Section>
          <Header size={2}>Understand the Data From Local Experts</Header>
          <SplitView split="3:2" style={{ alignItems: "start" }}>
            <div
              style={{
                paddingTop: 0,
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                It&apos;s essential that you, as a savvy investor, partner with
                top realtors and top property managers to further understand
                your chosen investment market. And you don&apos;t want a
                generalist - you want to work with partners who understand the
                nuances of the local Airbnb market and who understand how to
                maximize revenue.
              </Text>
              <Text block align="left">
                Chalet has an extensive network of vetted short-term rental
                realtors and Airbnb property managers ready to assist you in
                your investment journey. And the best part, we will refer you to
                our top realtors and property managers at NO COST. Connect with
                Chalet today and let us do the work for you.
              </Text>
              <Button
                block
                style={{ margin: "2rem auto" }}
                color={Color.primary}
                onClick={handleContactUsClick}
                data-id="analyze-cta4-get-started-button"
              >
                Get Started
              </Button>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/chalet-banner.webp`}
              alt="Top Airbnb Realtors and Top Airbnb Property Managers"
              $mobileSize={mobileSize}
              style={{
                // padding: mobileSize ? "1rem" : "0rem",
                gridRowStart: mobileSize ? "1" : "inherit",
                // aspectRatio: "4/3",
                maxWidth: mobileSize ? "90%" : "400px",
                display: "block",
                borderRadius: "0.25rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      {/* <Group>
        <Section>
          <Header size={2}>
            Get Higher Yields Through Airbnb Rentals With 1031 Exchange
          </Header>
          <SplitView split="2:3">
            <div>
              <Text
                block
                
                align="center"
                
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                A 1031 exchange allows investors to defer all capital gain taxes
                when selling a property if they reinvest the proceeds in a new
                property or portfolio of properties, including Airbnb Rentals in
                Fort Lauderdale, Florida.
              </Text>

              <HR $color={Color.quinary} />
              <Text
                block
                
                align="center"
                weight={400}
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                Work with Chalet, your team of short-term rental experts and
                Airbnb specialists to help you find your next 1031 exchange
                property.
              </Text>

              <HR $color={Color.quinary} />
              <Text
                block
                
                align="center"
                
                style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                New to 1031 exchanges? No problem, we are here to connect you
                with the right resources to decipher if this investment strategy
                works for you.
              </Text>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031exchange.webp`}
              alt="Need help launching your Airbnb Business?"
              $mobileSize={mobileSize}
              style={{
                gridRowStart: mobileSize ? "1" : "inherit",

                borderRadius: "0.25rem"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize}>
            <Button
              block
              color={Color.primary}
              onClick={handle1031ExchangeClick}
              data-id="chalet-locations-fort-lauderdale_1031-excange_button"
            >
              Get Started
            </Button>
          </SectionFooter>
        </Section>
      </Group> */}
      <ContactUsModal />
    </div>
  );
};

export default Analyze;
