import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Header from "../components/Header";
import Button from "../components/Button";
import { OptionProp } from "../components/Select";
import useCookies from "../hooks/useCookies";
import Image from "../components/Image";
import Section from "../components/Section";
import "../styles/Home.scss";
import Input from "../components/Input";
import { VerticalDivider, HorizontalDivider } from "../components/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const defaultForm = {
//   name: "",
//   phone: "",
//   email: "",
//   market: "",
//   interestedIn: "",
//   consent: true
// };

const Stars = () => <div className="stars">★ ★ ★ ★ ★</div>;

const Home = () => {
  const { getAllCookies } = useCookies();
  const { mobileSize } = useContext(AppContext);
  // const [isContactUs, setContactUs] = useState(false);
  const bodyRef = useRef<HTMLDivElement>();
  // const [, setThankYou] = useState(false);
  const [selectedMarket, setMarket] = useState("Not Specified");
  const [selectedService, setService] = useState("Real Estate Agent");
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const history = useHistory();
  const cookies: Record<string, string> = {};
  const location = useLocation();

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: selectedMarket,
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: ""
  };

  const marketOptions: OptionProp[] = [
    { label: "Anywhere in the US", value: "anywhere" },
    { label: "Dallas, TX", value: "dallas-tx" },
    { label: "Galveston, TX", value: "galveston-tx" },
    { label: "Kissimmee, FL", value: "kissimmee-fl" },
    { label: "Big Bear Lake, CA", value: "big-bear-lake-ca" }
  ];
  const serviceOptions: OptionProp[] = [
    { label: "Real Estate Agent", value: "real_estate_agent" },
    { label: "Property Management", value: "property_management" },
    {
      label: "Cost Segregation Specialist",
      value: "cost_segregation_specialist"
    },
    { label: "Airbnb Deal Underwriting", value: "deal_underwriting" },
    { label: "Airbnb Listing Optimization", value: "listing_optimization" }
  ];
  // Create Ref element.
  const el = useRef(null);

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  // const [form] = useState(defaultForm);
  // const { name, phone, email, market, interestedIn, consent } = form;

  const handleSelect = (value: any) => {
    if (marketOptions.some((element) => element.label === value.label)) {
      setMarket(
        value.label !== "Anywhere in the US" ? "Not Specified" : value.label
      );
    } else if (
      serviceOptions.some((element) => element.label === value.label)
    ) {
      setService(value.label);
    } else if (value.target.name === "email") {
      setEmail(value.target.value);
    } else if (value.target.name === "name") {
      setName(value.target.value);
    }
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userEmail)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: userName,
          sourcePage: "/"
        })
      };
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) button.disabled = true;
      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      ).then(() => {
        button.innerHTML = "&#10003;";
      });
    } catch (error) {
      console.error("error scheduling consultation:", error);
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) {
        button.disabled = false;
        button.innerHTML = "Free Download Now";
      }
    }
  };

  // useEffect(() => {
  //   async function addContact() {
  //     try {
  //       const requestOptions = {
  //         method: "POST",
  //         body: JSON.stringify({
  //           name,
  //           email,
  //           phone,
  //           downloadReport: true,
  //           market,
  //           interested_in: interestedIn,
  //           consent,
  //           ...cookies,
  //           sourcePage: "download-report"
  //         })
  //       };
  //       const apiData = await fetch(
  //         "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
  //         requestOptions
  //       );
  //       setThankYou(true);
  //     } catch (error) {
  //       console.error("error scheduling consultation:", error);
  //     }
  //     setContactUs(false);
  //   }
  //   if (isContactUs) {
  //     setContactUs(true);
  //     addContact();
  //   }
  // }, [isContactUs, form]);

  // Add this state to track which step is active
  const [activeStep, setActiveStep] = useState(1);

  // Create separate refs for each step with more granular thresholds
  const observerOptions = {
    threshold: [0, 0.25, 0.5, 0.75, 1], // More threshold points for smoother detection
    rootMargin: "-30% 0px -30% 0px" // Larger observation window
  };

  // Function to determine if a step should be active
  const handleStepVisibility = (inView: boolean, step: number) => {
    if (inView) {
      setActiveStep(step);
    }
  };

  return (
    <div id="landing-page">
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com" />
        <title>
          Chalet | Buy Profitable Short-Term Rentals With Confidence
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="The largest network of Airbnb realtors in the US. FREE Airbnb data tools and resources"
        />
        <meta
          property="og:title"
          content="Chalet | Buy and Own Profitable Short-Term Rentals "
        />
        <meta
          property="og:description"
          content="Short-term rental investor hub. Connect with
          a vast network of top-tier Airbnb realtors, property managers,
          CPAs and other STR service providers at No Cost"
        />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/logo2024.png"
        />
        <meta property="og:url" content="https://www.getchalet.com" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <div id="home-top">
        <div id="top_content">
          <div id="left-content">
            <h1>
              Connecting Investors with Short-Term Rental Experts, Tools, and
              Resources
            </h1>
            <h2>
              Get support every step of your investment journey—all at no cost
            </h2>
          </div>
          <div id="right-content">
            <h3>Get Started</h3>
            <p>
              Instantly match with leading short-term rental real estate agents
              and lenders.
            </p>
            <div id="button-container">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                Buying an Airbnb
              </Button> */}
              <PopupButton
                id="lMhk4Fej"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(banner-homepage)",
                  start_point: "buying"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Buying an Airbnb
              </PopupButton>
              <PopupButton
                id="lMhk4Fej"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(banner-homepage)",
                  start_point: "selling"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Selling an Airbnb
              </PopupButton>
              <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  source_page: "/(banner-homepage)",
                  start_point: "ancillary"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                Financing Your Airbnb
              </PopupButton>
            </div>
          </div>
        </div>
      </div>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--white)",
          maxWidth: "100%",
          padding: mobileSize ? "2rem" : "4rem 0"
        }}
      >
        <Header size={2} style={{ textAlign: "center", marginBottom: "1rem" }}>
          How It Works
        </Header>
        <Text block style={{ textAlign: "center", marginBottom: "4rem" }}>
          No matter where you are in your STR journey, Chalet has the tools,
          resources, and vendors to make your life easier.
        </Text>

        <div className="how-it-works-container">
          <div className="timeline">
            {[1, 2, 3, 4].map((step) => {
              const { ref, inView } = useInView(observerOptions);

              useEffect(() => {
                handleStepVisibility(inView, step);
              }, [inView, step]);

              return (
                <div
                  key={step}
                  ref={ref}
                  className={`step ${activeStep === step ? "active" : ""}`}
                >
                  <div className="step-number">{step}</div>
                  {step !== 4 && <div className="connector" />}
                </div>
              );
            })}
          </div>

          <div className="steps-content">
            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/analyze.png`}
                  alt="Analyze"
                />
              </div>
              <div className="step-text">
                <h3>Analyze</h3>
                <p>
                  Access our free Airbnb investment analytics dashboard—ideal
                  for comparing top STR markets and getting rental revenue
                  projections on specific properties.
                </p>
                <a href="/analyze" className="step-link">
                  Start Analyzing
                </a>
              </div>
            </div>

            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/buy.png`}
                  alt="Buy"
                />
              </div>
              <div className="step-text">
                <h3>Buy</h3>
                <p>
                  Whichever market you choose, Chalet can connect you with
                  experienced STR agents or lenders through our trusted partner
                  network.
                </p>
                <a href="/buyers-agent" className="step-link">
                  Find an Airbnb Buyer&apos;s Agent
                </a>
              </div>
            </div>

            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/manage.png`}
                  alt="Manage"
                />
              </div>
              <div className="step-text">
                <h3>Manage</h3>
                <p>
                  Whether you&apos;re hiring a property manager or
                  self-managing, Chalet offers free referrals to top
                  technologies, vendors, and tools to streamline operations.
                </p>
                <a href="/manage" className="step-link">
                  Start Managing
                </a>
              </div>
            </div>

            <div className="step-item">
              <div className="step-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/sell.png`}
                  alt="Sell"
                />
              </div>
              <div className="step-text">
                <h3>Sell</h3>
                <p>
                  Chalet connects you with STR-focused seller agents and lists
                  your property to reach an active audience of eager STR
                  investors.
                </p>
                <a href="/list" className="step-link">
                  List with Chalet
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section
        style={{
          boxShadow: "none",
          // background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          // background: `url("${process.env.PUBLIC_URL}/images/about2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: "var(--lightPurple)",
          maxWidth: "100%",
          // padding: "2rem 3rem",
          position: "relative"
        }}
      >
        <Header size={2} style={{ margin: "2rem auto 0" }}>
          Testimonials
        </Header>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "block"
          }}
        >
          {/* <Slider {...sliderSettings}>
          i
          </Slider> */}
          <div className="testimonials-container">
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/prohost-api/Hosting-1067591824691586398/original/bce45fe4-ec7e-4cd2-b3a5-320904fea506.jpeg?im_w=1200"
                alt="Justin B."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Justin B.
              </Header>
              <Text>
                My wife and I found Chalet when we were in search of a short
                term rental property, but needed some professional help. Chalet
                team replied back quickly and referred me to a local agent in
                the market where we were searching.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Justin&apos;s home
              </a>
            </div>
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTAyNDA5NjMzMjEwNTM3NzgyNA%3D%3D/original/aad2a319-59e6-40c1-91fb-1b831e327143.jpeg?im_w=1440"
                alt="Jimmy O."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Jimmy O.
              </Header>
              <Text>
                Chalet hooked me up with that amazing realtor in Tampa with
                great STR knowledge. Plus, they set me up with some folks for a
                Cost Segregation study that saved me a ton on taxes.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1024096332105377824"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Jimmy&apos;s home
              </a>
            </div>
            <div className="testimonial">
              <Image
                src="https://a0.muscache.com/im/pictures/miso/Hosting-564716174289419914/original/9ecc5107-abe9-4729-bee6-d3c1f298fb64.jpeg?im_w=1440"
                alt="Dorinda A."
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  objectFit: "cover"
                }}
                $mobileSize={mobileSize}
              />
              <Header
                size={3}
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
              >
                Dorinda A.
              </Header>
              <Text>
                Instead of just buying an expensive home, we needed a property
                that could also provide flexible income. That is where Chalet
                stepped in. Their comprehensive data research was compelling.
                But, the concierge, personal approach was out of this world.
              </Text>
              <Stars />
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                target="_blank"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
                rel="noreferrer"
              >
                Book a stay at Dorinda&apos;s home
              </a>
            </div>
          </div>
        </div>
      </Section>
      <Section
        id="home-statistics"
        style={{
          // backgroundImage: `url(&apos;${process.env.PUBLIC_URL}/images/about2.webp&apos;)`,
          background: "var(--sixth)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-black">Connected Investors With</span>
          <span id="highlight">100M+</span>
          <span id="alt-black">in Short-Term Rental Assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ Markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Analyzing</span>
          <span id="highlight">1M+</span>
          <span id="alt-black">Short-Term Rentals</span>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          maxWidth: "100vw",
          background: "var(--white)"
        }}
      >
        <Header
          size={2}
          style={{
            textAlign: "center",
            marginBottom: "2rem",
            fontSize: mobileSize ? "1.7rem" : "2.2rem"
          }}
        >
          AI-Driven Investment Tools & Resources
        </Header>
        <div className="tools-grid">
          <div
            className="tool-card"
            onClick={() => history.push("/analytics/overview")}
            onKeyDown={(e) =>
              e.key === "Enter" && history.push("/analytics/overview")
            }
            role="button"
            tabIndex={0}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/analyze.png`}
              alt="Analyze STR Markets"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.2rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Compare STR Markets
              </Header>
              <Text>
                Find your next investment with market guides, regulations, and
                insights.
              </Text>
              <Link to="/analytics/overview">STR Market Analytics</Link>
            </div>
          </div>
          <div
            className="tool-card"
            onClick={() => history.push("/airbnbs-for-sale")}
            onKeyDown={(e) =>
              e.key === "Enter" && history.push("/airbnbs-for-sale")
            }
            role="button"
            tabIndex={0}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/buy.png`}
              alt="Browse For-Sale Airbnb Listings"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.2rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Browse For-Sale Airbnb Listings
              </Header>
              <Text>
                Explore our curated selection of active for-sale short-term
                rental properties.
              </Text>
              <Link to="/airbnbs-for-sale">For-sale Airbnb Listings</Link>
            </div>
          </div>
          <div
            className="tool-card"
            onClick={() => history.push("/analytics/str-calculator")}
            onKeyDown={(e) =>
              e.key === "Enter" && history.push("/analytics/str-calculator")
            }
            role="button"
            tabIndex={0}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/manage.png`}
              alt="Estimate STR Revenue"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.2rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Estimate STR Revenue
              </Header>
              <Text>
                Use our free calculator to project Airbnb revenue for any
                property.
              </Text>
              <Link to="/analytics/str-calculator">Airbnb Calculator</Link>
            </div>
          </div>
          <div
            className="tool-card"
            onClick={() => history.push("/blog")}
            onKeyDown={(e) => e.key === "Enter" && history.push("/blog")}
            role="button"
            tabIndex={0}
          >
            <Image
              src={`${process.env.PUBLIC_URL}/images/sell.png`}
              alt="Get Guidance"
            />
            <div className="tool-content">
              <Header
                style={{
                  textAlign: "left",
                  paddingLeft: 0,
                  fontSize: "1.2rem",
                  fontWeight: 600
                }}
                size={3}
              >
                Get Guidance
              </Header>
              <Text>
                Access guides and articles to expand your Airbnb investment
                knowledge.
              </Text>
              <Link to="/blog">Blog</Link>
            </div>
          </div>
        </div>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          background: "var(--sixth)",
          maxWidth: "100%",
          padding: "4rem 0"
        }}
      >
        <div className="get-started-container">
          <div className="get-started-content">
            <h2>Ready to get started?</h2>
            <p>
              Instantly match with leading short-term rental real estate agents
              and lenders.
            </p>
            <div className="get-started-buttons">
              {/* <Button onClick={() => history.push("/buying-an-airbnb")}>
                I am buying an Airbnb
              </Button> */}
              <PopupButton
                id="lMhk4Fej"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "buying"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am buying an Airbnb
              </PopupButton>
              <PopupButton
                id="lMhk4Fej"
                shareGaInstance="true"
                hidden={{
                  ...typeformHiddenFields,
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "selling"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am selling an Airbnb
              </PopupButton>
              <PopupButton
                id="M700RBmq"
                shareGaInstance="true"
                hidden={{
                  source_page: "/(cta-bottom-homepage)",
                  start_point: "ancillary"
                }}
                data-id="talk-to-str-expert"
                className="talk-to-str-expert_button_top btn-consult"
                style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
              >
                I am looking for financing
              </PopupButton>
            </div>
          </div>
          <div className="get-started-image">
            <Image
              src={`${process.env.PUBLIC_URL}/images/house-with-palm-trees.svg`}
              alt="House with palm trees illustration"
            />
          </div>
        </div>
      </Section>
      <Section
        id="home-markets"
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundSize: "cover"
        }}
      >
        <Header size={2} id="home-markets__header">
          Airbnb Rentals Top 150 Markets
        </Header>
        <Text id="home-markets__description">
          Instantly compare the{" "}
          <span style={{ color: "var(--quinary)" }}>
            top 150 short-term (Airbnb) rental markets
          </span>{" "}
          in the US by returns on investment, occupancy rates, revenue, home
          price, and more…
        </Text>
        <div id="home-markets__main">
          <div id="left-main">
            <Image
              src={`${process.env.PUBLIC_URL}/images/chalet_markets_2024.webp`}
              alt="Market Comparison guide"
            />
          </div>
          <div id="right-main">
            <Input onChange={handleSelect} name="name" placeholder="Name" />
            <Input onChange={handleSelect} name="email" placeholder="Email" />
            <Text id="disclaimer">
              By signing up, you agree to receive subsequent email and
              third-party marketing communications from Chalet pursuant to our
              Privacy Policy, which you may opt out of, or unsubscribe from at
              any time.
            </Text>
            <Button
              id="free-download"
              data-id="modal_contact_us_message_us_button"
              onClick={handleSubmit}
            >
              Free download now
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;
