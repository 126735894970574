import React from "react";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import InvestorGuides from "../InvestorGuides";

export default function AnalyticsInvestorGuides() {
  return (
    <AnalyticsWrapper>
      <AnalyticsHeader />
      <InvestorGuides analytics />
    </AnalyticsWrapper>
  );
}
