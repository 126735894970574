import React from "react";
import styled from "styled-components";
import { Color } from "../../types";

export interface SVGLineProps extends React.HTMLAttributes<SVGPathElement> {
  color?: Color;
  index?: number;
}

const SVGLine = styled.path<SVGLineProps>`
  fill: none;
  shape-rendering: geometricPrecision;
  stroke: var(--${(props) => props.color});
  stroke-width: 0.5rem;
  stroke-linecap: round;
`;

SVGLine.defaultProps = {
  color: Color.primary
};

export default SVGLine;
