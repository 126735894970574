import React, { useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import hexToRgb from "../hexToRgb";
import { Colors } from "../GlobalStyle";
import { AppContext } from "../AppContext";

export interface MenuButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  href: string;
}

const StyledMenu = styled.div`
  animation: fadeIn 166ms ease forwards;
  background-color: var(--white);
  height: 100vh;
  left: 0;
  padding: 3.5rem 0.5rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1061;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const InnerMenu = styled.div`
  height: 100%;
  overflow: auto;
  overflow: overlay;
  margin-top: 1rem;
  width: 100%;
`;

const StyledMenuButton = styled.button`
  background-color: var(--white);
  border: none;
  border-radius: 0.25rem;

  font-size: 1rem;
  font-weight: 300;
  outline: none;
  padding: 1rem;
  width: 100%;

  &:hover {
    background-color: rgba(${hexToRgb(Colors.primary)}, 0.1);
  }

  &:focus {
    background-color: rgba(${hexToRgb(Colors.primary)}, 0.15);
  }

  a {
    color: var(--primary);
    text-decoration: none;
  }
`;

const MenuButton = ({ href, onClick, ...props }: MenuButtonProps) => {
  const history = useHistory();
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      history.push(href);
      onClick?.(e as any);
    },
    [history]
  );
  return <StyledMenuButton {...props} onClick={handleClick} />;
};

MenuButton.displayName = "NavbarMenuButton";

const NavbarMobileMenu = ({
  children,
  ...props
}: React.HTMLAttributes<any>) => {
  // const [isOpen, setIsOpen] = useState(false);
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useContext(AppContext);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      <svg
        viewBox="0 0 100 56"
        width="40"
        height="40"
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
          // setIsOpen(!isOpen);
        }}
        style={{ zIndex: !isMobileMenuOpen ? 1060 : 1062 }}
      >
        {!isMobileMenuOpen && (
          <>
            <rect width="100" height="12" style={{ fill: Colors.primary }} />
            <rect
              y="24"
              width="100"
              height="12"
              style={{ fill: Colors.primary }}
            />
            <rect
              y="48"
              width="100"
              height="12"
              style={{ fill: Colors.primary }}
            />
          </>
        )}
        {isMobileMenuOpen && (
          <>
            <line
              x1={0}
              x2={100}
              y1={0}
              y2={56}
              strokeWidth={12}
              style={{ stroke: Colors.primary }}
            />
            <line
              x1={0}
              x2={100}
              y1={56}
              y2={0}
              stroke="black"
              strokeWidth={12}
              style={{ stroke: Colors.primary }}
            />
          </>
        )}
      </svg>
      {isMobileMenuOpen && (
        <StyledMenu {...props}>
          <InnerMenu>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child)) {
                // @ts-ignore
                return React.cloneElement(child, {
                  // @ts-ignore
                  onClick: (e: React.MouseEvent) => {
                    child.props.onClick?.(e);
                    // setIsOpen(false);
                    setIsMobileMenuOpen(false);
                  }
                });
              }
              return child;
            })}
          </InnerMenu>
        </StyledMenu>
      )}
    </>
  );
};

NavbarMobileMenu.Button = MenuButton;

export default NavbarMobileMenu;
