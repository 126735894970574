import { HTMLAttributes, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export interface PortalProps extends HTMLAttributes<HTMLDivElement> {
  node?: Element;
}

const Portal = ({ children, node }: PortalProps) => {
  let existingNode = document.getElementById("portal-container");

  if (!node && !existingNode) {
    existingNode = document.createElement("div");
    existingNode.id = "portal-container";
    document.body.appendChild(existingNode);
  } else if (node) {
    existingNode = node as any;
  }

  if (existingNode) {
    return createPortal(children, existingNode);
  }

  return null;
};

export default Portal;
