import styled, { css } from "styled-components";

export interface RowProps {
  noPadding?: boolean;
  $shadow?: boolean;
  $border?: boolean;
  $maintainRow?: boolean;
  $hideMobile?: boolean;
}

const Row = styled.div<RowProps>`
  display: flex;
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 0.5rem;

  // &:first-child,
  // &:last-child {
  //   padding-top: 0;
  // }

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `};

  ${(props) =>
    props.$shadow &&
    css`
      box-shadow: 4px 4px 32px 0px rgba(47, 53, 75, 0.1);
    `};

  ${(props) =>
    props.$border &&
    css`
      border: 1px solid var(--primary);
    `};

  @media (max-width: 800px) {
    flex-direction: ${(props) => (props.$maintainRow ? "row" : "column")};
    padding: 1.25rem 0.5rem;

    ${(props) =>
      props.$hideMobile &&
      css`
        display: none;
      `};

    ${(props) =>
      props.noPadding &&
      css`
        padding: 2rem;
      `};
  }
`;

export default Row;
