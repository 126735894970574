export function getCookie(name: string) {
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    const cookiePair = cookieArray[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function setCookie(attributes: string, expires: string) {
  document.cookie = `${attributes}; expires=${expires}; Path=/`;
}
