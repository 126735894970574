/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import useSWR from "swr";
import { Parser } from "html-to-react";
import Section from "../components/Section";
import Text from "../components/Text";
import { AppContext } from "../components/AppContext";
import Header from "../components/Header";
import Post from "../components/Post";
import Select from "../components/Select";
import Image from "../components/Image";
import SplitView from "../components/SplitView";
import "../styles/Blog.scss";
import shuffleArray from "../utils/arrayUtils"; // Add this import
import {
  Featured,
  FeaturedLeft,
  FeaturedPost,
  FeaturedRight
} from "../components/Featured";
import SkeletonLanding from "../components/SkeletonLanding";
import Modal from "../components/Modal";
import { Color } from "../types";
import Field from "../components/Field";

import Button from "../components/Button";
import { getCookie, setCookie } from "../utils/ManageCookies";
import fetcher from "../utils/fetch";
import SkeletonLoader from "../components/SkeletonLoader";
import ShareButton from "./Analytics/components/ShareButton";

// @ts-ignore
const HtmlToReactParser = new Parser();

const BlogSearch = styled(Select)`
  display: flex;
  flex-direction: column;
  max-height: 260px;
  margin-top: inherit;
  background-color: var(--quinary);
  color: var(--black);
  overflow-y: auto;
`;

const StyledSectionBlog = styled(Section)`
  background: none;
  box-shadow: none;
  border-radius: 0.75rem 0.75rem 0px 0rem;
  padding: 1rem;
`;

const ConsentLabel = () => {
  return (
    <div
      style={{
        fontWeight: 300,
        fontSize: "0.7rem",
        marginTop: "-0.9rem",
        marginBottom: "2rem"
      }}
    >
      By submitting this form, you agree to receive subsequent emails and
      third-party marketing communications from Chalet pursuant to our{" "}
      <Link
        to="/privacy-policy"
        target="_blank"
        style={{
          cursor: "pointer",
          display: "inline-block",
          textDecoration: "underline"
        }}
      >
        Privacy Policy
      </Link>
      , which you may opt out of, or unsubscribe from, at any time.
    </div>
  );
};

const defaultForm = {
  name: "",
  phone: "",
  email: "",
  consent: true
};

const Blog = () => {
  const history = useHistory();
  const { slug } = useParams<any>();
  const { mobileSize, prerenderHeight, setPrerenderHeight } =
    useContext(AppContext);
  const [managementStyle, setManagement] = useState(undefined);
  const [isContactUs, setContactUs] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState(defaultForm);
  const [errors, setErrors] = useState<any>({
    name: false,
    phone: false,
    email: false,
    notes: false
  });
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 12; // Show 12 posts per page

  const { name, email, phone, consent } = form;

  const { data: activePosts, isLoading } = useSWR(
    slug
      ? `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/posts?slug=${slug}`
      : undefined,
    fetcher
  );

  const { data: posts, isLoading: isPostsLoading } = useSWR(
    "https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/posts?per_page=100", // Changed to fetch 100 posts
    fetcher,
    {
      fallbackData: []
    }
  );

  const activePost = activePosts?.[0];

  const searchOptions =
    posts?.map((post: any) => ({
      id: post.id,
      label: post.title?.rendered,
      value: post.slug,
      link: post._links.self[0].href
    })) || [];

  const toggleModal = () => {
    if (isModalOpen) {
      const expiry = new Date();
      expiry.setTime(expiry.getTime() + 7 * 24 * 60 * 60 * 1000); // Adds 7 days
      setCookie("freeEstimate=true", expiry.toUTCString());
    }
    setIsModalOpen(!isModalOpen);
  };
  const transformHTMLContent = (htmlContent: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const images = doc.querySelectorAll("img");
    console.log("images", images);
    images.forEach((image) => {
      const src = image.getAttribute("src");
      console.log("source", src);
      if (
        src &&
        (src.includes("44.231.197.151") ||
          src.includes("dgbqmpxvjgsxl.cloudfront.net"))
      ) {
        const newsrc = src
          .replace("http", "https")
          .replace("44.231.197.151", "dgbqmpxvjgsxl.cloudfront.net");
        // .replace("dgbqmpxvjgsxl.cloudfront.net", "dgbqmpxvjgsxl.cloudfront.net");
        image.setAttribute("src", newsrc);
      }
    });

    return doc.body.innerHTML;
  };
  useEffect(() => {
    if (!slug) {
      setPrerenderHeight("auto");
    }

    const modalSlugs = [
      // "cost-segregation-analysis-for-airbnb-rentals",
      "the-unveiled-potential-of-look-back-cost-segregation",
      // "depreciating-airbnb-vacation-rentals",
      "unlocking-tax-benefits-with-qualified-improvement-property-for-short-term-rentals",
      "real-talk-how-we-slashed-36k-in-taxes-by-taking-charge-of-our-short-term-rental"
    ];
    if (modalSlugs.includes(slug) && !getCookie("freeEstimate")) {
      setTimeout(toggleModal, 5000);
    }
  }, [slug]);

  useEffect(() => {
    if (isContactUs) {
      const payload = {
        name: form.name,
        email: form.email,
        phone: form.phone,
        managementStyle
      };
      fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/cost-seg-contacts",
        {
          method: "POST",
          body: JSON.stringify(payload)
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Form Response was not ok ${response.statusText}`);
          }
          setContactUs(false);
          toggleModal();
          return response.json();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Form error", error);
        });
    }
  }, [isContactUs]);

  const handlePostClick = (url: string) => () => {
    fetch(url)
      .then((response) => response.json())
      .then((body) => {
        history.push(`/blog/${body.slug}`);
      });
  };

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    e.persist();
    let inputName: any;
    let inputValue: any;

    if ((e as any).target.name === "managementStyle") {
      setManagement((e as any).target.value);
    } else {
      const { name: n, value } = e.target as HTMLInputElement;
      inputName = n;
      inputValue = value;

      setForm((prevState) => ({
        ...prevState,
        [inputName]: inputValue
      }));
    }
  };

  const allowContactUs = () => {
    let isEveryElementFilled = Object.entries(form).every(
      ([formName, value]: [string, string | boolean]) => {
        if (
          formName !== "password" &&
          formName !== "confirmpassword" &&
          formName !== "verificationcode" &&
          formName !== "other"
        ) {
          if (formName === "consent") {
            return value;
          }
          return value !== "";
        }
        if (!managementStyle) return false;

        return true;
      }
    );
    isEveryElementFilled = !!managementStyle;
    if (!isEveryElementFilled) {
      const newErrors = { ...errors };
      Object.entries(form).forEach(
        ([formName, value]: [string, string | boolean]) => {
          if (
            formName !== "password" &&
            formName !== "confirmpassword" &&
            formName !== "verificationcode" &&
            formName !== "other"
          ) {
            if (formName === "consent") {
              newErrors.consent = !consent;
            } else {
              newErrors[formName] = value === "";
            }
          }
        }
      );
      setErrors(newErrors);
    }

    return isEveryElementFilled;
  };

  const featuredComponents = posts
    .slice(0, 50)
    .map((post: any, idx: number) => {
      const [title, subtitle] = post.title.rendered.split(":");
      if (idx % 4 === 0 || idx % 4 === 3) {
        return (
          <FeaturedPost
            image={`${post.jetpack_featured_media_url}`}
            title={`${title}`}
            text={`${subtitle || ""}`}
            href={`/blog/${post.slug}`}
          />
        );
      }
      if (idx % 4 === 1) {
        return (
          <FeaturedLeft
            image={`${post.jetpack_featured_media_url}`}
            title={`${title}`}
            text={`${subtitle || ""}`}
            href={`/blog/${post.slug}`}
          />
        );
      }
      if (idx % 4 === 2) {
        return (
          <FeaturedRight
            image={`${post.jetpack_featured_media_url}`}
            title={`${title}`}
            text={`${subtitle || ""}`}
            href={`/blog/${post.slug}`}
          />
        );
      }
      return null;
    });

  const renderPosts = (postsArray: any) => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = postsArray.slice(indexOfFirstPost, indexOfLastPost);

    const finalRender = [];
    const children = currentPosts.reverse();
    let isFirst = true;

    while (children.length) {
      if (children.length >= 2) {
        finalRender.push(
          <Featured
            title={isFirst ? "Featured Posts" : ""}
            key={children.length}
          >
            {children.pop()}
            {children.pop()}
          </Featured>
        );
      } else {
        finalRender.push(
          <Featured
            title={isFirst ? "Featured Posts" : ""}
            key={children.length}
          >
            {children.pop()}
          </Featured>
        );
      }
      isFirst = false;
    }
    return finalRender;
  };

  const renderPagination = () => {
    const pageCount = Math.ceil(featuredComponents.length / postsPerPage);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          padding: "2rem 0",
          alignItems: "center"
        }}
      >
        <Button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          style={{
            background: "var(--quinary)",
            opacity: currentPage === 1 ? 0.5 : 1
          }}
        >
          Previous
        </Button>
        <span style={{ color: "var(--primary)" }}>
          Page {currentPage} of {pageCount}
        </span>
        <Button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, pageCount))
          }
          disabled={currentPage === pageCount}
          style={{
            background: "var(--quinary)",
            opacity: currentPage === pageCount ? 0.5 : 1
          }}
        >
          Next
        </Button>
      </div>
    );
  };

  const renderRecommendedPosts = () => {
    const recommendedPosts = shuffleArray(
      posts.filter((post: any) => post.slug !== slug)
    ).slice(0, 5);

    return (
      <div
        className="recommended-posts"
        style={{
          margin: "2rem auto",
          width: "100%",
          maxWidth: "100%"
        }}
      >
        <Header
          size={2}
          style={{
            marginTop: "0rem",
            // fontWeight: 300,
            textAlign: "center",
            color: "var(--sixth)"
          }}
        >
          What to read next
        </Header>
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            display: "grid",
            gridTemplateColumns: mobileSize ? "1fr" : "repeat(5, 1fr)",
            gap: "1rem",
            width: "100%",
            maxWidth: "100%"
          }}
        >
          {recommendedPosts.map((post: any) => (
            <li
              key={post.id}
              style={{
                width: "100%",
                height: "100%", // Ensure li takes full height,
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
              }}
            >
              <Link
                to={`/blog/${post.slug}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: "100%",
                  height: "100%", // Make link full height
                  textAlign: "left",
                  border: "none",
                  padding: 0,
                  background: "none",
                  cursor: "pointer",
                  display: "block",
                  textDecoration: "none"
                }}
              >
                <div
                  className="recommended-post-card"
                  style={{
                    height: "100%", // Make card full height
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Image
                    src={post.jetpack_featured_media_url}
                    alt={post.title.rendered}
                    className="post-image"
                    style={{
                      aspectRatio: "16/9", // Maintain consistent image ratio
                      objectFit: "cover",
                      width: "100%"
                    }}
                  />
                  <div
                    className="post-content"
                    style={{
                      flex: 1, // Take remaining space
                      padding: "1rem",
                      fontSize: "1.1rem",
                      lineHeight: "1.2rem"
                    }}
                  >
                    {post.title.rendered}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div id="blog_main" style={{ paddingTop: activePost ? "20px" : "120px" }}>
      <Modal toggle={toggleModal} isOpen={isModalOpen} id="blog-modal">
        <Link to="/">
          <img
            id="modal-logo"
            src={`${process.env.PUBLIC_URL}/images/logo2024.png`}
            alt="Chalet"
            loading="lazy"
          />
        </Link>
        <Section
          style={{
            background: mobileSize ? "var(--white)" : "none",
            boxShadow: "none"
          }}
          id="modal-content"
        >
          <div style={{ padding: 0 }} id="modal-title">
            <Header size={2} style={{ textAlign: "left" }}>
              {" "}
              Maximize Your Tax Savings with 100% FREE Cost Segregation Estimate
            </Header>
          </div>
          <div style={{ paddingBottom: "1rem" }} id="modal-main">
            <div
              style={{
                width: mobileSize ? "calc(100% - 0rem)" : "100%"
              }}
            >
              <Field
                name="name"
                placeholder="Name"
                type="text"
                value={name}
                invalid={errors.name}
                onChange={onChange}
              />
              <Field
                name="email"
                placeholder="Email"
                type="email"
                value={email}
                invalid={errors.email}
                onChange={onChange}
              />
              <Field
                name="phone"
                placeholder="Phone Number"
                type="number"
                value={phone}
                invalid={errors.phone}
                onChange={onChange}
              />
              <select
                id="style-select"
                name="managementStyle"
                onChange={onChange}
              >
                <option selected disabled hidden>
                  How do you manage your short-term rental?
                </option>
                <option value="I have a property manager">
                  I have a property manager
                </option>
                <option value="I self-manage">I self-manage</option>
              </select>
              <ConsentLabel />
              {Object.values(errors).some((value) => value) && (
                <Text
                  color={Color.danger}
                  style={{
                    display: "block",
                    padding: "0.5rem",
                    textAlign: "center",
                    width: "100%"
                  }}
                >
                  Fields{" "}
                  {Object.entries(errors)
                    .map(([key, value]) => (value ? key : undefined))
                    .filter((str) => str !== undefined)
                    .join(", ")}{" "}
                  are missing values
                </Text>
              )}
              <Button
                isLoading={isContactUs}
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  background: "var(--quinary)"
                }}
                onClick={() => allowContactUs() && setContactUs(true)}
                data-id="modal_contact_us_message_us_button"
              >
                Get a Free Estimate
              </Button>
            </div>
          </div>
        </Section>
      </Modal>
      <StyledSectionBlog id="blog_header">
        <SplitView
          split="1:1"
          style={{
            padding: mobileSize ? "1rem" : "1rem 2rem 0",
            alignItems: "flex-start",
            maxWidth: mobileSize ? "100vw" : "85vw",
            margin: "1rem auto 0"
          }}
        >
          {!activePost && (
            <div id="location-container" style={{ padding: 0 }}>
              <Text
                weight={300}
                block
                style={{
                  fontSize: mobileSize ? "1.7em" : "2.4rem",
                  lineHeight: mobileSize ? "2rem" : "2.5rem",
                  paddingBottom: mobileSize ? "1rem" : "0.5rem",
                  color: "var(--primary)",
                  fontWeight: 400
                }}
              >
                Stay on top of trends in Short-Term Rental Industry
              </Text>

              <BlogSearch
                id="blog_search"
                style={{
                  marginTop: mobileSize ? "1rem" : "initial",
                  padding: "1rem"
                }}
                isSearchable
                options={searchOptions}
                placeholder={
                  mobileSize
                    ? "Search by keyword"
                    : "Search by keyword or category"
                }
                onChange={(e: any) => handlePostClick(e.value.link)()}
              />
            </div>
          )}

          <div id="blog_header-image" style={{ padding: "0px" }}>
            <Image
              className={`search-image ${activePost ? "_bg" : ""}`}
              src={`${process.env.PUBLIC_URL}/images/blog_header.webp`}
              alt="Review of best Airbnbs in the United States"
              style={{
                margin: "0 auto",
                padding: "0rem",
                maxWidth: "100%",
                display: mobileSize ? "none" : "block"
              }}
              $mobileSize={mobileSize}
            />
          </div>
        </SplitView>
      </StyledSectionBlog>
      {!activePost && (
        <>
          <div
            style={{
              backgroundColor: "var(--white)",
              borderTop: "1px solid var(--primary)"
            }}
          >
            <Post id="blog_landing">
              <SkeletonLanding
                id="blog_skeleton"
                width="100%"
                height={prerenderHeight}
              >
                <div
                  style={{ maxWidth: "1200px", margin: "0rem auto 2rem" }}
                  id="blog_intro"
                >
                  Welcome to dynamic blog, where the world of short-term rentals
                  unfolds in rich detail. At Chalet, we&apos;re dedicated to
                  bridging the gap between savvy investors and top-tier
                  short-term rental service providers. Our platform is a
                  treasure trove of insights, offering free tools, expert
                  guidance, and the latest trends in the industry.
                </div>
                <div
                  style={{ maxWidth: "1200px", margin: "0 auto" }}
                  id="blog_posts"
                >
                  {!isPostsLoading && renderPosts(featuredComponents)}
                  {isPostsLoading && <SkeletonLoader />}
                  {!isPostsLoading && !activePost && renderPagination()}
                </div>
              </SkeletonLanding>
            </Post>
          </div>
        </>
      )}
      {activePost && !isLoading && (
        <div
          style={{
            backgroundColor: "var(--white)",
            borderTop: "1px solid var(--primary)"
          }}
        >
          <Helmet>
            {(activePost as any)?.head_tags.map(
              ({ content, tag, attributes }: any, idx: number) => {
                if (tag === "title") {
                  return <title key={tag}>{content}</title>;
                }

                // console.log('meta')
                if (tag === "meta") {
                  const {
                    name: metaname,
                    content: metaContent,
                    property
                  } = attributes;
                  const replacedContent = metaContent
                    .replace("http", "https")
                    .replace("44.231.197.151", window.location.host)
                    .replace(
                      "dgbqmpxvjgsxl.cloudfront.net",
                      window.location.host
                    );

                  // Add og:image and twitter:image meta tags
                  if (property === "og:image" || name === "twitter:image") {
                    return (
                      <meta
                        key={property || name}
                        property={property}
                        name={name}
                        content={activePost.jetpack_featured_media_url}
                      />
                    );
                  }

                  if (metaname) {
                    // console.log("replacedContent mmetaname", replacedContent);
                    return (
                      <meta
                        key={metaname}
                        name={metaname}
                        content={replacedContent}
                      />
                    );
                  }
                  if (property) {
                    console.log("property", property);
                    console.log("replacedContent", replacedContent);
                    return (
                      <meta
                        key={property}
                        property={property}
                        content={replacedContent}
                      />
                    );
                  }
                }
                if (tag === "link") {
                  const { href, ...otherAttributes } = attributes;
                  let replacedHref = href;
                  const key = `link-${idx}`;
                  if (
                    href.includes("44.231.197.151") ||
                    href.includes("dgbqmpxvjgsxl.cloudfront.net")
                  ) {
                    replacedHref = replacedHref
                      .replace("http", "https")
                      .replace(/\/\d{4}\/\d{2}\/\d{2}\//, "/blog/")
                      .replace("44.231.197.151", window.location.host)
                      .replace(
                        "dgbqmpxvjgsxl.cloudfront.net",
                        window.location.host
                      );
                  }
                  if (
                    replacedHref.includes("blog") &&
                    !replacedHref.includes("wp-json") &&
                    !replacedHref.includes("/feed")
                  ) {
                    console.log("key", key);
                    console.log("replacedHref", replacedHref);
                    return (
                      <link
                        key={key}
                        href={replacedHref}
                        {...otherAttributes}
                      />
                    );
                  }
                }
                return null;
              }
            )}
            {/* Add og:image and twitter:image if not present in head_tags */}

            <meta
              property="og:image"
              content={activePost.jetpack_featured_media_url}
            />

            <meta
              name="twitter:image"
              content={activePost.jetpack_featured_media_url}
            />
          </Helmet>
          <Post
            style={{
              padding: mobileSize ? "1rem" : "3rem 2rem"
            }}
          >
            <div className="main-content" style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0.5rem"
                }}
              >
                <ShareButton />
              </div>
              {(activePost as any)?.title.rendered
                .split(":")
                .map((t: string, idx: number) => (
                  <Header key={t} subtitle={idx === 1}>
                    {t}
                  </Header>
                ))}
              <div
                style={{
                  maxWidth: mobileSize ? "100%" : "1200px",
                  margin: "0 auto"
                }}
              >
                {HtmlToReactParser.parse(
                  transformHTMLContent(activePost?.content?.rendered || "")
                )}
              </div>
            </div>

            {renderRecommendedPosts()}
          </Post>
          <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
            <h6 style={{ margin: 0 }}>
              The information provided on this website regarding short-term
              rental regulations is for general informational and educational
              purposes only. We endeavor to keep the information up-to-date and
              accurate, but laws, regulations, and policies governing short-term
              rentals are subject to change and can vary widely by location and
              over time.
            </h6>
            <h6 style={{ margin: 0 }}>
              Please be aware that the regulatory environment for short-term
              rentals is in a constant state of flux. Changes at the local,
              state, or federal level may occur rapidly and could have a
              significant impact on the operation of short-term rentals.
              Therefore, we cannot guarantee the completeness, reliability, or
              accuracy of the information provided as it may not reflect the
              most current legal developments.
            </h6>
            <h6 style={{ margin: 0 }}>
              This information is not intended to provide, and should not be
              relied on for, legal, tax, or professional advice. We encourage
              you to consult with a professional advisor or legal counsel
              familiar with the specific circumstances of your situation and the
              local regulations before making any decisions based on the
              information found on this site.
            </h6>
            <h6 style={{ margin: 0 }}>
              By using this site, you acknowledge and agree that
              www.getchalet.com is not responsible or liable for any claim,
              loss, or damage arising from the use of the information provided.
              You are ultimately responsible for ensuring compliance with the
              applicable laws and regulations for your short-term rental.
            </h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
