import styled from "styled-components";
import { Colors } from "./GlobalStyle";
import hexToRgb from "./hexToRgb";

const Option = styled.button<any>`
  background: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  padding: 0.5rem;
  text-align: left;
  color: var(--primary);
  width: 100%;

  // &:nth-child(5) {
  //   background-color: var(--primary);
  //   color: var(--white);
  // }
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: rgba(${hexToRgb(Colors.primary)}, 0.2);
  }
`;

export default Option;
