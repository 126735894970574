import { Bar } from "react-chartjs-2";
import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  defaults
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import styled from "styled-components";
import { Colors } from "../../../components/GlobalStyle";
import {
  compactNotation,
  currencyNotation,
  numberNotation,
  percentNotation
} from "../../../utils/numberFormatters";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { Font } from "../../../components/Text";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  ChartDataLabels
);

defaults.font.family = Font.SourceSansPro;

const labelSort = (sortValues: boolean) => (a: any, b: any) => {
  if (sortValues) {
    if (parseFloat(a[1]) > parseFloat(b[1])) {
      return -1;
    }
    if (parseFloat(a[1]) < parseFloat(b[1])) {
      return 1;
    }
    return 0;
  }

  if (a[0] === "Studio" || b[0] === "Studio") {
    return 1;
  }

  if (a[0] > b[0]) {
    return 1;
  }

  if (a[0] < b[0]) {
    return -1;
  }
  return 0;
};

const ChartLabel = styled.span`
  color: var(--primary);
  font-size: 0.9rem;
  font-weight: 500;
`;

const getAnchorPosition = (isAmenitiesChart: boolean, horizontal: boolean) => {
  if (isAmenitiesChart) return "center";
  return horizontal ? "end" : "center";
};

const getAlignPosition = (isAmenitiesChart: boolean, horizontal: boolean) => {
  if (isAmenitiesChart) return "center";
  return horizontal ? "right" : "center";
};

const getOffset = (isAmenitiesChart: boolean, horizontal: boolean) => {
  if (isAmenitiesChart) return 0;
  return horizontal ? 0 : 0;
};

export default function BarChart({
  aspectRatio = 1.75,
  color,
  colors,
  data,
  dataType = "currency",
  datalabels,
  height,
  width,
  percent,
  lighter,
  horizontal = false,
  padding,
  sort = "labels",
  stacked,
  xGrid,
  yGrid,
  xAxis,
  yAxis,
  xAxisPosition,
  yAxisPosition,
  yAxisStyle,
  maintainAspectRatio = false,
  labelPosition = "inside",
  labelComponent,
  isAmenitiesChart = false
}: {
  aspectRatio?: number;
  color?: Colors;
  colors?: string[];
  data: Record<string, any>;
  dataType?: "currency" | "percent" | "number";
  datalabels?: boolean;
  height?: number;
  width?: number;
  lighter?: boolean;
  percent?: boolean;
  padding?: Record<string, number>;
  horizontal?: boolean;
  sort?: "labels" | "values";
  stacked?: boolean;
  xGrid?: boolean;
  yGrid?: boolean;
  xAxis?: boolean;
  yAxis?: boolean;
  xAxisPosition?: "top" | "bottom";
  yAxisPosition?: "left" | "right";
  yAxisStyle?: Record<any, any>;
  maintainAspectRatio?: boolean;
  labelPosition?: "inside" | "outside";
  labelComponent?: React.ComponentType<{ value: number | string }>;
  isAmenitiesChart?: boolean;
}) {
  const labels: string[] = [];
  const horizontalLabels: string[] = [];
  const values: number[] = [];
  const sortValues = sort === "values";

  Object.entries(data)
    .sort(labelSort(sortValues))
    .forEach(([key, value]: [string, any]) => {
      if (key !== "market") {
        labels.push(key.replace("Bedrooms", "BR").replace("Bedroom", "BR"));
        if (typeof value === "number") {
          values.push(parseFloat(String(percent ? value / 100 : value)));
        } else {
          horizontalLabels.push(value);
          const parsed = parseFloat(
            String(value).replace("$", "").replace("+", "").replace(",", "")
          );
          values.push(percent ? parsed / 100 : parsed);
        }
      }
    });

  const largest = Math.max.apply(0, values);
  const reverseValues = values.map(() => largest);

  const calculateAspectRatio = () => {
    if (!maintainAspectRatio) {
      return undefined;
    }
    if (height) {
      return undefined;
    }
    return aspectRatio;
  };

  const datasets = [
    {
      labels: horizontal ? horizontalLabels : labels,
      data: values,
      backgroundColor:
        colors || color || (lighter ? Colors.secondary : Colors.quaternary)
    },
    ...(stacked
      ? [
          {
            labels: horizontal ? horizontalLabels : labels,
            data: reverseValues,
            backgroundColor: Colors.gray,
            plugins: {
              datalabels: {
                display: false
              }
            }
          }
        ]
      : [])
  ];

  const CustomLabel = labelComponent || ChartLabel;

  return (
    <Bar
      data={{
        labels,
        datasets: datasets as any
      }}
      height={height}
      width={width}
      options={{
        indexAxis: horizontal ? "y" : "x",
        responsive: true,
        maintainAspectRatio,
        aspectRatio: calculateAspectRatio(),
        elements: {
          bar: {
            borderRadius: 5
          }
        },
        layout: {
          padding: padding || {
            top: 16,
            left: 8,
            right: labelPosition === "outside" ? 50 : 8
          }
        },
        plugins: {
          datalabels: {
            display: !isNullOrUndefined(datalabels) ? datalabels : horizontal,
            color: isAmenitiesChart ? Colors.white : Colors.primary,
            anchor: getAnchorPosition(isAmenitiesChart, horizontal),
            align: getAlignPosition(isAmenitiesChart, horizontal),
            offset: getOffset(isAmenitiesChart, horizontal),
            formatter: (value, context) => {
              if (context.datasetIndex === 0) {
                if (percent || dataType === "percent") {
                  return `${(value * 100).toFixed(1)}%`;
                }
                if (isAmenitiesChart) {
                  return currencyNotation(value, 0);
                }
                return numberNotation(value);
              }
              return "";
            },
            font: {
              weight: "bold",
              size: isAmenitiesChart ? 11 : undefined
            }
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (context.datasetIndex === 0) {
                  if (percent || dataType === "percent") {
                    return percentNotation(
                      context.parsed[horizontal ? "x" : "y"]
                    );
                  }

                  if (dataType === "currency") {
                    return currencyNotation(
                      context.parsed[horizontal ? "x" : "y"]
                    );
                  }

                  return numberNotation(context.parsed[horizontal ? "x" : "y"]);
                }

                return "";
              }
            },
            filter: (tooltipItem: any): any => {
              if (tooltipItem.datasetIndex === 0) {
                return true;
              }
              return false;
            }
          }
        },
        scales: {
          [horizontal ? "x" : "y"]: {
            display: yAxis || !horizontal,
            position: horizontal ? xAxisPosition : yAxisPosition,
            ticks: {
              color: horizontal ? Colors.quaternary : yAxisStyle?.color,
              callback: (value: any) =>
                percent ? `${Math.round(value * 100)}%` : compactNotation(value)
            },
            grid: {
              display: yGrid || false,
              borderDash: [8]
            }
          },
          [horizontal ? "y" : "x"]: {
            display: xAxis || horizontal,
            ticks: {
              color: horizontal ? yAxisStyle?.color : yAxisStyle?.color,
              font: {
                weight: yAxisStyle?.fontWeight
              }
            },
            stacked,
            position: horizontal ? yAxisPosition : xAxisPosition,
            grid: {
              display: xGrid || false
            }
          }
        }
      }}
    />
  );
}
