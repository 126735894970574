/* eslint-disable camelcase */
import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import styled, { css } from "styled-components";
import Markdown from "markdown-to-jsx";
import { PopupButton } from "@typeform/embed-react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  Polygon,
  OverlayView
} from "@react-google-maps/api";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";
import Flex from "../../components/Flex";
import Text from "../../components/Text";
import GaugeChart from "./components/GaugeChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import {
  compactCurrencyNotation,
  currencyNotation,
  numberNotation,
  percentNotation
} from "../../utils/numberFormatters";
import GrossYield from "./components/GrossYield";
import { Colors } from "../../components/GlobalStyle";
import Star from "../../icons/Star";
import Header from "../../components/Header";
import fetcher from "../../utils/fetch";
import AnalyticsModal from "./components/AnalyticsModal";
import LiveMarkets from "../../liveMarkets";
import SkeletonLoader from "../../components/SkeletonLoader";
import {
  AnalyticsBox,
  AnalyticsBoxTitle,
  ChartWrapper
} from "./components/AnalyticsBoxes";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell
} from "../../components/Table";
import AreaChart from "../../components/AreaChart"; // Add this import

export interface Amenities {
  "Home Theater": string;
  "Free Parking": string;
  Sauna: string;
  Pool: string;
  Gym: string;
  "Hot Tub": string;
  "Pet Friendly": string;
  Balcony: string;
}

// Update the RateData interface to include all bedroom types
export interface RateData {
  market: number;
  Studio: number;
  "1 Bedroom": number;
  "2 Bedroom": number;
  "3 Bedroom": number;
  "4 Bedroom": number;
  "5 Bedroom": number;
  [key: string]: number; // Add this index signature
}

export interface Hosts {
  host_name: string;
  total_listings: string;
  avg_rating: number;
  city: string;
  data_of_analysis: string;
}
export interface MarketData {
  market: string;
  zipcode: string;
  updated: string;
  total_full_time_listings: number;
  annual_revenue: number;
  annual_revenue_max: number;
  supply: RateData;
  average_daily_rate: RateData;
  property_tax: number;
  "airbnb-regulation": string;
  occupancy_rate: RateData;
  gross_yield: string;
  us_gross_yield_ranking: string;
  unemployment_rate: Record<string, string>;
  zillow_home_value_data: Record<string, number>;
  host_info: Hosts[];
  most_impactful_amenities: Amenities;
  demand_source: Record<string, string>;
  row_one_text: Record<string, string>;
  row_two_text: Record<string, string>;
  row_three_text: Record<string, string>;
}

const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 95vw;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 1rem 1rem;
    box-shadow: none;
  }
  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }
  }
`;

const AnalyticRow = styled(Flex)<{ $reverse?: boolean; $isFirstRow?: boolean }>`
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin: 0 2rem;
  // margin-top: 1rem;
  padding: 1rem;

  ${(props) =>
    props.$reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${(props) =>
    props.$isFirstRow &&
    css`
      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
      }
    `}

  &:not(:last-child) {
    border-bottom: 1px solid var(--primary);
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    margin: 0;
  }
`;

const AnalyticsTitle = styled(Flex)`
  justify-content: space-between; // This spreads out the children
  align-items: center; // This vertically centers the children
  flex: 1 1 100%;
  gap: 1rem;
  margin: 0 1rem;
  padding: 1rem;
  padding-bottom: 0;

  h1 {
    font-size: 1.3rem;
    font-weight: inherit;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .analytics-title-updated,
  .analytics-title-market,
  .analytics-title-description,
  .analytics-beta {
    font-weight: 400;
  }

  .analytics-title-updated,
  .analytics-title-market {
    color: var(--quinary);
  }
  .analytics-beta {
    color: var(--white);
    background: var(--quinary);
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    margin-left: -0.75rem;
  }

  .analytics-title-description {
    color: var(--primary);
    position: relative;

    &:after {
      background-color: var(--primary);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:before {
      left: -0.5rem;
    }

    &:after {
      right: -0.5rem;
    }
  }

  @media (max-width: 720px) {
    justify-content: center;
    margin: 0;
  }
`;

const AnalyticSection = styled(Flex)<{
  $column?: boolean;
  $halfColumn?: boolean;
  $third?: boolean;
  $twoFifth?: boolean;
  $verticalSplit?: boolean;
  $isFirstRowText?: boolean;
}>`
  align-items: flex-start;
  flex: 1 1 100%;
  text-align: left;

  ${(props) =>
    props.$isFirstRowText &&
    css`
      @media (min-width: 1024px) {
        grid-column: 1;
      }
    `}

  ${(props) =>
    props.$third &&
    css`
      flex: 1 1 150%;
    `};

  ${(props) =>
    props.$twoFifth &&
    css`
      flex: 1 1 150%;
    `};

  ${(props) =>
    props.$halfColumn &&
    css`
      flex: 1 1 50%;
    `};

  ${(props) =>
    props.$column &&
    css`
      flex-direction: column;
    `};

  ${(props) =>
    props.$verticalSplit &&
    css`
      align-self: stretch;
      gap: 0.5rem;
    `};

  p {
    margin: 0 0 1rem;
  }

  @media (max-width: 815px) {
    flex: 1 1 calc(50% - 2rem);
  }

  @media (max-width: 410px) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const AnalyticsSplitWrapper = styled(Flex)`
  align-items: stretch;
  gap: 0.5rem;
  width: 100%;

  @media (max-width: 415px) {
    flex-direction: column;
  }
`;

const AnalyticsTextWrapper = styled(Flex)`
  align-items: flex-start;
  padding: 0 0.5rem;
  flex-direction: column;
  flex: 1 1 100%;
  font-weight: 300;
  p {
    font-size: 1.1rem;
    font-weight: 300;
  }
  strong {
    color: var(--eighth);
    font-weight: 500;
    font-size: 1.1rem;
  }
`;

const StyledPopupButton = styled(PopupButton)`
  background: var(--linearGradient);
  color: var(--white);
  padding: 0.5rem 1rem;
  border: 0;
  font-weight: 700;
  width: initial;
  text-decoration: underline;
  display: initial;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px rgb(0 0 0 / 40%);
  border-radius: 0.25rem;
  margin: 1rem 0 2rem;

  @media (max-width: 720px) {
    width: 100%;
    display: block;
    margin: 1rem auto;
  }
`;

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const AnalyticsBoxTitleWithYoY = styled(AnalyticsBoxTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const YoYChange = styled.span<{ $isPositive: boolean }>`
  color: ${(props) => (props.$isPositive ? "green" : "red")};
  font-size: 0.9em;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const YoYChangeTitle = styled.span`
  font-size: 0.8em;
  color: var(--secondary);
  margin-bottom: 2px;
`;

const sortChronologically = (
  dataForSorting: Record<string, number | string>
) => {
  // Create a new object without 'yoy_change' and 'market' properties
  const filteredData = Object.fromEntries(
    Object.entries(dataForSorting).filter(
      ([key]) => key !== "yoy_change" && key !== "market"
    )
  );

  return Object.fromEntries(
    Object.entries(filteredData).sort(
      ([a], [b]) => new Date(a).getTime() - new Date(b).getTime()
    )
  );
};

const FullHeightAnalyticsBox = styled(AnalyticsBox)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
`;

const FullHeightChartWrapper = styled(ChartWrapper)`
  flex-grow: 1;
  height: 200px; // Adjust this value as needed
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Modify the orderBedroomData function to work with RateData
const orderBedroomData = (data: RateData): Record<string, number> => {
  const order = [
    "Studio",
    "1 Bedroom",
    "2 Bedroom",
    "3 Bedroom",
    "4 Bedroom",
    "5 Bedroom"
  ];
  return Object.fromEntries(
    order
      .map((key) => [key, data[key]])
      .filter(([_, value]) => value !== undefined)
  );
};

// Add this function to format numbers with commas
const formatNumberWithCommas = (num: number) => {
  return num
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AnalyzeButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: var(--white);
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  transition: background-color 0.2s ease;

  &:hover {
    background: var(--linearGradient);
  }

  &::after {
    content: " →";
    margin-left: 0.5rem;
  }
`;

const RentalRegulationButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: var(--quinary);
  border: 1px solid var(--quinary);
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 1rem;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(var(--quinary-rgb), 0.1);
  }

  &::after {
    content: " →";
    margin-left: 0.5rem;
    color: var(--quinary);
  }
`;

// Add this new styled component
const FullHeightAnalyticsBoxWithTitle = styled(FullHeightAnalyticsBox)`
  display: flex;
  flex-direction: column;
`;

const AnalyticsContent = styled.div`
  display: flex;
  width: 100%;
`;

const AnalyticsRowsContainer = styled.div`
  flex: 0 0 100%;
  overflow-y: auto;
`;

const MapContainer = styled.div`
  flex: 0 0 30%;
  height: 100vh;
  position: sticky;
  top: 0;
  right: 0;
`;

const libraries: any[] = ["places"];

// Add this type definition
type PolygonData = {
  paths: { lat: number; lng: number }[];
  grossYield: number;
  zipcode: string;
};

// Replace the placeholderPolygons with this more detailed dummy data
const placeholderPolygons: PolygonData[] = [
  {
    paths: [
      { lat: 39.7, lng: -105.0 },
      { lat: 39.8, lng: -105.0 },
      { lat: 39.8, lng: -104.9 },
      { lat: 39.7, lng: -104.9 }
    ],
    grossYield: 0.08,
    zipcode: "80202"
  },
  {
    paths: [
      { lat: 39.6, lng: -104.9 },
      { lat: 39.7, lng: -104.9 },
      { lat: 39.7, lng: -104.8 },
      { lat: 39.6, lng: -104.8 }
    ],
    grossYield: 0.06,
    zipcode: "80203"
  }
  // Add more placeholder polygons as needed
];

// Add this custom map style
const simplifiedMapStyle = [
  {
    featureType: "all",
    elementType: "labels",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ visibility: "off" }]
  },
  {
    featureType: "road",
    stylers: [{ visibility: "simplified" }]
  },
  {
    featureType: "landscape",
    stylers: [{ color: "#f5f5f5" }]
  },
  {
    featureType: "water",
    stylers: [{ color: "#e6e6e6" }]
  }
];

// Add this new styled component near the top of the file, after other styled components
const BackToMarketButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--quinary);
  color: var(--sixth);
  padding: 0.25rem;
  border-radius: 0.25rem;
  font-weight: 400;
  // text-decoration: underline;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-right: auto; // This pushes the button to the left

  &:hover {
    background-color: var(--quinary);
  }

  &::before {
    content: "← ";
    margin-right: 0.25rem;
  }
`;

export default function AnalyticsZipcode() {
  const { market, zipcode } = useParams<Record<string, string>>();
  const location = useLocation();
  const history = useHistory();
  const [ratingHeight, setRatingHeight] = React.useState(0);
  const [ratingWrapperWidth, setRatingWrapperWidth] = React.useState(0);

  const urlSlug = React.useMemo(
    () =>
      market
        ? `https://www.getchalet.com/analytics/overview/${market}/${zipcode}`
        : "https://www.getchalet.com/analytics/overview/",
    [market, zipcode]
  );

  const { data, isLoading } = useSWR<MarketData>(
    `https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/market/${market}/${zipcode}`,
    fetcher
  );

  let currentDate = new Date();
  let currentMonth = new Date();
  const currentYear = currentDate.getFullYear();
  const hostData: Record<string, any> = {};

  if (data) {
    const splitUpdated = data.updated.split(" ");
    currentDate = new Date();
    currentDate.setMonth(
      months.findIndex((month) => month === splitUpdated[0])
    );
    currentDate.setFullYear(Number(splitUpdated[1]));
    currentMonth = new Date(currentDate.getTime());
    currentMonth.setMonth(currentMonth.getMonth() - 0);

    data.host_info.forEach(({ host_name, total_listings }) => {
      hostData[host_name] = total_listings;
    });
  }

  const setRef = React.useCallback((node) => {
    if (node) {
      const { height, width } = node.getBoundingClientRect();
      setRatingHeight(height);
      setRatingWrapperWidth(
        node.parentNode.getBoundingClientRect().width - width
      );
    }
  }, []);
  // Near the top of the component, after data is fetched:
  const currentMonthForTitle = months[new Date().getMonth()];

  // Update the RateData interface to include all bedroom types

  // Define the bedroom types
  const bedroomTypes = [
    "Studio",
    "1 Bedroom",
    "2 Bedroom",
    "3 Bedroom",
    "4 Bedroom",
    "5 Bedroom"
  ] as const;
  type BedroomType = typeof bedroomTypes[number];

  // Add these styled components
  const StyledTable = styled(Table)`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  `;

  const StyledTableHeader = styled(TableHeader)`
    font-weight: 400;
  `;

  const StyledTableCell = styled(TableCell)`
    border-right: 1px solid var(--primary);
    padding: 8px;

    &:last-child {
      border-right: none;
    }
  `;

  const StyledTableHeaderCell = styled(StyledTableCell)`
    font-weight: 400;
  `;

  const StyledAnnualRevenueCell = styled(StyledTableCell)`
    font-weight: 700;
    color: var(--primary);
  `;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_PLACES_API_KEY || "",
    libraries
  });

  const mapCenter = { lat: 39.740063, lng: -104.939545 }; // Default to Denver, CO

  // Function to determine color based on gross yield
  // const getColorForYield = (grossYield: number) => {
  //   if (grossYield < 0.05) return "rgba(255, 0, 0, 0.2)";
  //   if (grossYield < 0.07) return "rgba(255, 165, 0, 0.2)";
  //   return "rgba(0, 255, 0, 0.2)";
  // };

  // Function to handle polygon click
  // const handlePolygonClick = (zipcode: string) => {
  //   history.push(`/analytics/${market}/${zipcode}`);
  // };

  // Update the map options
  // const mapOptions = {
  //   streetViewControl: false,
  //   mapTypeControl: false,
  //   zoomControl: true,
  //   fullscreenControl: false,
  //   styles: simplifiedMapStyle // Add this line
  // };

  // Function to calculate the center of a polygon
  const getPolygonCenter = (paths: { lat: number; lng: number }[]) => {
    const latSum = paths.reduce((sum, point) => sum + point.lat, 0);
    const lngSum = paths.reduce((sum, point) => sum + point.lng, 0);
    return {
      lat: latSum / paths.length,
      lng: lngSum / paths.length
    };
  };

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: LiveMarkets[market] || "Not Specified",
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: zipcode || ""
  };

  return (
    <AnalyticsWrapper style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
        <title>
          {`${currentMonthForTitle} ${currentYear} ${
            `${data?.zipcode} Zipcode` || "Market"
          } Airbnb Analytics & Demand Data`}
        </title>
        <meta
          name="description"
          content={`Airbnb Rental Analysis Update for ${
            `${data?.zipcode} Zipcode` || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <link rel="canonical" href={urlSlug} />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content={`${data?.market || "Market"} Analytics | Chalet`}
        />
        <meta
          property="og:description"
          content={`Airbnb Rental Analysis Update for ${
            `${data?.zipcode} Zipcode` || "the selected market"
          }. Annual rental income of ${currencyNotation(
            data?.annual_revenue || 0,
            0
          )}, occupancy rate of ${percentNotation(
            (data?.occupancy_rate.market || 0) / 100
          )}, and average daily rate of ${currencyNotation(
            data?.average_daily_rate.market || 0,
            0
          )}`}
        />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="og:site_name" content="Chalet" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${data?.zipcode || "Market"} Analytics | Chalet`}
        />
        <meta
          name="twitter:description"
          content={`Airbnb Rental Market Update for ${
            data?.market || "the selected market"
          }. View Airbnb rental market data, including income potential, occupancy rates, and more.`}
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
      </Helmet>
      <AnalyticsHeader />

      {isLoading && (
        <AnalyticsWrapper marginTop={false}>
          <SkeletonLoader />
        </AnalyticsWrapper>
      )}
      {!isLoading && data === undefined && (
        <AnalyticsWrapper>
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%"
            }}
          >
            No data.
          </Flex>
        </AnalyticsWrapper>
      )}
      {!isLoading && data !== undefined && (
        <AnalyticsContent>
          <AnalyticsRowsContainer>
            <AnalyticsTitle>
              <BackToMarketButton
                onClick={() => history.push(`/analytics/overview/${market}`)}
              >
                Back to Market
              </BackToMarketButton>
              <h1>
                {/* <span className="analytics-title-updated">
          {currentMonthForTitle} {currentYear}
        </span> */}
                <span className="analytics-title-description">
                  Short-Term Rental Market Update
                </span>
                <span className="analytics-title-market">{data?.market}</span>
              </h1>
            </AnalyticsTitle>
            <AnalyticRow $isFirstRow>
              <AnalyticSection $column $isFirstRowText>
                <AnalyticsTextWrapper>
                  <Markdown>
                    {Object.values(data.row_one_text).join("\n\n")}
                  </Markdown>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AnalyzeButton href="/analytics/str-calculator">
                      Analyze Properties in {data.market}
                    </AnalyzeButton>
                    <RentalRegulationButton
                      href={`/analytics/rental-regulations/${market}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rental Regulation
                    </RentalRegulationButton>
                  </div>
                </AnalyticsTextWrapper>
              </AnalyticSection>
              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>Yearly Income Potential</AnalyticsBoxTitle>
                  <FullHeightChartWrapper>
                    <GaugeChart
                      data={[data.annual_revenue, data.annual_revenue_max]}
                      title={[
                        "Annual Revenue",
                        currencyNotation(data.annual_revenue, 2)
                      ]}
                      $isZipcode
                    />
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Average Daily Rate</div>
                    <div className="analytics-box-title-subtitle">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0
                      }).format(data.average_daily_rate.market)}
                    </div>
                  </AnalyticsBoxTitle>
                  <FullHeightChartWrapper>
                    <BarChart
                      data={orderBedroomData(data.average_daily_rate)}
                      yGrid
                      xAxis
                      color={Colors.primary}
                    />
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
              <AnalyticSection $column $halfColumn $verticalSplit>
                <AnalyticsBox $knockoutDarkBlue $split>
                  <GrossYield
                    grossYield={data.gross_yield}
                    // ranking={data.us_gross_yield_ranking}
                  />
                </AnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow $reverse>
              <AnalyticSection $column $third>
                <AnalyticsTextWrapper>
                  <Markdown>
                    {Object.values(data.row_two_text).join("\n\n")}
                  </Markdown>
                </AnalyticsTextWrapper>
              </AnalyticSection>

              <AnalyticSection>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>
                      Occupancy Rate
                      {` (${
                        months[currentMonth.getMonth()]
                      } ${currentMonth.getFullYear()})`}
                    </div>
                    <div className="analytics-box-title-subtitle">
                      {percentNotation(data.occupancy_rate.market / 100)}
                    </div>
                  </AnalyticsBoxTitle>

                  <FullHeightChartWrapper>
                    <BarChart
                      data={orderBedroomData(data.occupancy_rate)}
                      color={Colors.primary}
                      percent
                      yGrid
                      xAxis
                    />
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
              <AnalyticSection $third>
                <FullHeightAnalyticsBox>
                  <AnalyticsBoxTitle>Available Listings</AnalyticsBoxTitle>
                  <FullHeightChartWrapper
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Header size={3}>Total Available Listings</Header>
                    <Header size={2} style={{ color: Colors.secondary }}>
                      {data.total_full_time_listings}
                    </Header>
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBox>
              </AnalyticSection>
              <AnalyticSection>
                <FullHeightAnalyticsBoxWithTitle>
                  <AnalyticsBoxTitleWithYoY>
                    <div>
                      <div>Zillow Median Home Value</div>
                      <div className="analytics-box-title-subtitle">
                        {compactCurrencyNotation(
                          data?.zillow_home_value_data?.market || 0,
                          1
                        )}
                      </div>
                    </div>
                    {data.zillow_home_value_data.yoy_change && (
                      <YoYChange
                        $isPositive={data.zillow_home_value_data.yoy_change > 0}
                      >
                        <YoYChangeTitle>YoY Change</YoYChangeTitle>
                        {data.zillow_home_value_data.yoy_change > 0 ? "+" : ""}
                        {data.zillow_home_value_data.yoy_change}%
                      </YoYChange>
                    )}
                  </AnalyticsBoxTitleWithYoY>
                  <FullHeightChartWrapper>
                    {data.zillow_home_value_data && (
                      <AreaChart
                        data={
                          sortChronologically(
                            data.zillow_home_value_data
                          ) as any
                        }
                        min={null}
                      />
                    )}
                  </FullHeightChartWrapper>
                </FullHeightAnalyticsBoxWithTitle>
              </AnalyticSection>
            </AnalyticRow>
            <AnalyticRow>
              <AnalyticSection $column $third>
                <AnalyticsTextWrapper>
                  <Markdown>
                    {Object.values(data.row_three_text).join("\n\n")}
                  </Markdown>
                </AnalyticsTextWrapper>
                <AnalyticsBox
                  $decorative
                  style={{
                    alignItems: "flex-start",
                    flex: "1 1 auto"
                  }}
                >
                  <Header
                    size={4}
                    style={{ alignItems: "flex-start", textAlign: "left" }}
                  >
                    Real Estate Agents With
                    <br />
                    Airbnb Rental Investing Experience
                  </Header>
                  <div style={{ padding: "0 1rem", fontSize: "0.875rem" }}>
                    Let our agents guide you to{" "}
                    <strong style={{ color: "var(--primary)" }}>
                      the best short-term rental
                    </strong>{" "}
                    investments in {LiveMarkets[market]}
                  </div>
                  <div style={{ padding: "0 1rem" }}>
                    <StyledPopupButton
                      id="DU51pTsh"
                      shareGaInstance="true"
                      hidden={typeformHiddenFields}
                      className="connect-with-realtor_popup_top"
                    >
                      Connect with Airbnb Realtor
                    </StyledPopupButton>
                  </div>
                </AnalyticsBox>
              </AnalyticSection>
              <AnalyticSection $column $verticalSplit>
                <AnalyticsSplitWrapper>
                  <AnalyticSection $column $halfColumn $verticalSplit>
                    <AnalyticsBox $split>
                      <AnalyticsBoxTitle>Property Tax</AnalyticsBoxTitle>
                      <ChartWrapper $text>
                        {percentNotation(data.property_tax, 2)}
                      </ChartWrapper>
                    </AnalyticsBox>
                    <AnalyticsBox $split $knockoutBlue>
                      <AnalyticsBoxTitle $knockoutBlue>
                        Airbnb Regulation
                      </AnalyticsBoxTitle>
                      <ChartWrapper $textBlue>
                        <a
                          href={`/rental-regulations/${market}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          {data["airbnb-regulation"]
                            .split("-")
                            .map((str) => str[0].toUpperCase() + str.slice(1))
                            .join(" ")}
                        </a>
                      </ChartWrapper>
                    </AnalyticsBox>
                  </AnalyticSection>
                  <AnalyticsBox $split $knockoutBlue>
                    <AnalyticsBoxTitleWithYoY $knockoutBlue>
                      <div>
                        <div>Unemployment Rate</div>
                        <div className="analytics-box-title-subtitle">
                          <div>{data.unemployment_rate.market}</div>
                        </div>
                      </div>
                      {data.unemployment_rate.yoy_change && (
                        <YoYChange
                          $isPositive={
                            parseFloat(data.unemployment_rate.yoy_change) < 0
                          }
                        >
                          <YoYChangeTitle>YoY Change</YoYChangeTitle>
                          {parseFloat(data.unemployment_rate.yoy_change) > 0
                            ? "+"
                            : ""}
                          {data.unemployment_rate.yoy_change}
                        </YoYChange>
                      )}
                    </AnalyticsBoxTitleWithYoY>
                    <ChartWrapper $textBlue>
                      <AreaChart
                        color={Colors.primary}
                        data={
                          sortChronologically(data.unemployment_rate) as any
                        }
                        min={null}
                        y
                        percent
                        yAxisPrecision={1}
                        yAxisTickCount={5}
                      />
                    </ChartWrapper>
                  </AnalyticsBox>
                </AnalyticsSplitWrapper>
                <Flex
                  style={{
                    alignItems: "stretch",
                    gap: "0.5rem",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <AnalyticsBox>
                    <AnalyticsBoxTitle>
                      <div>Most Impactful Amenities</div>
                      <div className="analytics-box-title-subtitle">
                        <div
                          style={{
                            background: "var(--seventh)",
                            color: "var(--white)",
                            padding: "0.25rem 0.5rem",
                            borderRadius: "0.25rem"
                          }}
                        >
                          Beta
                        </div>
                      </div>
                    </AnalyticsBoxTitle>
                    <ChartWrapper>
                      <BarChart
                        aspectRatio={1}
                        data={data.most_impactful_amenities as any}
                        horizontal
                        stacked
                        yAxisStyle={{
                          color: Colors.primary,
                          fontWeight: "normal"
                        }}
                      />
                    </ChartWrapper>
                  </AnalyticsBox>
                </Flex>
              </AnalyticSection>
              <AnalyticSection $column $verticalSplit>
                <AnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Host Info</div>
                    <div>Listings</div>
                    <div>Average Rating</div>
                  </AnalyticsBoxTitle>
                  <Flex style={{ width: "100%" }}>
                    <ChartWrapper>
                      {ratingHeight && (
                        <BarChart
                          sort="values"
                          height={ratingHeight}
                          width={ratingWrapperWidth}
                          data={hostData}
                          dataType="number"
                          color={Colors.primary}
                          datalabels={false}
                          horizontal
                          padding={{
                            top: 0,
                            bottom: 0
                          }}
                          yAxisStyle={{
                            color: Colors.primary,
                            fontWeight: "normal"
                          }}
                        />
                      )}
                    </ChartWrapper>
                    <Flex
                      style={{
                        flex: "1 1 30%",
                        flexDirection: "column",
                        padding: "0.5rem 0"
                      }}
                      ref={setRef}
                    >
                      {data.host_info.map(({ host_name, avg_rating }) => (
                        <Flex key={host_name}>
                          <Star
                            style={{
                              width: "1rem",
                              fill: "var(--linearGradient)"
                            }}
                          />
                          {numberNotation(avg_rating, 2)}
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                </AnalyticsBox>
                <AnalyticsBox>
                  <AnalyticsBoxTitle>
                    <div>Source of Airbnb Demand</div>
                  </AnalyticsBoxTitle>
                  <ChartWrapper>
                    <BarChart
                      sort="values"
                      aspectRatio={1}
                      data={data.demand_source as any}
                      percent
                      horizontal
                      datalabels={false}
                      yAxis
                      yGrid
                      xAxisPosition="top"
                      yAxisStyle={{
                        color: Colors.primary,
                        fontWeight: "normal"
                      }}
                    />
                  </ChartWrapper>
                </AnalyticsBox>
              </AnalyticSection>
            </AnalyticRow>
          </AnalyticsRowsContainer>
          {/* <MapContainer>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "100%"
                }}
                center={mapCenter}
                zoom={10}
                options={mapOptions}
              >
                {placeholderPolygons.map((polygonData) => (
                  <React.Fragment key={polygonData.zipcode}>
                    <Polygon
                      paths={polygonData.paths}
                      options={{
                        fillColor: getColorForYield(polygonData.grossYield),
                        fillOpacity: 0.5,
                        strokeColor: "black",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        clickable: true,
                        draggable: false,
                        editable: false,
                        geodesic: false,
                        zIndex: 1
                      }}
                      onClick={() => handlePolygonClick(polygonData.zipcode)}
                    />
                    <OverlayView
                      position={getPolygonCenter(polygonData.paths)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          background: "var(--white)",
                          position: "relative",
                          padding: "5px",
                          borderRadius: "5px",
                          boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                          fontSize: "12px",
                          fontWeight: "400",
                          transform: "translate(-50%, -50%)"
                        }}
                      >
                        GrossYield: {(polygonData.grossYield * 100).toFixed(1)}%
                      </div>
                    </OverlayView>
                  </React.Fragment>
                ))}
              </GoogleMap>
            )}
          </MapContainer> */}
        </AnalyticsContent>
      )}
      {!isLoading && data !== undefined && (
        <FAQSection>
          <Header size={2}>Frequently Asked Questions</Header>
          <div className="faq-item">
            <Header size={3}>
              💰 What is the average income for Airbnb in {data?.zipcode}?
            </Header>
            <Text>
              Based on our data, the average annual revenue for Airbnb
              properties in {data?.zipcode} is{" "}
              {currencyNotation(data?.annual_revenue, 2)}. However, this can
              vary depending on factors such as property type, location, and
              amenities.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              🌟 Is {data?.zipcode} a good place for investing in Airbnb
              Rentals?
            </Header>
            <Text>
              {data?.zipcode} shows promising potential for Airbnb investments.
              With an average occupancy rate of{" "}
              {percentNotation(data?.occupancy_rate.market / 100)} and a gross
              yield of {data?.gross_yield}, it ranks{" "}
              {data?.us_gross_yield_ranking} among U.S. markets for short-term
              rental investments.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              📈 How profitable is Airbnb in {data?.zipcode}?
            </Header>
            <Text>
              Airbnb can be quite profitable in {data?.zipcode}. The average
              daily rate is ${data?.average_daily_rate.market}, and with an
              occupancy rate of{" "}
              {percentNotation(data?.occupancy_rate.market / 100)}, properties
              here have the potential for significant returns.
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>📋 Is Airbnb allowed in {data?.market}?</Header>
            <Text>
              The current Airbnb regulation status in {data?.market} is{" "}
              {data?.["airbnb-regulation"]
                .split("-")
                .map((str) => str[0].toUpperCase() + str.slice(1))
                .join(" ")}
              . However, regulations can change, so it&apos;s important to stay
              updated on local laws and ordinances. For more detailed
              information, please check our{" "}
              <a
                href={`/analytics/rental-regulations/${market}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.market} short-term rental regulations page
              </a>
              .
            </Text>
          </div>
          <div className="faq-item">
            <Header size={3}>
              📊 What is the occupancy rate in {data?.market}?
            </Header>
            <Text>
              The average occupancy rate for short-term rentals in{" "}
              {data?.market} is{" "}
              {percentNotation(data?.occupancy_rate.market / 100)}. This rate
              can fluctuate based on seasonality and other market factors.
            </Text>
          </div>
          {/* <div className="faq-item">
            <Header size={3}>
              🏘️ Is {data?.market} Airbnb rental market saturated?
            </Header>
            <Text>
              With {formatNumberWithCommas(data?.supply.market)} active
              short-term rentals in {data?.market}, the market shows healthy
              competition. However, the strong occupancy rates and revenue
              potential suggest there&apos;s still room for well-managed
              properties to succeed.
            </Text>
          </div> */}
          <div className="faq-item">
            <Header size={3}>
              🏘️ Do I need an Airbnb realtor in {data?.zipcode}?
            </Header>
            <Text>
              While not strictly necessary, working with a realtor who
              specializes in short-term rentals can be highly beneficial in{" "}
              {data?.market}. They can provide valuable insights into the local
              market, help you find properties with the best investment
              potential, and navigate local regulations. To connect with
              experienced Airbnb realtors in {data?.market}, visit our{" "}
              <a
                href={`/real-estate-agents/${market}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.market} Airbnb realtor page
              </a>
              .
            </Text>
          </div>
        </FAQSection>
      )}
      <AnalyticsModal />
    </AnalyticsWrapper>
  );
}
