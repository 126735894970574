import uniqueId from "lodash.uniqueid";
import styled from "styled-components";
import React, {
  ForwardedRef,
  MouseEvent,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import Field, { FieldProps } from "./Field";
import Option from "./Option";
import Popover, { PopoverProps } from "./Popover";
import useOnClickOutside from "../hooks/useOnClickOutside";
import Flex from "./Flex";
import Text from "./Text";

const { navigator } = window;
const ChevronDown = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   strokeWidth="2"
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  //   className="feather feather-arrow-down"
  // >
  //   <polyline points="6 9 12 15 18 9" />
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="var(--quaternary)"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-search"
  >
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
  </svg>
);

export interface OptionProp {
  label?: string;
  value?: any;
}

export interface SelectProps extends FieldProps {
  options?: OptionProp[];
  isSearchable?: boolean;
}

export const StyledSelect = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;

  input {
    padding-right: 40px;
    cursor: pointer;
    // text-transform: uppercase;
    // font-weight: bold;
    font-size: 1rem;
    font-family: "Source Sans Pro", Impact, "Franklin Gothic Bold", sans-serif;
    background: var(--lightPurple);
  }
  > svg {
    height: 100%;
    margin: 0 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (max-width: 800px) {
    input {
      // padding: 1rem 7rem;
      font-size: 1rem;
    }
  }
`;

const innerFunction = forwardRef(
  (props: PopoverProps, ref: ForwardedRef<any>) => (
    <Popover {...props} ref={ref} />
  )
);

const Menu = styled(innerFunction)`
  display: flex;
  flex-direction: column;
  max-height: 260px;
`;
const Select = ({
  isSearchable,
  name,
  onChange,
  options,
  value: propValue,
  id: propId,
  ...props
}: SelectProps) => {
  const id = useRef(propId || uniqueId("select-"));
  const container = useRef<any>();
  const popoverRef = useRef<HTMLElement>();
  const fieldRef = useRef<HTMLInputElement>();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(propValue);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(popoverRef, (e: React.FocusEvent<HTMLDivElement>) => {
    if (!container.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (value !== propValue) {
      setValue(propValue);
    }
  }, [propValue]);

  const handleClick = () => {
    // console.log("click is triggered");
    setIsOpen(true);
  };

  const handleFocus = () => {
    // console.log("focus is triggered");
    setIsOpen(!isOpen);
  };

  // const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  //   if (e.relatedTarget !== popoverRef.current) {
  //     // setIsOpen(false);
  //     setValue(propValue);
  //     // setSearchValue("");
  //   } else {
  //     setSearchValue("");
  //   }
  // };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Close soft keyboard if the focus is still on an input field
    if (e.relatedTarget !== popoverRef.current) {
      // setIsOpen(false);
      setValue(propValue);
      // setSearchValue("");
      // console.log("Blurring option", e);
      // console.log("Blurring option", e.currentTarget);
      e.currentTarget.blur();
      container.current.blur();
      popoverRef.current?.blur();
      fieldRef.current?.blur();
    } else {
      // console.log("Blurring option", e);
      // console.log("Blurring option", e.currentTarget);
      e.currentTarget.blur();
      container.current.blur();
      popoverRef.current?.blur();
      fieldRef.current?.blur();
      setSearchValue("");
    }
  };

  const handleOptionBlur = (e: any) => {
    if (
      document.activeElement instanceof HTMLElement &&
      document.activeElement.tagName === "INPUT"
    ) {
      document.activeElement.blur();
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // const inputField = e.currentTarget;
      // inputField.blur();
      // console.log("Blurring option", e);
      // console.log("Blurring option", e.currentTarget);
      e.currentTarget.blur();
      container.current.blur();
      fieldRef.current?.blur();
      popoverRef.current?.blur();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown" || e.key === "Tab") {
      if (popoverRef.current) {
        e.preventDefault();
        e.stopPropagation();
        // console.log("current popover", popoverRef.current);
        // console.log("e", options?[e.target.children], e.target.children);

        // e.preventDefault();
        // e.target[0].blur();
        popoverRef.current?.getElementsByTagName("button")[0]?.focus();
      }
    } else {
      // console.log("not a current popover", e);
      // console.log("not a current popover", popoverRef);
    }
  };

  const handleChange = (e: MouseEvent<HTMLInputElement>) => {
    // console.log("e.target as any).value", (e.target as any).value);
    if ((e.target as any).value !== "") {
      setSearchValue((e.target as any).value);
      setIsOpen(true);
    } else {
      setSearchValue("");
      // console.log("value", (e.target as any).value);
    }
  };

  const handleOptionClick = (option: OptionProp) => (e: React.MouseEvent) => {
    // console.log("handling option click", option);
    e.preventDefault();
    setValue(option);
    setSearchValue("");
    setIsOpen(false);
    (e as any).name = name;
    (e as any).value = option;
    onChange?.(e as any);
    fieldRef?.current?.blur();
  };

  const handleOptionKeyDown = (option: OptionProp) => (e: any) => {
    if (e.key === "Enter" || e.key === " ") {
      // console.log("eee is enter or empty", e);
      handleOptionClick(option)(e as any);
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      if (popoverRef.current) {
        e.preventDefault();
        e.stopPropagation();
        const rightButtons = Array.from(
          popoverRef.current?.getElementsByClassName(
            e.target.className.split(" ")[1]
          )
        );
        const targetButtonIndex = rightButtons.findIndex(
          (o: any) => o.childNodes[0].data === e.target.firstChild.data
        );
        const numButtons = rightButtons.length;
        let newIndex;
        if (e.key === "ArrowDown") {
          newIndex = (targetButtonIndex + 1) % numButtons;
        } else {
          newIndex = (targetButtonIndex - 1 + numButtons) % numButtons;
        }
        const targetButton = rightButtons[newIndex] as HTMLButtonElement;
        targetButton.focus();
        // setIsOpen(false);
      }
    }
  };

  const filteredOptions = useMemo(() => {
    // if (options?.length === 51)
    // console.log("options", options?.length);
    return (options || []).filter((option: OptionProp) => {
      // console.log("option", option);
      if (option.label) {
        // const lenSearchValue: number = searchValue.length;
        // const searchedValue: string = option?.label.slice(0, lenSearchValue);
        // console.log("len search value", lenSearchValue, searchedValue);
        // console.log(option?.label, searchValue)
        return option?.label.toLowerCase().includes(searchValue.toLowerCase());
        // return searchedValue.toLowerCase() === searchValue.toLowerCase();
      }
      return false;
    });
  }, [options, searchValue]);

  return (
    <StyledSelect ref={container}>
      <Field
        ref={fieldRef}
        id={id.current}
        // value={searchValue}
        value={isOpen ? searchValue : value?.label}
        name={name}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={!isSearchable}
        {...props}
      />
      <ChevronDown />
      <Menu
        ref={popoverRef}
        isOpen={isOpen}
        target={id.current}
        id="search-dropdown"
      >
        {filteredOptions.length !== 0 &&
          filteredOptions.map((option: OptionProp, idx: number) => {
            const key = `${option.label}-${idx}`;
            return (
              <Option
                key={key}
                onMouseDown={handleOptionClick(option)}
                handleFocus={handleFocus}
                handleBlur={handleOptionBlur}
                onKeyDown={handleOptionKeyDown(option)}
                tabIndex={0}
              >
                {option.label}
              </Option>
            );
          })}
        {filteredOptions.length === 0 && (
          <Flex
            style={{
              alignItems: "center",
              padding: "1rem",
              textAlign: "center"
            }}
          >
            <Text style={{ width: "100%" }}>No options found.</Text>
          </Flex>
        )}
      </Menu>
    </StyledSelect>
  );
};

Select.defaultProps = {
  isSearchable: false
};

export default Select;
