import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Row from "../components/Row";
import Column from "../components/Column";
import Header from "../components/Header";
import { Color } from "../types";
import Text from "../components/Text";
import Button from "../components/Button";
import Section from "../components/Section";

// Styled components
const InputField = styled.input`
  border: none;
  font-size: 1.4rem;
  width: 100%;
  font-weight: 700;
  border: 3px solid var(--lightPurple);
  border-radius: 0.5rem;
  //   box-shadow: 0px 1.4rem 2rem -1rem rgba(108, 129, 171, 0.3);
  color: var(--sixth);
  padding: 0.5rem;
  &:focus {
    outline: none;
  }
`;

const Chip = styled.div<{ noBorders?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background: var(--white);
`;

const ChipRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Label = styled.div`
  color: var(--primary);
  font-size: 1.2rem;
  min-width: 160px;
`;

const SectionTitle = styled.h2`
  color: var(--primary);
  font-size: 1.25rem;
  margin: 1.5rem 0 1rem 0;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  box-shadow: 0px 1.4rem 2rem -1rem rgba(108, 129, 171, 0.3);
  border: 1px solid var(--lightPurple);
  border-radius: 0.5rem;
  padding: 0.5rem;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const StyledSelect = styled.select`
  border: none;
  background: var(--white);
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  width: 100%;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: 1px solid var(--lightPurple);

  &:focus {
    outline: none;
  }
`;

const DSCRValue = styled.span`
  color: var(--fourth);
  font-weight: 500;
  font-size: 2rem;
`;

const InfoSection = styled.div`
  padding: 0rem;
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--lightGray);
  margin-top: 0rem;

  @media (max-width: 800px) {
    margin-top: 1rem;
    padding: 1rem;
  }
`;

const InfoTitle = styled.h2`
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const InfoSubtitle = styled.h3`
  color: var(--primary);
  font-size: 1.5rem;
  margin: 1.5rem 0 0.75rem 0;
`;

const InfoText = styled.p`
  color: var(--primary);
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
`;

// Add new styled component for the tools section
const ToolsSection = styled.div`
  margin-bottom: 2rem;
`;

const ToolsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  max-width: 1200px;
  gap: 1rem;
  margin: 1rem auto 0;
`;

const ToolButton = styled(Button)`
  width: 100%;
  padding: 1rem;
  text-align: center;
  background: var(--white);
  border: 3px solid var(--lightPurple);
  border-radius: 0.5rem;
  color: var(--primary);
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.2s ease;

  &:hover {
    background: var(--lightPurple);
    transform: translateY(-2px);
  }
`;

const DSCRCalculator = () => {
  const [mobileSize] = useState(window.innerWidth <= 800);

  // Income state
  const [grossIncome, setGrossIncome] = useState("50000");

  // Loan Details state
  const [priceValue, setPriceValue] = useState("200000");
  const [downPayment, setDownPayment] = useState("10");
  const [loanAmount, setLoanAmount] = useState("180000");
  const [ltv, setLtv] = useState("0");
  const [interestRate, setInterestRate] = useState("6.0");
  //   const [interestOnly, setInterestOnly] = useState("No");
  const [loanTerm, setLoanTerm] = useState("30");

  // Monthly Payment state
  const [operatingExpenses, setOperatingExpenses] = useState("20");

  // DSCR Calculation
  const [dscrValue, setDscrValue] = useState("0.0");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "grossIncome":
        setGrossIncome(value);
        break;
      case "priceValue":
        setPriceValue(value);
        {
          // Calculate new loan amount when price changes
          const priceDownPayment =
            (parseFloat(downPayment) / 100) * parseFloat(value);
          setLoanAmount((parseFloat(value) - priceDownPayment).toString());
        }
        break;
      case "downPayment":
        setDownPayment(value);
        {
          // Calculate new loan amount when down payment changes
          const valueDownPayment =
            (parseFloat(value) / 100) * parseFloat(priceValue);
          setLoanAmount((parseFloat(priceValue) - valueDownPayment).toString());
        }
        break;
      case "loanAmount":
        setLoanAmount(value);
        break;
      case "ltv":
        setLtv(value);
        break;
      case "interestRate":
        setInterestRate(value);
        break;
      case "operatingExpenses":
        setOperatingExpenses(value);
        break;
      default:
        break;
    }
  };

  const handleClear = () => {
    setGrossIncome("0.00");
    setPriceValue("0.00");
    setDownPayment("0.000");
    setLoanAmount("0.00");
    setLtv("0.000");
    setInterestRate("0.000");
    setLoanTerm("30");
    setOperatingExpenses("20.00");
    setDscrValue("0.000");
  };

  function calculateDSCR() {
    // Parse string values to numbers, ensure we're using actual numbers
    const grossIncomeNum = parseFloat(grossIncome) || 0;
    const loanAmountNum = parseFloat(loanAmount) || 0;
    const interestRateNum = parseFloat(interestRate) || 0;
    const operatingExpensesNum = parseFloat(operatingExpenses) / 100; // Convert percentage to decimal
    const loanTermNum = parseFloat(loanTerm);

    // Calculate monthly mortgage payment
    const monthlyRate = interestRateNum / 12 / 100; // Convert annual % to monthly decimal
    const numPayments = loanTermNum * 12; // Convert years to months
    const monthlyPayment =
      (loanAmountNum * (monthlyRate * (1 + monthlyRate) ** numPayments)) /
      ((1 + monthlyRate) ** numPayments - 1);
    const annualPayment = monthlyPayment * 12;

    // Calculate DSCR
    const operatingExpenseAmount = grossIncomeNum * operatingExpensesNum;
    const totalAnnualDebt = operatingExpenseAmount + annualPayment;
    const dscr = totalAnnualDebt > 0 ? grossIncomeNum / totalAnnualDebt : 0;

    // Format to 3 decimal places
    setDscrValue(dscr.toFixed(3));

    // For debugging
    console.log({
      grossIncome: grossIncomeNum,
      loanAmount: loanAmountNum,
      interestRate: interestRateNum,
      operatingExpenses: operatingExpensesNum,
      monthlyPayment,
      annualPayment,
      operatingExpenseAmount,
      totalAnnualDebt,
      dscr
    });
  }

  return (
    <>
      <Helmet>
        <title>DSCR Calculator for Short-Term Rental Investors | Chalet</title>
        <meta
          name="description"
          content="Calculate your Debt Service Coverage Ratio (DSCR) for short-term rental properties. Essential tool for vacation rental investors to evaluate financing options and property cash flow."
        />
        <meta
          name="keywords"
          content="DSCR calculator, debt service coverage ratio, short-term rental, vacation rental, investment property, rental property financing, DSCR loan"
        />

        {/* Open Graph tags for social media */}
        <meta
          property="og:title"
          content="DSCR Calculator for Short-Term Rental Investors | Chalet"
        />
        <meta
          property="og:description"
          content="Free calculator to determine your property's Debt Service Coverage Ratio (DSCR). Essential for STR investors seeking DSCR loans and evaluating rental property investments."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.getchalet.com/dscr-calculator"
        />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/dscr-calculator-preview.jpg"
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="DSCR Calculator for Short-Term Rental Investors | Chalet"
        />
        <meta
          name="twitter:description"
          content="Free calculator to determine your property's Debt Service Coverage Ratio (DSCR). Essential for STR investors seeking DSCR loans and evaluating rental property investments."
        />
        <meta
          name="twitter:image"
          content="https://ww.getchalet.com/images/dscr-calculator-preview.jpg"
        />

        {/* Additional SEO tags */}
        <link
          rel="canonical"
          href="https://www.getchalet.com/dscr-calculator"
        />
      </Helmet>

      <Section
        style={{ marginTop: "100px", padding: "1rem 2rem", boxShadow: "none" }}
      >
        <ToolsSection>
          <Text
            style={{
              marginBottom: "1rem",
              fontSize: "1.3rem",
              display: "block",
              maxWidth: "1200px",
              margin: "0 auto"
            }}
          >
            Explore our suite of analytics tools designed for short-term rental
            investors.
          </Text>
          <ToolsGrid>
            <ToolButton as="a" href="/analytics/overview" target="_blank">
              Airbnb Market Dashboard
            </ToolButton>
            <ToolButton as="a" href="/analytics/str-calculator" target="_blank">
              Airbnb Calculator
            </ToolButton>
            <ToolButton
              as="a"
              href="/analytics/rental-regulations"
              target="_blank"
            >
              Rental Regulations
            </ToolButton>
          </ToolsGrid>
        </ToolsSection>

        <Header size={1} color={Color.tertiary} style={{ marginTop: "0" }}>
          DSCR Calculator
        </Header>

        <Row
          style={{
            gap: "2rem",
            maxWidth: "1200px",
            margin: "0rem auto",
            padding: mobileSize ? "0rem" : "0rem",
            flexDirection: mobileSize ? "column" : "row"
          }}
        >
          <Column
            style={{
              flex: "1 1 35%",
              boxShadow: "0px 1.4rem 6rem -2rem rgba(108, 129, 171, 0.3)",
              border: "1px solid var(--lightPurple)",
              borderRadius: "0.5rem",
              padding: "2rem"
            }}
          >
            <Column style={{ width: "100%" }} $noPadding>
              <SectionTitle>Income</SectionTitle>
              <InputContainer>
                <Label>Gross Rental Income (annual)</Label>
                <InputField
                  type="number"
                  value={grossIncome}
                  onChange={(e) => setGrossIncome(e.target.value)}
                  style={{ background: "var(--white)" }}
                />
              </InputContainer>

              <SectionTitle>Loan Details</SectionTitle>
              <InputContainer>
                <Label>Price/Value $</Label>
                <InputField
                  type="number"
                  value={priceValue}
                  onChange={(e) => {
                    setPriceValue(e.target.value);
                    // Calculate new loan amount when price changes
                    const priceDownPayment =
                      (parseFloat(downPayment) / 100) *
                      parseFloat(e.target.value);
                    setLoanAmount(
                      (parseFloat(e.target.value) - priceDownPayment).toString()
                    );
                  }}
                  style={{ background: "var(--white)" }}
                />
              </InputContainer>

              <InputContainer>
                <Label>Down Payment %</Label>
                <InputField
                  type="number"
                  value={downPayment}
                  onChange={(e) => {
                    setDownPayment(e.target.value);
                    // Calculate new loan amount when down payment changes
                    const valueDownPayment =
                      (parseFloat(e.target.value) / 100) *
                      parseFloat(priceValue);
                    setLoanAmount(
                      (parseFloat(priceValue) - valueDownPayment).toString()
                    );
                  }}
                  style={{ background: "var(--white)" }}
                />
              </InputContainer>

              <InputContainer>
                <Label>Loan Amount $</Label>
                <InputField
                  type="number"
                  name="loanAmount"
                  value={loanAmount}
                  onChange={onChange}
                  disabled
                  style={{ background: "var(--lightGray)" }}
                />
              </InputContainer>

              <InputContainer>
                <Label>LTV %</Label>
                <InputField
                  type="number"
                  value={ltv}
                  onChange={(e) => setLtv(e.target.value)}
                  disabled
                  style={{ background: "var(--lightGray)" }}
                />
              </InputContainer>

              <InputContainer>
                <Label>Interest Rate %</Label>
                <InputField
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(e.target.value)}
                  style={{ background: "var(--white)" }}
                />
              </InputContainer>
              <InputContainer>
                <Label>Loan Term</Label>
                <StyledSelect
                  value={loanTerm}
                  onChange={(e) => setLoanTerm(e.target.value)}
                >
                  <option value="30">30 years</option>
                  <option value="25">25 years</option>
                  <option value="20">20 years</option>
                  <option value="15">15 years</option>
                </StyledSelect>
              </InputContainer>
              <InputContainer>
                <Label>Operating Expenses %</Label>
                <InputField
                  type="number"
                  value={operatingExpenses}
                  onChange={(e) => setOperatingExpenses(e.target.value)}
                  disabled
                  style={{ background: "var(--lightGray)" }}
                />
              </InputContainer>

              <SectionTitle>Debt Service Coverage Ratio</SectionTitle>
              <InputContainer>
                <Label>DSCR calculation:</Label>
                <DSCRValue>{dscrValue}</DSCRValue>
              </InputContainer>

              <Row
                style={{
                  justifyContent: "flex-start",
                  padding: "1rem 0",
                  gap: "1rem"
                }}
              >
                <Button
                  onClick={() => calculateDSCR()}
                  style={{
                    backgroundColor: "var(--fourth)",
                    color: "var(--white)",
                    border: "none",
                    padding: "0.5rem 1rem",
                    borderRadius: "0.25rem",
                    cursor: "pointer"
                  }}
                >
                  Calculate
                </Button>
                <Button
                  onClick={handleClear}
                  style={{
                    backgroundColor: "var(--primary)",
                    color: "var(--white)",
                    border: "none",
                    padding: "0.5rem 1rem",
                    borderRadius: "0.25rem",
                    cursor: "pointer"
                  }}
                >
                  Clear
                </Button>
              </Row>
            </Column>
          </Column>
          <Column style={{ flex: "1 1 65%" }}>
            <InfoSection>
              <InfoTitle>
                Understanding DSCR (Debt Service Coverage Ratio)
              </InfoTitle>
              <InfoText>
                The Debt Service Coverage Ratio (DSCR) is a crucial metric used
                by lenders to evaluate a property&apos;s ability to generate
                sufficient income to cover its debt obligations. This calculator
                helps real estate investors determine if their rental property
                meets typical DSCR requirements for financing.
              </InfoText>

              <InfoSubtitle>What is DSCR?</InfoSubtitle>
              <InfoText>
                DSCR is calculated by dividing the property&apos;s annual net
                operating income (NOI) by its total annual debt service. A DSCR
                of 1.0 means the property generates just enough income to cover
                its debt payments. Most lenders require a minimum DSCR of 1.25
                for investment properties.
              </InfoText>

              <InfoSubtitle>How to Use This Calculator</InfoSubtitle>
              <InfoText>
                <ol>
                  <li style={{ fontSize: "1.2rem" }}>
                    Enter your expected monthly rental income
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Input the property price and down payment
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Specify loan terms including interest rate and duration
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Add monthly expenses like insurance, taxes, and HOA fees
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    The calculator will automatically compute your DSCR
                  </li>
                </ol>
              </InfoText>

              <InfoSubtitle>DSCR Requirements</InfoSubtitle>
              <InfoText>
                <ul>
                  <li style={{ fontSize: "1.2rem" }}>
                    DSCR &lt; 1.0: Property doesn&apos;`t generate enough income
                    to cover debt
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    DSCR = 1.0: Property breaks even
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    DSCR 1.25+: Typical minimum requirement for most lenders
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    DSCR 1.5+: Strong cash flow position
                  </li>
                </ul>
              </InfoText>

              <InfoSubtitle>Benefits of DSCR Loans</InfoSubtitle>
              <InfoText>
                <ul>
                  <li style={{ fontSize: "1.2rem" }}>
                    No personal income requirements
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Based on property&apos;`s income potential
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Faster approval process
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Suitable for multiple investment properties
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Available for both residential and commercial properties
                  </li>
                </ul>
              </InfoText>

              <InfoSubtitle>Important Considerations</InfoSubtitle>
              <InfoText>
                When calculating DSCR, remember to:
                <ul>
                  <li style={{ fontSize: "1.2rem" }}>
                    Include all rental income sources
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Account for vacancy rates
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Consider all operating expenses
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Factor in seasonal fluctuations
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Include property management costs if applicable
                  </li>
                </ul>
              </InfoText>

              <InfoSubtitle>Why DSCR Matters</InfoSubtitle>
              <InfoText>
                A strong DSCR not only helps secure financing but also indicates
                a property&apos;`s potential profitability. It&apos;`s a
                valuable tool for:
                <ul>
                  <li style={{ fontSize: "1.2rem" }}>
                    Evaluating investment opportunities
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Planning refinancing strategies
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Managing portfolio risk
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Comparing different properties
                  </li>
                  <li style={{ fontSize: "1.2rem" }}>
                    Setting appropriate rental rates
                  </li>
                </ul>
              </InfoText>
            </InfoSection>
          </Column>
        </Row>
      </Section>
    </>
  );
};

export default DSCRCalculator;
