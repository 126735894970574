import React from "react";
import styled from "styled-components";

interface ToggleOption {
  label: string | React.ReactNode;
  value: string;
  className?: string;
}

interface ToggleProps {
  options: ToggleOption[];
  value: string;
  onChange: (value: string) => void;
}

const ToggleWrapper = styled.div`
  display: flex;
  background-color: var(--light-gray);
  border-radius: 20px;
  padding: 4px;
`;

const ToggleButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  background-color: ${(props) =>
    props.active ? "var(--white)" : "transparent"};
  color: ${(props) => (props.active ? "var(--primary)" : "var(--secondary)")};
  border: ${(props) =>
    props.active ? "1px solid var(--lightPurple)" : "none"};
  border-radius: 0.5rem;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.active ? "var(--white)" : "var(--light-gray-hover)"};
  }

  &.active {
    background-color: var(--white);
    color: var(--primary);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    // color: var(--secondary);
    // border: none;
  }
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    min-width: 16px;
    min-height: 16px;
    color: ${(props) => (props.active ? "var(--primary)" : "var(--secondary)")};
    margin-right: 2px;
    line-height: 1;
  }
`;

const Toggle: React.FC<ToggleProps> = ({ options, value, onChange }) => {
  return (
    <ToggleWrapper>
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          active={value === option.value}
          onClick={() => onChange(option.value)}
          className={option.className}
        >
          <i
            className={
              option.value === "grid" ? "fas fa-table-cells" : "fas fa-list"
            }
          />
          {option.label}
        </ToggleButton>
      ))}
    </ToggleWrapper>
  );
};

export default Toggle;
