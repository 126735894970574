import styled from "styled-components";

interface SkeletonLandingProps {
  width?: string;
  height?: string;
  zIndex?: number;
  display?: string;
}

const SkeletonLanding = styled.div<SkeletonLandingProps>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  z-index: ${(props) => props.zIndex};
  display: ${(props) => props.display || "block"} !important;
  font-size: 1.2rem;
  font-weight: 200;
  justify-content: center;
  align-items: center;
`;

export default SkeletonLanding;
