import React, { RefObject, useRef } from "react";
import debounce from "lodash.debounce";
import uniqueId from "lodash.uniqueid";
import styled from "styled-components";
import ChartTooltip from "./ChartTooltip";
import useCombinedRefs from "../../utils/useCombinedRefs";

export interface DefaultChartProps extends React.HTMLAttributes<SVGElement> {
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginTop?: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export const defaultChartProps: DefaultChartProps = {
  marginBottom: 20,
  marginLeft: 8,
  marginRight: 8,
  marginTop: 8
};

export interface SVGBaseProps extends DefaultChartProps {
  height: number;
  getSVGWidth(height: number, width: number): void;
  showTooltip?: boolean;
  tooltipData?: any[];
  width: number;
}

export const ChartTooltipWrapper = styled.div`
  display: flex;
  padding: 0.25rem;

  span {
    flex: 1 1 auto;
    margin: 0 0.25rem;

    &:last-child {
      text-align: right;
    }
  }
`;

export const AxisLabel = styled.text`
  fill: var(--gray);
  font-size: 0.75rem;
`;

const Container = styled.div`
  color: inherit;
  padding: 0;
  position: relative;
  width: 100%;
`;

export const StyledSVGBase = styled.svg`
  height: 100%;
  padding: 0;
  pointer-events: all;
  transition: all;
  width: 100%;

  .tooltip-line {
    transition: all 332ms ease;
  }
`;

export interface DataValue {
  date: number;
  value: number;
}

export const SVGBase = React.forwardRef(
  (
    {
      children,
      className,
      getSVGWidth,
      height,
      marginLeft,
      marginTop,
      showTooltip,
      tooltipData,
      width,
      xAxisLabel,
      yAxisLabel,
      ...props
    }: SVGBaseProps,
    ref: React.ForwardedRef<any>
  ): React.ReactElement => {
    const svgRef: RefObject<any> = useRef();
    const combinedRef = useCombinedRefs(svgRef, ref);

    const resizeEvent = (): void => {
      if (svgRef !== null && svgRef !== undefined) {
        const { clientHeight, clientWidth } = svgRef.current;
        getSVGWidth(
          clientHeight - (yAxisLabel ? 24 : 0),
          clientWidth - (xAxisLabel ? 24 : 0)
        );
      }
    };

    React.useEffect(() => {
      if (svgRef !== null && svgRef !== undefined) {
        resizeEvent();
        window.addEventListener("resize", debounce(resizeEvent, 50));

        return function cleanup() {
          window.removeEventListener("resize", debounce(resizeEvent, 50));
        };
      }

      return undefined;
    }, [svgRef.current]);

    return (
      <Container
        ref={combinedRef}
        className={className}
        style={{ height: "100%", position: "relative" }}
      >
        <StyledSVGBase
          {...props}
          preserveAspectRatio="xMinYMin meet"
          viewBox={`0 0 ${width} ${height}`}
        >
          <g
            transform={`translate(${
              marginLeft! + (xAxisLabel ? 8 : 0)
            }, ${marginTop})`}
          >
            {children}
          </g>
          {yAxisLabel && (
            <AxisLabel
              style={{ transform: "translate(16px, 50%) rotateZ(-90deg)" }}
            >
              {yAxisLabel}
            </AxisLabel>
          )}
          {xAxisLabel && (
            <AxisLabel style={{ transform: `translate(50%, ${height + 8}px)` }}>
              {xAxisLabel}
            </AxisLabel>
          )}
        </StyledSVGBase>
        {showTooltip && (
          <ChartTooltip isOpen={showTooltip} target={uniqueId("svg-tooltip-")}>
            {tooltipData}
          </ChartTooltip>
        )}
      </Container>
    );
  }
);

SVGBase.displayName = "SVGBase";

export default SVGBase;
