import styled from "styled-components";

export interface BarProps {
  color?: string;
}

const Bar = styled.rect`
  fill: var(--${(props) => props.color});
`;

Bar.defaultProps = {
  color: "primary"
};

export default Bar;
