import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import { PopupButton } from "@typeform/embed-react";
import useSWR from "swr";
import { Parser } from "html-to-react";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Text from "../components/Text";
import Select, { OptionProp } from "../components/Select";
import Header from "../components/Header";
import Post from "../components/Post";
import { AppContext } from "../components/AppContext";
import Image from "../components/Image";
import SplitView from "../components/SplitView";
import "../styles/InvestorGuides.scss";
import SkeletonLanding from "../components/SkeletonLanding";
import { Featured, FeaturedPost, FeaturedRight } from "../components/Featured";
import fetcher from "../utils/fetch";
import AnalyticsWrapper from "./Analytics/components/AnalyticsWrapper";
import SkeletonLoader from "../components/SkeletonLoader";

// @ts-ignore
const HtmlToReactParser = new Parser();

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0 !important;
  padding-left: 0 !important;

  label {
    margin-bottom: 0;
  }
`;

const InvestorLocationsDropdown = styled(Select)`
  display: flex;
  flex-direction: column;
  max-height: 260px;
  margin-top: initial;
  background-color: var(--quinary);
  color: var(--white);
  overflow-y: auto;
  padding: 1rem !important;

  @media (max-width: 720px) {
    margin-top: 1rem;
    width: 100%;
  }
`;

const StyledSectionInvestorGuides = styled(Section)`
  display: flex;
  flex-direction: column;
  overflow: inherit;
  padding: 1rem;
  margin-bottom: -16rem;
  box-shadow: none;
  border-radius: 0.75rem 0.75rem 0 0;
  background: none;

  @media (max-width: 800px) {
    & > svg {
      top: 0.5rem;
    }
  }

  @media (max-width: 720px) {
    padding: 0;
    margin-bottom: initial;
  }
`;

const MainInvestorGuides = styled.div<{ analytics?: boolean }>`
  background: none;

  ${(props) =>
    !props.analytics &&
    css`
      padding-top: 120px;
    `};
`;

// const StyledPost = styled(Post)<{ $isLoaded?: boolean }>`
//   ${(props) =>
//     !props.$isLoaded &&
//     css`
//       min-height: 100vh;
//
//       @media (max-width: 720px) {
//         min-height: 9000px;
//       }
//     `}
// `;

const InvestorGuideSplitView = styled(SplitView)`
  align-items: flex-start;
  padding: 1rem 2rem 0;
  max-width: 85vw;
  margin: 1rem auto 0;

  @media (max-width: 720px) {
    padding: 1rem;
    max-width: 100vw;
  }
`;

const InvestorGuidesTitle = styled(Text)`
  font-size: 2.4rem;
  line-height: 2.5rem;
  padding-bottom: 0.5rem;
  color: var(--primary);
  font-weight: 400;

  @media (max-width: 720px) {
    font-size: 1.9rem;
    line-height: 2rem;
    padding-bottom: 1rem;
  }
`;

const InvestorGuidesSkeleton = styled(SkeletonLanding)`
  height: fit-content;

  @media (max-width: 720px) {
    height: auto;
  }
`;

const ActivePostWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 720px) {
    max-width: 100%;
  }
`;

const StyledImage = styled(Image)`
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
`;

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function getMarketFromSlug(slug = "") {
  // let marketFromSlug = ''
  let marketFromSlug = slug ? toTitleCase(slug.replace(/-/g, " ")) : "";

  if (
    [
      "fort-myers-fl",
      "lake-havasu-city-az",
      "panama-city-beach-fl",
      "pompano-beach-fl",
      "gulf-shores-al",
      "san-antonio-tx",
      "west-palm-beach-fl"
    ].includes(slug)
  ) {
    marketFromSlug = marketFromSlug.replace(
      marketFromSlug.slice(-3),
      `, ${marketFromSlug.slice(-2).toUpperCase()}`
    );
  } else if (slug === "broward-county-fl-investor-guide-airbnb-rentals") {
    marketFromSlug = "Fort Lauderdale, FL";
  } else if (slug === "memphis-tn-investor-guide-gross-yields-from-24") {
    marketFromSlug = "Memphis, TN";
  } else {
    marketFromSlug = marketFromSlug.replace(
      marketFromSlug.slice(-3),
      `, ${marketFromSlug.slice(-2).toUpperCase()}`
    );
  }
  return marketFromSlug;
}

function getSearchPlaceholder(slug = "") {
  const splitLocation = window.location.pathname.split("/");
  const label = splitLocation[splitLocation.length - 1];
  if (label === "investor-guides" || label === "") {
    return "Search Locations";
  }
  return getMarketFromSlug(slug);
}

const InvestorGuides = ({ analytics }: { analytics?: boolean }) => {
  const history = useHistory();
  const { slug: slugParam, market: marketParam } = useParams<any>();
  const { mobileSize } = useContext(AppContext);
  const location = useLocation();
  // const [isContactUs, setContactUs] = useState(false);
  // const [, setThankYou] = useState(false);
  const [categories, setCategories] = useState<OptionProp[]>();

  // const cookies = React.useMemo(() => {
  //   const newCookies: Record<string, string> = {};
  //
  //   getAllCookies().forEach(([cookieName, val]) => {
  //     newCookies[cookieName] = val;
  //   });
  //
  //   return newCookies;
  // }, [getAllCookies]);

  const slug = slugParam || marketParam;

  const market = React.useMemo(
    () => getMarketFromSlug(slug),
    [slug, marketParam]
  );

  const placeholder = React.useMemo(() => getSearchPlaceholder(slug), [slug]);

  // const { fullName, email, consent } = defaultForm;

  const { data: activePosts, isLoading } = useSWR(
    slug
      ? `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/investor_guides?slug=${slug}`
      : undefined,
    fetcher,
    {
      fallbackData: []
    }
  );

  // const { data: activeCategories, isLoadingCategories } = useSWR(
  //   !slug && activeCategory
  //     ? `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/investor_guides?categories=${activeCategory.id}`
  //     : undefined,
  //   fetcher
  // );

  const activePost = activePosts[0];

  useEffect(() => {
    const allCategories: any[] = [];
    const perPage = 100; // Adjust based on what the API supports. Usually, it's 100.

    const fetchCategories = async (page: number) => {
      const response = await fetch(
        `https://dgbqmpxvjgsxl.cloudfront.net/wp-json/wp/v2/categories?per_page=${perPage}&page=${page}`
      );

      // Check for the successful response
      if (!response.ok) {
        throw new Error("Server responded with an error");
      }

      // Read the total number of pages from the headers
      const totalPages = response.headers.get("X-WP-TotalPages");

      // Parse the JSON response
      const body = await response.json();

      // Filter and map the categories as before
      const newCategories = body
        .filter(
          (category: any) =>
            category.link.includes("investor_guides") &&
            category.slug !== "investor_guides"
        )
        .map((category: any) => ({
          id: category.id,
          label: category.name,
          value: category.slug
        }));

      allCategories.push(...newCategories);

      return totalPages ? parseInt(totalPages, 10) : 1;
    };

    const fetchAllCategories = async () => {
      try {
        // Start with the first page
        const totalPages = await fetchCategories(1);

        // If there are more pages, fetch those as well
        if (totalPages > 1) {
          // Create an array of promises for all the pages we want to fetch
          const fetchPromises = [];
          for (let page = 2; page <= totalPages; page++) {
            fetchPromises.push(fetchCategories(page)); // no 'await' here
          }

          // Now we wait for all the pages to be fetched concurrently
          await Promise.all(fetchPromises);
        }

        // After all pages have been fetched, update the state
        setCategories(allCategories);
      } catch (error) {
        // Handle the error here
        // eslint-disable-next-line no-console
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchAllCategories();
  }, []);

  // useEffect(() => {
  //   async function addContact() {
  //     // eslint-disable-next-line
  //     console.log("name", fullName);
  //     // eslint-disable-next-line
  //     console.log("Consent", consent);
  //     const emailDownloadMarketReportEl = document.getElementById(
  //       "email_for_download_report"
  //     ) as HTMLInputElement;
  //     const nameDownloadMarketReportEl = document.getElementById(
  //       "name_for_download_report"
  //     ) as HTMLInputElement;
  //
  //     try {
  //       const requestOptions = {
  //         method: "POST",
  //         // headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify({
  //           name:
  //             fullName ||
  //             (nameDownloadMarketReportEl
  //               ? nameDownloadMarketReportEl.value
  //               : ""),
  //           email:
  //             email ||
  //             (emailDownloadMarketReportEl
  //               ? emailDownloadMarketReportEl.value
  //               : ""),
  //           downloadReport: true,
  //           market,
  //           consent,
  //           ...cookies,
  //           sourcePage: "investor-guides-v2"
  //         })
  //       };
  //       // const apiData = await API.post("customers", "/contacts", data);
  //       await fetch(
  //         "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
  //         requestOptions
  //       );
  //
  //       setThankYou(true);
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       // eslint-disable-next-line
  //       console.log("error scheduling consultation:", error);
  //     }
  //     setContactUs(false);
  //   }
  //   if (isContactUs) {
  //     setContactUs(true);
  //     addContact();
  //   }
  // }, [isContactUs, market]);

  const onSelect = (e: any) => {
    history.push(`/investor-guides/${e.value.value}`);
  };
  const transformHTMLContent = (htmlContent: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const images = doc.querySelectorAll("img");
    console.log("images", images);
    images.forEach((image) => {
      const src = image.getAttribute("src");
      console.log("source", src);
      if (
        src &&
        (src.includes("44.231.197.151") ||
          src.includes("dgbqmpxvjgsxl.cloudfront.net"))
      ) {
        const newsrc = src
          .replace("http", "https")
          .replace("44.231.197.151", "dgbqmpxvjgsxl.cloudfront.net");
        // .replace("dgbqmpxvjgsxl.cloudfront.net", "dgbqmpxvjgsxl.cloudfront.net");
        image.setAttribute("src", newsrc);
      }
    });

    return doc.body.innerHTML;
  };

  const landingInfo = JSON.parse(localStorage.getItem("landingInfo") || "{}");

  const typeformHiddenFields = {
    market_of_interest: "Not Specified",
    source_page: location.pathname,
    utm_source: landingInfo.utm_source || "",
    utm_medium: landingInfo.utm_medium || "",
    anon_user_id: landingInfo.anon_user_id || "",
    user_id: landingInfo.user_id || "",
    utm_campaign: landingInfo.utm_campaign || "",
    utm_term: landingInfo.utm_term || "",
    utm_content: landingInfo.utm_content || "",
    subquery: ""
  };

  return (
    <MainInvestorGuides className="investor-guides_main" analytics={analytics}>
      {!activePost && (
        <Helmet>
          <link
            rel="canonical"
            href="https://www.getchalet.com/analytics/investor-guides/"
          />
          <title>Chalet | Short-Term Rental (Airbnb) Investor Guides</title>
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content="Chalet provides analysis of short-term rental real estate
            markets and national economic trends. Ultimate investor guides
            contain everything from regulations, taxgross yields, median revenue,
            occupancy rates, guest origin, top property management firms, and much more."
          />
          <meta
            property="og:title"
            content="Chalet | Short-Term Rental (Airbnb) Investor Guides"
          />
          <meta
            property="og:description"
            content="Chalet provides analysis of short-term rental real estate
            markets and national economic trends. Ultimate investor guides
            contain everything from regulations, taxgross yields, median revenue,
            occupancy rates, guest origin, top property management firms, and much more."
          />
          <meta property="og:image" content="" />
          <meta
            property="og:url"
            content="https://www.getchalet.com/analytics/investor-guides/"
          />
          <meta property="og:site_name" content="Chalet" />
        </Helmet>
      )}
      {activePost && (
        <Helmet>
          {(activePost as any)?.head_tags.map(
            ({ content, tag, attributes }: any, idx: number) => {
              if (tag === "title") {
                return <title key={tag}>{content}</title>;
              }
              if (tag === "meta") {
                const { name, content: metaContent, property } = attributes;
                const replacedContent = metaContent
                  .replace("http", "https")
                  .replace("44.231.197.151", window.location.host);
                if (name) {
                  return (
                    <meta key={name} name={name} content={replacedContent} />
                  );
                }
                if (property) {
                  return (
                    <meta
                      key={property}
                      property={property}
                      content={replacedContent}
                    />
                  );
                }
              }
              if (tag === "link") {
                const { href, ...otherAttributes } = attributes;
                let replacedHref = href;
                const key = `link-${idx}`;
                if (
                  href.includes("44.231.197.151") ||
                  href.includes("dgbqmpxvjgsxl.cloudfront.net")
                ) {
                  replacedHref = replacedHref
                    .replace("http", "https")
                    .replace("44.231.197.151", window.location.host)
                    .replace(
                      "dgbqmpxvjgsxl.cloudfront.net",
                      window.location.host
                    );
                }
                if (href.includes("investor_guides")) {
                  replacedHref = replacedHref.replace(
                    "investor_guides",
                    "investor-guides"
                  );
                }
                if (replacedHref.includes("investor-guides")) {
                  replacedHref = replacedHref.replace(
                    "investor-guides",
                    "analytics/investor-guides"
                  );
                }
                if (
                  replacedHref.includes("investor-guides") &&
                  !replacedHref.includes("wp-json") &&
                  !replacedHref.includes("/feed")
                ) {
                  console.log("key", key);
                  console.log("replacedHref", replacedHref);
                  return (
                    <link key={key} href={replacedHref} {...otherAttributes} />
                  );
                }
              }
              return null;
            }
          )}
        </Helmet>
      )}
      {!analytics && (
        <StyledSectionInvestorGuides className="search-investor-guides">
          <InvestorGuideSplitView split="1:1">
            <LocationWrapper>
              <InvestorGuidesTitle>
                Airbnb Rental Investor Guides
              </InvestorGuidesTitle>
              <InvestorLocationsDropdown
                isSearchable
                options={categories}
                placeholder={placeholder}
                onChange={onSelect}
                data-id="investor-guides_dropdown"
              />
            </LocationWrapper>

            <div style={{ padding: "0px" }}>
              <StyledImage
                className="search-image"
                src="/images/investor-guides3.0.webp"
                alt="Airbnb Rental Guides for investors in short-term rentals"
                width="552px"
                height="408px"
                loading="eager"
              />
            </div>
          </InvestorGuideSplitView>
        </StyledSectionInvestorGuides>
      )}
      <Post
        id={!activePost && !isLoading ? "no-market" : ""}
        // $isLoaded={!!activePost}
      >
        {!activePost && !isLoading && (
          <InvestorGuidesSkeleton>
            <div id="no-market_intro">
              Looking to invest in short-term rentals and not sure where to
              start? Our investor guides provide comprehensive information and
              guidance on a range of investment strategies for those interested
              in the short-term rental market.
              <br />
              <br />
              Our guides are tailored to investors of all experience levels and
              will provide you with the knowledge and tools you need to make
              informed investment decisions and maximize your returns.
              <br />
              <br />
              <br />
              <Featured title="Popular Markets">
                <FeaturedPost
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/galveston-tx-thumb.webp"
                  title="Galveston, TX"
                  text="The most popular homes are 3-bedroom homes, comprising 35.23% of inventory. The average gross yield in Galveston is 16.07%..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/galveston-tx`}
                />
                <FeaturedRight
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/kissimmee-fl-thumb.webp"
                  title="Kissimmee, FL"
                  text="There has been a 3.30% increase in the supply of short-term rentals in Kissimmee. With a thriving tourism industry and a growing population..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/kissimmee-fl`}
                />
              </Featured>
              <Featured title="">
                <FeaturedRight
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/big-bear-city-ca-thumb.webp"
                  title="Big Bear Lake, CA"
                  text="The short-term rental market in Big Bear City, CA, is thriving, with 3,800 active short-term rentals as reported by Airdna, a market research firm. The occupancy rate is 40%, resulting in an annual revenue of ..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/big-bear-lake-ca`}
                />
                <FeaturedPost
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/memphis-tn-thumb.webp"
                  title="Memphis, TN"
                  text="Considering the average daily rate and occupancy rate, the annual revenue for (Airbnb) short-term rentals in Memphis amounts to $35,500..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/memphis-tn`}
                />
              </Featured>
              <Featured title="">
                <FeaturedPost
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/gulf-shores-al-thumb.webp"
                  title="Gulf Shores, AL"
                  text="The average gross yield for short-term rentals in Gulf Shores, AL, is still relatively high at 17.03%. This is above the national average of..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/gulf-shores-al`}
                />
                <FeaturedRight
                  image="https://dgbqmpxvjgsxl.cloudfront.net/wp-content/uploads/2024/05/minneapolis-mn-thumb.webp"
                  title="Minneapolis, MN"
                  text="According to Zillow, the median home value in Minneapolis is $317,760. Additionally, homes in Minneapolis have appreciated by..."
                  href={`${
                    analytics ? "/analytics" : ""
                  }/investor-guides/minneapolis-mn`}
                />
              </Featured>
            </div>
          </InvestorGuidesSkeleton>
        )}
        {!activePost && isLoading && (
          <AnalyticsWrapper marginTop={false}>
            <SkeletonLoader />
          </AnalyticsWrapper>
        )}
        {activePost && (
          <>
            {(activePost as any)?.title.rendered
              .split(":")
              .map((t: string, idx: number) => (
                <Header
                  key={t}
                  subtitle={idx === 1}
                  // style={{ fontSize: mobileSize ? "2rem" : "3rem" }}
                >
                  {t}
                </Header>
              ))}
            <ActivePostWrapper>
              {HtmlToReactParser.parse(
                transformHTMLContent(activePost?.content?.rendered || "")
              )}
            </ActivePostWrapper>
            <div
              id="ctas"
              style={{
                boxShadow: mobileSize
                  ? "none"
                  : "rgb(67 73 94 / 27%) 0px 0px 43px",
                background: `url(${process.env.PUBLIC_URL}/images/about2.webp)`,
                backgroundSize: "cover",
                borderRadius: "0.5rem",
                padding: mobileSize ? "0rem" : "2rem",
                maxWidth: mobileSize ? "100%" : "1200px",
                margin: "0 auto"
                // padding: mobileSize ? 0 : "2rem"
              }}
            >
              <Section
                style={{
                  boxShadow: mobileSize ? "0 0 43px rgb(0 0 0 / 10%)" : "none",
                  background: "none",
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100wv" : "100wv",
                  padding: mobileSize ? "2rem" : "0rem"
                }}
              >
                <Header
                  size={2}
                  // font={Font.Pacifico}
                  style={{
                    color: "var(--primary)",
                    textAlign: mobileSize ? "center" : "left",
                    display: mobileSize ? "none" : "block"
                  }}
                >
                  <img
                    loading="lazy"
                    // src={`${process.env.PUBLIC_URL}/logo.webp`}
                    src={`${process.env.PUBLIC_URL}/images/logo2024.png`}
                    alt="Chalet"
                    style={{
                      // marginTop: mobileSize ? "0.5rem" : "0rem",
                      width: mobileSize ? "110px" : "110px",
                      marginBottom: mobileSize ? "0rem" : "-0.1em"
                    }}
                  />
                </Header>
                <div className="first">
                  {/* <Header size={2} style={{ color: "var(--primary)" }}>
              Airbnb Rental Investing for{" "}
              <Text style={{ fontSize: "2.0rem", fontWeight: 400 }}>
                {" "}
                The Independent & Savvy
              </Text>{" "}
            </Header> */}
                </div>
                <SplitView split="2:1" style={{ alignItems: "center" }}>
                  <div style={{ gridRowStart: mobileSize ? 2 : "initial" }}>
                    <div
                      style={{ marginTop: mobileSize ? "1.6rem" : "initial" }}
                      className="second"
                    >
                      <Text
                        block
                        style={{ textAlign: "left", fontSize: "1.6rem" }}
                      >
                        Chalet helps you find{" "}
                        <Text>
                          the{" "}
                          <Text
                            style={{
                              // fontSize: mobileSize ? "1.6rem" : "1.4rem",
                              fontSize: "1.6rem",
                              fontWeight: 400
                              // color: "var(--quinary)"
                            }}
                          >
                            right market
                          </Text>
                        </Text>
                        ,{" "}
                        <Text>
                          the{" "}
                          <Text
                            style={{
                              // fontSize: mobileSize ? "1.6rem" : "1.6rem",
                              fontSize: "1.6rem",
                              fontWeight: 400
                              // color: "var(--quinary)"
                            }}
                          >
                            right realtor
                          </Text>
                        </Text>
                        <Text>
                          &nbsp;and&nbsp;the{" "}
                          <Text
                            style={{
                              // fontSize: mobileSize ? "1.6rem" : "1.6rem",
                              fontSize: "1.6rem",
                              fontWeight: 400
                              // color: "var(--quinary)"
                            }}
                          >
                            right Airbnb rental investment
                          </Text>
                        </Text>
                      </Text>
                    </div>
                    <div
                      className="third"
                      style={{
                        alignItems: "center",
                        display: mobileSize ? "block" : "flex",
                        fontSize: "1rem",
                        paddingTop: ".75rem",
                        padding: mobileSize ? "0" : "0rem",
                        paddingLeft: "0rem"
                      }}
                    >
                      {" "}
                      {/* <PopupButton
                        id="DU51pTsh"
                        // open="load"
                        // data-tf-lazy="false"
                        shareGaInstance="true"
                        hidden={{
                          market_of_interest:
                            getMarketFromSlug() || "Not Specified",
                          source_page: slug
                        }}
                        data-id="talk-to-str-expert"
                        style={{
                          // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                          backgroundColor: mobileSize
                            ? "var(--quinary)"
                            : "var(--quinary)",
                          textTransform: "uppercase",
                          color: "var(--white)",
                          padding: "1rem",
                          border: 0,
                          width: mobileSize ? "100%" : "initial",
                          textDecoration: "none",
                          display: mobileSize ? "block" : "initial",
                          fontSize: "1rem",
                          cursor: "pointer",
                          textAlign: "center",
                          boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                          borderRadius: "0.25rem",
                          // display: mobileSize ? "block" : "initial",
                          margin: mobileSize ? "1rem auto" : "1rem"
                        }}
                        className="talk-to-str-expert_button_top"
                      >
                        Connect With An Airbnb Realtor
                      </PopupButton> */}
                      <PopupButton
                        id="lMhk4Fej"
                        // open="load"
                        // data-tf-lazy="false"
                        shareGaInstance="true"
                        hidden={{
                          market_of_interest:
                            market === "" ? market : "Not Specified",
                          source_page: `investor-guides/${slug}`
                        }}
                        data-id="talk-to-str-expert"
                        style={{
                          // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                          backgroundColor: "var(--quinary)",
                          textTransform: "uppercase",
                          color: "var(--white)",
                          padding: "1rem",
                          border: 0,
                          width: mobileSize ? "100%" : "initial",
                          textDecoration: "none",
                          display: mobileSize ? "block" : "initial",
                          fontSize: "1rem",
                          cursor: "pointer",
                          textAlign: "center",
                          boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                          borderRadius: "0.25rem",
                          // display: mobileSize ? "block" : "initial",
                          margin: mobileSize ? "1rem auto" : "1rem"
                        }}
                        className="talk-to-str-expert_button_top"
                      >
                        Talk TO STR Expert
                      </PopupButton>
                      <a
                        style={{
                          // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                          backgroundColor: "var(--primary)",
                          textTransform: "uppercase",
                          color: "var(--white)",
                          padding: "1rem",
                          width: mobileSize ? "100%" : "initial",
                          textDecoration: "none",
                          fontSize: "1rem",
                          cursor: "pointer",
                          display: mobileSize ? "block" : "initial",
                          textAlign: "center",
                          boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                          borderRadius: "0.25rem",
                          // display: mobileSize ? "block" : "initial",
                          margin: mobileSize ? "1rem auto" : "1rem"
                        }}
                        data-id="learn-more_button_bottom"
                        href={`/real-estate-agents/${slug}`}
                        target="_blank"
                        title={`Real Estate Agents with Airbnb Rental Expertise in ${market}`}
                        rel="noreferrer"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                  <Image
                    src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
                    alt="Real Estate Agents with Airbnb Rental Expertise"
                    style={{
                      display: mobileSize ? "block" : "block",
                      margin: mobileSize ? "0 auto" : "-5rem auto 0",
                      gridRowStart: mobileSize ? 1 : "initial",
                      padding: "0rem",
                      maxWidth: mobileSize ? "200px" : "250px"
                    }}
                    $mobileSize={mobileSize}
                  />
                </SplitView>
              </Section>
            </div>
            <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
              <h6 style={{ margin: 0 }}>
                The information provided on this website regarding short-term
                rental regulations is for general informational and educational
                purposes only. We endeavor to keep the information up-to-date
                and accurate, but laws, regulations, and policies governing
                short-term rentals are subject to change and can vary widely by
                location and over time.
              </h6>
              <h6 style={{ margin: 0 }}>
                Please be aware that the regulatory environment for short-term
                rentals is in a constant state of flux. Changes at the local,
                state, or federal level may occur rapidly and could have a
                significant impact on the operation of short-term rentals.
                Therefore, we cannot guarantee the completeness, reliability, or
                accuracy of the information provided as it may not reflect the
                most current legal developments.
              </h6>
              <h6 style={{ margin: 0 }}>
                This information is not intended to provide, and should not be
                relied on for, legal, tax, or professional advice. We encourage
                you to consult with a professional advisor or legal counsel
                familiar with the specific circumstances of your situation and
                the local regulations before making any decisions based on the
                information found on this site.
              </h6>
              <h6 style={{ margin: 0 }}>
                By using this site, you acknowledge and agree that
                www.getchalet.com is not responsible or liable for any claim,
                loss, or damage arising from the use of the information
                provided. You are ultimately responsible for ensuring compliance
                with the applicable laws and regulations for your short-term
                rental.
              </h6>
            </div>
          </>
        )}
      </Post>
    </MainInvestorGuides>
  );
};

export default InvestorGuides;
