import React from "react";
import styled from "styled-components";
import { FieldProps } from "./Field";
import Text from "./Text";

export interface InputProps
  extends FieldProps,
    React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  symbol?: string;
}

export const StyledInput = styled.label<{ symbol?: string }>`
  // align-items: center;
  display: block;
  // justify-content: space-between;
  margin: 0.5rem 0;
  position: relative;
  width: 100%;

  // * {
  //   flex: 0 1 auto;
  // }

  input {
    background-color: var(--lightPurple);
    border: 1px solid var(--primary);
    border-radius: 0.25rem;
    font-size: 1.1rem;
    box-sizing: border-box;

    font-weight: 300;
    padding: 0.25rem;
    width: calc(400px + 0.5rem);
  }
`;

const Input = ({ id, label, symbol, type = "text", ...props }: InputProps) => {
  return (
    <StyledInput htmlFor={id} symbol={symbol}>
      <Text weight={300}>{label}</Text>
      <input id={id} type={type} {...props} />
    </StyledInput>
  );
};

export default Input;
