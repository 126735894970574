import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Header from "../components/Header";
import SplitView from "../components/SplitView";
import Image from "../components/Image";
import Input from "../components/Input";
import Button from "../components/Button";
import Section from "../components/Section";
import Group from "../components/Group";

const Container = styled.div`
  padding-top: 0px;

  @media (max-width: 810px) {
    padding-top: 100px;
  }
`;

const DownloadWhitepaper = () => {
  const { mobileSize } = useContext(AppContext);
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (value: any) => {
    if (value.target.name === "email") {
      setEmail(value.target.value);
    } else if (value.target.name === "name") {
      setName(value.target.value);
    }
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userEmail)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: userName,
          sourcePage: "/whitepaper",
          downloadType: "1031-exchange-whitepaper"
        })
      };

      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      );

      const button = document.getElementById(
        "whitepaper-download"
      ) as HTMLButtonElement;
      if (button) {
        button.innerHTML = "✓ Downloaded";
        button.disabled = true;
      }
    } catch (error) {
      console.error("error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Download STR Investment Whitepaper | Chalet</title>
        <meta
          name="description"
          content="Download our comprehensive guide to learn key strategies for identifying high-performing STR properties and maximizing tax benefits through 1031 exchanges."
        />
      </Helmet>

      <Group>
        <Section
          style={{
            boxShadow: "none",
            background: "var(--lightPink)",
            maxWidth: "100%"
          }}
        >
          <div
            className="whitepaper-container"
            style={{
              maxWidth: "1200px",
              margin: "0 auto",
              padding: "0 2rem"
            }}
          >
            <Header
              size={2}
              style={{
                color: "var(--sixth)",
                marginBottom: "0rem",
                paddingBottom: 0
              }}
            >
              Free Whitepaper
            </Header>
            <Header size={3} style={{ marginBottom: "1rem", padding: "0rem" }}>
              The Ultimate STR Guide for 1031 Exchange Investors
            </Header>
            <SplitView
              split="2:3"
              style={{
                alignItems: "center",
                maxWidth: mobileSize ? "100%" : "85vw",
                margin: "0 auto"
              }}
            >
              <div style={{ padding: "1rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/whitepaper-preview.webp`}
                  alt="1031 Exchange STR Investment Guide"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "100%",
                    width: "100%",
                    boxShadow: "0 8px 16px rgb(0 0 0 / 40%)"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div
                style={{
                  color: "var(--white)",
                  padding: mobileSize ? "0rem" : "1rem"
                }}
              >
                {/* ... Rest of the content ... */}
                <Text
                  block
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    marginBottom: "0rem",
                    padding: 0
                  }}
                >
                  Download our comprehensive guide to learn:
                </Text>
                <ul
                  style={{
                    marginBottom: "0rem",
                    fontSize: "1.1rem",
                    lineHeight: "1.6",
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0
                  }}
                >
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Key strategies for identifying high-performing STR
                    properties
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    How to maximize tax benefits through 1031 exchanges
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Market analysis techniques for STR investments
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Common pitfalls to avoid in STR investing
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Expert tips for managing your STR portfolio
                  </li>
                </ul>
                <div style={{ padding: "1rem" }}>
                  <Input
                    onChange={handleSelect}
                    name="name"
                    placeholder="Name"
                    style={{
                      marginBottom: "1.5rem",
                      padding: "1rem",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid var(--lightPurple)",
                      fontSize: "1rem",
                      backgroundColor: "var(--white)"
                    }}
                  />
                  <Input
                    onChange={handleSelect}
                    name="email"
                    placeholder="Email"
                    style={{
                      marginBottom: "0rem",
                      padding: "1rem",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid var(--lightPurple)",
                      fontSize: "1rem",
                      backgroundColor: "var(--white)"
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "0.8rem",
                      marginBottom: "1rem",
                      lineHeight: "1.4",
                      paddingTop: 0,
                      opacity: 0.8
                    }}
                  >
                    By signing up, you agree to receive subsequent email and
                    third-party marketing communications from Chalet pursuant to
                    our Privacy Policy, which you may opt out of, or unsubscribe
                    from at any time.
                  </Text>
                  <Button
                    id="whitepaper-download"
                    data-id="whitepaper_download_button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    style={{
                      width: "100%",
                      background: "var(--fourth)",
                      color: "var(--white)",
                      marginTop: "1rem",
                      padding: "1rem",
                      opacity: isLoading ? 0.7 : 1,
                      cursor: isLoading ? "not-allowed" : "pointer"
                    }}
                  >
                    {isLoading ? "Downloading..." : "Download Free Whitepaper"}
                  </Button>
                </div>
              </div>
            </SplitView>
          </div>
        </Section>
      </Group>
    </Container>
  );
};

export default DownloadWhitepaper;
