import { axisLeft } from "d3-axis";
import { format } from "d3-format";
import { select } from "d3-selection";
import React from "react";
import styled from "styled-components";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export interface YAxisProps {
  yScale: any;
  xAxisZero?: number;
}

const StyledYAxis = styled.g`
  .tick text {
    color: var(--primary);

    font-size: 0.75rem;
  }
  path,
  line {
    stroke: transparent;
  }
`;

const YAxis = ({
  yScale,
  xAxisZero,
  ...props
}: YAxisProps): React.ReactElement => {
  const axisRef: React.RefObject<any> = React.useRef();

  React.useEffect(() => {
    if (!isNullOrUndefined(axisRef.current)) {
      select(axisRef.current).call((g) =>
        g.call(
          axisLeft(yScale)
            .ticks(yScale.range()[0] / 70)
            .tickFormat((d) => format("($,.0f")(d as number))
            .tickSizeOuter(0)
        )
      );
    }
  }, [axisRef.current, yScale, xAxisZero]);

  return (
    <StyledYAxis
      ref={axisRef}
      transform={`translate(${xAxisZero}, 0)`}
      {...props}
    />
  );
};

export default YAxis;
