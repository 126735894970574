import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Flex from "../components/Flex";
import Text from "../components/Text";

const NotFound = () => {
  return (
    <div style={{ paddingTop: "208px" }}>
      <Header>Sorry, that page doesn&apos;t seem to exist!</Header>
      <Flex style={{ justifyContent: "center" }}>
        <Text>
          Please use the navigation above or return to the&nbsp;
          <Link to="/">homepage</Link>.
        </Text>
      </Flex>
    </div>
  );
};

export default NotFound;
