import React from "react";
import styled from "styled-components";
import Image from "./Image";
import Text from "./Text";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  padding: 1rem;
  // margin: 1rem;
  border-radius: 0.5rem;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
`;

const Heading = styled(Text)`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Subheading = styled(Text)`
  font-size: 1.2rem;
  color: var(--primary);
  text-align: center;
`;

interface CardProps {
  icon: string;
  heading: string;
  subheading: string | React.ReactNode;
}

const Card: React.FC<CardProps> = ({ icon, heading, subheading }) => {
  return (
    <CardContainer>
      <IconWrapper>
        <Image
          src={`${process.env.PUBLIC_URL}/images/${icon}`}
          alt={heading}
          style={{ width: "100%", height: "100%" }}
        />
      </IconWrapper>
      <Heading>{heading}</Heading>
      <Subheading>{subheading}</Subheading>
    </CardContainer>
  );
};

export default Card;
