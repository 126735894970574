import React from "react";
import styled from "styled-components";
import Popover, { Placement, PopoverProps } from "../Popover";
import { Color } from "../../types";

export type ChartTooltipProps = PopoverProps;

export const MouseTarget = styled.div`
  color: inherit;
  padding: 0;
  height: 0;
  left: 0;
  position: fixed;
  top: 0;
  width: 0;
`;

export const StyledChartTooltip = styled(Popover)`
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.25rem;
  pointer-events: none;
`;

const ChartTooltip = ({
  target,
  ...props
}: ChartTooltipProps): React.ReactElement => {
  const [{ left, top }, setTooltipLocation] = React.useState({
    left: 0,
    top: 0
  });
  const moveEvent = (e: MouseEvent | TouchEvent): void => {
    const { clientX, clientY, touches } = e as any;
    const x = clientX || touches[0].clientX;
    const y = clientY || touches[0].clientY;
    setTooltipLocation({
      left: x + 16,
      top: y
    });
  };

  React.useEffect(() => {
    window.addEventListener("mousemove", moveEvent);
    window.addEventListener("touchmove", moveEvent);

    return function cleanup() {
      window.removeEventListener("mousemove", moveEvent);
      window.removeEventListener("touchmove", moveEvent);
    };
  }, []);

  return (
    <>
      <MouseTarget
        id={target}
        style={{
          transform: `translate(${left}px, ${top}px)`
        }}
      />
      <StyledChartTooltip
        color={Color.white}
        target={target}
        placement={Placement.top}
        {...props}
        style={{
          left: 0,
          top: 0,
          transform: `translate(${left}px, ${top}px)`
        }}
      />
    </>
  );
};

export default ChartTooltip;
