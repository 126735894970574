function getItemWidth(str: string): number {
  const span = document.createElement("span");
  span.innerText = str;
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
}

export default getItemWidth;
