import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Helmet } from "react-helmet";
import "../../styles/Analytics.scss";
import styled from "styled-components";
import Section from "../../components/Section";
import { Featured, FeaturedCenter } from "../../components/Featured";
import fetcher from "../../utils/fetch";
import Flex from "../../components/Flex";
import AnalyticsHeader from "./components/AnalyticsHeader";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import SkeletonLoader from "../../components/SkeletonLoader";
import MultiSelect from "../../components/MultiSelect";
import AnalyticsMarket from "./AnalyticsMarket";
import Header from "../../components/Header";
import Text from "../../components/Text";

const sortOptions = [
  {
    label: "Gross Yield",
    value: "gross_yield"
  },
  {
    label: "Annual Revenue",
    value: "annual_revenue"
  }
];
const FAQSection = styled.section`
  background: var(--white);
  padding: 4rem 2rem;
  max-width: 95vw;
  margin: 0 auto;

  h2 {
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-item {
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h3 {
      color: var(--primary);
      margin-bottom: 1rem;
    }

    p {
      color: var(--secondary);
      line-height: 1.6;
    }
  }
`;

const AnalyticsMTRPage = () => {
  const { market: marketPathParam } = useParams<any>();
  const [filteredTypes, setFilteredTypes] = React.useState<string[]>([]);
  const [stateTypes, setStateTypes] = React.useState<string[]>([]);
  const [sortType, setSortType] = React.useState<string | undefined>();

  const { data: markets } = useSWR<any[]>(
    "https://zmura59afa.execute-api.us-west-2.amazonaws.com/v1/mtr/markets",
    fetcher,
    {
      fallbackData: []
    }
  );

  const featuredComponents: any = (markets || [])
    .sort((a: any, b: any) => {
      const type = sortOptions.find(({ label }) => label === sortType);
      if (type) {
        return (
          parseFloat(b[type.value].replaceAll("$", "")) -
          parseFloat(a[type.value].replaceAll("$", ""))
        );
      }

      return 0;
    })
    .filter((market: any) => {
      let includesFilter = true;
      let includesState = true;
      if (filteredTypes.length > 0) {
        includesFilter = filteredTypes.includes(market.market_type);
      }
      if (stateTypes.length > 0) {
        includesState = stateTypes.includes(market.state_name);
      }
      return includesFilter && includesState;
    })
    .slice(0, 14)
    .map((market: any) => {
      const key = `market-${market.market}`;
      return (
        <FeaturedCenter
          key={key}
          image={`https://d2mv0zzolg6397.cloudfront.net/market-images-thumbs/${market.market_slug}.jpg`}
          title={market.market}
          gross={market.gross_yield}
          revenue={market.annual_revenue}
          medianHomeValue={market.median_home_value}
          href={`/analytics/mid-term-rentals/${market.market_slug}`}
          // cta="LEARN MORE"
          // right={idx % 2 !== 0}
        />
      );
    });

  const handleApply = (selectedOptions: string[]) => {
    console.log("Selected options:", selectedOptions);
    setFilteredTypes(selectedOptions);
  };

  const handleStateApply = (selectedOptions: string[]) => {
    console.log("Selected state options:", selectedOptions);
    setStateTypes(selectedOptions);
  };

  const handleSortApply = (selectedOptions: string[]) => {
    console.log("Selected state options:", selectedOptions);
    setSortType(selectedOptions[0]);
  };

  const [marketOptions, stateOptions] = (markets || []).reduce(
    (acc: [any[], any[]], cur: any) => {
      acc[0].push(cur.market_type);
      acc[1].push(cur.state_name);

      return acc;
    },
    [[], []]
  );

  if (marketPathParam) {
    return <AnalyticsMarket />;
  }

  return (
    <AnalyticsWrapper>
      <Helmet>
        <title>
          FREE AI-Powered Mid-Term Rental Analytics: MTR Rental Income, Demand
          and more
        </title>
        <meta
          name="description"
          content="Discover our free mid-term rental investment analytics tool offering comprehensive insights into occupancy rates, average monthly rates, revenue estimates, and more. Perfect for investors at any level, with tailored guides, regulation info, and an ROI estimator."
        />
        <link
          rel="canonical"
          href="https://www.getchalet.com/analytics/mid-term-rentals/"
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="FREE Mid-Term Rental Analysis/Tools You Need"
        />
        <meta
          property="og:description"
          content="Free Mid-term rental analysis to get 
          you best ROI. Free Market Reports, MTR Profit 
          Calculator, MTR Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta
          property="og:url"
          content="https://www.getchalet.com/analytics/mid-term-rentals/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.getchalet.com/images/analytics_dashboard.webp"
        />{" "}
        {/* Replace with actual image URL */}
        <meta property="og:site_name" content="Chalet" />
        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Unlock Free Mid-Term Rental Investment Analytics | Chalet"
        />
        <meta
          name="twitter:description"
          content="Free Mid-term rental analysis to get 
          you best ROI. Free Market Reports, MTR Profit 
          Calculator, MTR Regulation Analysis. 
          Real Hard Data from local experts."
        />
        <meta
          name="twitter:image"
          content="https://www.getchalet.com/og-image-analytics.jpg"
        />{" "}
        {/* Replace with actual image URL */}
      </Helmet>
      <AnalyticsHeader />
      <Section id="analytics-main">
        <div id="analytics_landing">
          <div className="analytics_intro">
            Are you exploring mid-term rental investments and seeking accurate
            data to guide your decisions? Our completely free mid-term rental
            investment analytics dashboard provides powerful insights into key
            metrics like occupancy rates, average monthly rates, and supply
            analysis.
          </div>
          <div className="analytics_intro">
            With tools such as a revenue and ROI estimator (MTR Calculator),
            investor guides, regulation updates, and comprehensive mid-term
            rental analysis, you&apos;ll have everything you need to succeed in
            the mid-term rental market.
          </div>
          <div id="analytics_markets">
            <Featured
              title="AI-Powered Mid-Term Rental Analytics"
              actions={
                <Flex style={{ gap: "1rem" }}>
                  <MultiSelect
                    options={marketOptions}
                    onApply={handleApply}
                    placeholder="Market Types"
                  />
                  <MultiSelect
                    options={stateOptions}
                    onApply={handleStateApply}
                    placeholder="States"
                  />
                  <MultiSelect
                    options={sortOptions.map(({ label }) => label)}
                    onApply={handleSortApply}
                    placeholder="Unsorted"
                    prefix="Sort"
                    single
                  />
                </Flex>
              }
            >
              {markets?.length === 0 ? <SkeletonLoader /> : featuredComponents}
            </Featured>
          </div>
        </div>
      </Section>
      <FAQSection>
        <Header size={2}>Frequently Asked Questions</Header>
        <div className="faq-item">
          <Header size={3}>
            What data does the mid-term rental analytics tool provide?
          </Header>
          <Text>
            Our tool offers detailed insights into key metrics such as occupancy
            rates, average monthly rates (AMR), supply analysis, revenue
            projections, and even the impact of amenities. It also includes
            market-specific information like local regulations and an MTR
            calculator for ROI estimation.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            How can I use the tool to find the best markets for mid-term rental
            investments?
          </Header>
          <Text>
            You can filter markets based on various factors such as occupancy
            rates, AMR, and regulations. This allows you to compare different
            markets to find the most profitable ones for mid-term rental
            investments.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Does the tool provide information on mid-term rental regulations?
          </Header>
          <Text>
            Yes, our dashboard includes the latest information on mid-term
            rental regulations for each market, so you can stay compliant and
            understand how local rules might impact your investment.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Can I calculate my potential revenue and ROI using this tool?
          </Header>
          <Text>
            Absolutely! Our MTR Calculator is designed to estimate your revenue
            and ROI based on specific property data. It allows you to adjust key
            variables like occupancy rate, monthly rates, and expenses to give
            you a clear picture of your potential returns.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>
            Does the tool cover long-term rentals as well as mid-term rentals?
          </Header>
          <Text>
            While our primary focus is on mid-term rentals, our analytics tool
            also provides insights into long-term rental opportunities, helping
            you assess whether this option could be a profitable strategy in
            your chosen market.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>How often is the data updated?</Header>
          <Text>
            Our data is updated monthly to ensure you&apos;re getting the most
            accurate and up-to-date insights on market trends, regulations, and
            performance metrics for mid-term rentals.
          </Text>
        </div>
        <div className="faq-item">
          <Header size={3}>Is this tool really free?</Header>
          <Text>
            Yes, our mid-term rental analytics tool is completely free to use,
            with no hidden fees. You can access all the insights, data, and
            analysis at no cost.
          </Text>
        </div>
      </FAQSection>
    </AnalyticsWrapper>
  );
};

export default AnalyticsMTRPage;
