import React from "react";

export default function Star(props: any) {
  return (
    <svg viewBox="0 0 17 16" {...props}>
      <path
        d="M8.5 0L10.4084 5.87336L16.584 5.87336L11.5878 9.50329L13.4962 15.3766L8.5 11.7467L3.50383 15.3766L5.41219 9.50329L0.416019 5.87336L6.59163 5.87336L8.5 0Z"
        fill="url(#linear-gradient)"
      />
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="-2.75582e-07"
          y1="0.235521"
          x2="0.530115"
          y2="17.2768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F06F78" />
          <stop offset="1" stopColor="#F4246C" />
        </linearGradient>
      </defs>
    </svg>
  );
}
