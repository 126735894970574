import React, { useContext, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Header from "../components/Header";
import useCookies from "../hooks/useCookies";
import Flex from "../components/Flex";
import SectionFooter from "../components/SectionFooter";
import SplitView from "../components/SplitView";
import Group from "../components/Group";
import Section from "../components/Section";
import { Color } from "../types";
import LiveMarkets from "../liveMarkets";
import Image from "../components/Image";
import Input from "../components/Input";
import Button from "../components/Button";
import List from "../components/List";

const Container = styled(Flex)`
  // flex-direction: column;
  font-size: 2.5rem;
  height: 100vh;
  width: 85vw;
  margin: 0 auto;
  // width: 50%;
  justify-content: center;
  line-height: 1;
  li {
    color: var(--white);
    font-weight: 200;
    fontSize; 1.2rem;
  }
  h1 {
    font-weight: 300;
    font-size: 2.5rem;
    // text-align: center;
    margin-bottom: 0;
    text-align: left;
    // padding-bottom: 1rem;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    // text-align: center;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }
  padding: 5rem 0;
  .first ${Text} {
    white-space: nowrap;
    font-size: 2.5rem;
  }
  .second h1 {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0;
    color: var(--primary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
    // width: 50%;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
    // width: 50%;
  }
  .second {
    padding-top: 0;
  }
  .third {
    // display: block;
    // text-align: left;
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    // justify-content: start;
    align-items: flex-start;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    h3 {
      font-size: 2rem;
    }
    .first {
      text-align: center;
      h1 {
        font-size: 2.5rem;
        text-align: center;
        ${Text} {
          font-size: 2.5rem;
          text-align: center;
          line-height: 2.6rem;
        }
      }
    }
    .second {
      display: none;
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const Service1031Exchange = () => {
  const { getAllCookies } = useCookies();
  const { marketPathParam } = useParams<any>();
  // const { mobileSize, setOpenContactModal } = useContext(AppContext);
  const { mobileSize } = useContext(AppContext);

  const bodyRef = useRef<HTMLDivElement>();

  const cookies: Record<string, string> = {};

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  const handleInvestorGuideClick = () => {
    let investorGuideSlug = marketPathParam;
    if (marketPathParam === "memphis-tn") {
      investorGuideSlug = "memphis-tn-investor-guide-gross-yields-from-24";
    } else if (marketPathParam === "fort-lauderdale-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    } else if (marketPathParam === "san-diego-county-ca") {
      investorGuideSlug = "encinitas-carlsbad-oceanside-ca";
    } else if (marketPathParam === "lake-worth-fl") {
      investorGuideSlug = "broward-county-fl-investor-guide-airbnb-rentals";
    }
    window.open(`/analytics/investor-guides/${investorGuideSlug}`, "_blank");
  };

  const titleString = `Maximize Your 1031 Exchange with Short-Term Rental Investments | ${
    LiveMarkets[marketPathParam] || ""
  }`;
  const descriptionString = `Chalet helps you unlock the power of short-term rental investments for 
  your 1031 exchange. Our experienced real estate agents are local experts 
  who understand the benefits of working with cash buyers and have 
  a well-connected network of partners and 1031 exchange intermediaries 
  in the ${LiveMarkets[marketPathParam] || ""} market`;

  const urlSlug = marketPathParam
    ? `https://www.getchalet.com/1031-exchange/${marketPathParam}`
    : "https://www.getchalet.com/1031-exchange";

  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (value: any) => {
    if (value.target.name === "email") {
      setEmail(value.target.value);
    } else if (value.target.name === "name") {
      setName(value.target.value);
    }
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userEmail)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: userName,
          sourcePage: "/1031-exchange",
          downloadType: "1031-exchange-whitepaper"
        })
      };

      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      );

      const button = document.getElementById(
        "whitepaper-download"
      ) as HTMLButtonElement;
      if (button) {
        button.innerHTML = "✓ Downloaded";
        button.disabled = true;
      }
    } catch (error) {
      console.error("error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={urlSlug} />
        <title>{titleString}</title>
        <meta property="og:type" content="website" />
        <meta name="description" content={descriptionString} />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:image" content="" />
        <meta property="og:url" content={urlSlug} />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <Container ref={bodyRef as any}>
        <div
          style={{
            // maxWidth: mobileSize ? "100%" : "50%",
            paddingRight: mobileSize ? "0" : "4rem"
          }}
        >
          <div className="first">
            <h1 style={{ lineHeight: mobileSize ? "2.7rem" : "4.0rem" }}>
              Unlock the Power of Short-Term Rental Investments for{" "}
              <Text
                style={{
                  display: "block",
                  // fontSize: mobileSize ? "2rem" : "initial",
                  fontWeight: 400,
                  whiteSpace: "initial"
                }}
              >
                {" "}
                Your 1031 Exchange
              </Text>
            </h1>
          </div>
          <div className="second">
            <h3>
              Chalet helps you find the
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--quinary)"
                }}
              >
                &nbsp;right short-term rental property for your 1031 exchange
                <Text
                  // block
                  style={{ fontSize: mobileSize ? "2rem" : "1.6rem" }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--primary)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              <Text
                block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500
                  // color: "var(--quaternary)",
                  // background: "var(--primary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && "top US markets"}
              </Text>
            </h3>
          </div>
          <div className="third">
            <PopupButton
              id="G3A8zRSV"
              // open="load"
              // data-tf-lazy="false"
              shareGaInstance="true"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified"
              }}
              // data-id="connect-with-realtor"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--primary)"
                  : "var(--primary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                display: mobileSize ? "block" : "initial",
                fontSize: "1rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem"
              }}
              className="connect-with-realtor_popup_top"
            >
              Talk STR Expert
            </PopupButton>
            <a
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                backgroundColor: mobileSize
                  ? "var(--quinary)"
                  : "var(--quinary)",
                textTransform: "uppercase",
                color: "var(--white)",
                padding: "1rem",
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                fontSize: "1rem",

                cursor: "pointer",
                display: mobileSize ? "block" : "initial",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "0rem auto" : "1rem"
              }}
              data-id="learn-more_button_top"
              href="#section1"
              title="/buy"
            >
              Learn More
            </a>
          </div>
          <div
            className="fourth"
            style={{
              display: mobileSize ? "none" : "none",
              marginTop: "5rem"
            }}
          >
            <h3 style={{ color: "var(--white)", textAlign: "center" }}>
              Let our realtors help you find
              <Text
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 400,
                  color: "var(--white)"
                }}
              >
                &nbsp;the best STR investments
                <Text
                  style={{
                    fontSize: mobileSize ? "2rem" : "1.6rem",
                    textAlign: "center"
                  }}
                >
                  {marketPathParam && " in"}
                </Text>{" "}
                <Text
                  style={{
                    color: "var(--white)",
                    fontWeight: "500",
                    // textDecoration: "underline",
                    textAlign: "center",
                    fontSize: mobileSize ? "2rem" : "1.6rem"
                  }}
                >
                  {LiveMarkets[marketPathParam]}
                </Text>
              </Text>
              <Text
                // block
                style={{
                  fontSize: mobileSize ? "2rem" : "1.6rem",
                  fontWeight: 500,
                  color: "var(--white)",
                  textAlign: "center"
                  // background: "var(--primary)",
                  // padding: "1rem",
                  // marginTop: "-1.75rem"
                }}
              >
                {!marketPathParam && " in "}{" "}
                {!marketPathParam && (
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--quaternary)",
                      background: "rgba(255,255,255,0.3)",
                      padding: "0.5rem",
                      marginTop: "0.2rem",
                      borderRadius: "0.5rem",
                      display: "block"
                    }}
                    href="#locations"
                  >
                    top 50 US markets
                  </a>
                )}
              </Text>
            </h3>
          </div>
        </div>
        <div style={{ display: "block", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.png`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>

      <Group>
        <Section
          style={{
            boxShadow: "none",
            background: "var(--lightPink)",
            maxWidth: "100%"
            // padding: "4rem 0"
          }}
        >
          <div
            className="whitepaper-container"
            style={{
              maxWidth: "1200px",
              margin: "0 auto",
              padding: mobileSize ? "0" : "0 2rem"
            }}
          >
            <Header
              size={2}
              style={{
                color: "var(--sixth)",
                marginBottom: "0rem",
                paddingBottom: 0
              }}
            >
              Free Whitepaper
            </Header>
            <Header size={3} style={{ marginBottom: "1rem", padding: "0rem" }}>
              The Ultimate STR Guide for 1031 Exchange Investors
            </Header>
            <SplitView
              split="2:3"
              style={{
                alignItems: "center",
                maxWidth: mobileSize ? "100%" : "85vw",
                margin: "0 auto"
              }}
            >
              <div style={{ padding: "1rem" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/images/whitepaper-preview.webp`}
                  alt="1031 Exchange STR Investment Guide"
                  style={{
                    borderRadius: "0.25rem",
                    maxWidth: mobileSize ? "100%" : "100%",
                    width: "100%",
                    boxShadow: "0 8px 16px rgb(0 0 0 / 40%)"
                  }}
                  $mobileSize={mobileSize}
                />
              </div>
              <div
                style={{
                  color: "var(--white)",
                  padding: mobileSize ? "0rem" : "1rem"
                }}
              >
                <Text
                  block
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    marginBottom: "0rem",
                    padding: mobileSize ? "1rem" : "0rem"
                  }}
                >
                  Download our comprehensive guide to learn:
                </Text>
                <ul
                  style={{
                    marginBottom: "0rem",
                    fontSize: "1.1rem",
                    lineHeight: "1.6",
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0
                  }}
                >
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Key strategies for identifying high-performing STR
                    properties
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    How to maximize tax benefits through 1031 exchanges
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Market analysis techniques for STR investments
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Common pitfalls to avoid in STR investing
                  </li>
                  <li style={{ fontSize: "1.3rem", fontWeight: 300 }}>
                    Expert tips for managing your STR portfolio
                  </li>
                </ul>
                <div style={{ padding: "1rem" }}>
                  <Input
                    onChange={handleSelect}
                    name="name"
                    placeholder="Name"
                    style={{
                      marginBottom: "1.5rem",
                      padding: "1rem",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid var(--lightPurple)",
                      fontSize: "1rem",
                      backgroundColor: "var(--white)"
                    }}
                  />
                  <Input
                    onChange={handleSelect}
                    name="email"
                    placeholder="Email"
                    style={{
                      marginBottom: "0rem",
                      padding: "1rem",
                      width: "100%",
                      borderRadius: "4px",
                      border: "1px solid var(--lightPurple)",
                      fontSize: "1rem",
                      backgroundColor: "var(--white)"
                    }}
                  />
                  <Text
                    style={{
                      fontSize: "0.8rem",
                      marginBottom: "1rem",
                      lineHeight: "1.4",
                      paddingTop: 0,
                      opacity: 0.8
                    }}
                  >
                    By signing up, you agree to receive subsequent email and
                    third-party marketing communications from Chalet pursuant to
                    our Privacy Policy, which you may opt out of, or unsubscribe
                    from at any time.
                  </Text>
                  <Button
                    id="whitepaper-download"
                    data-id="modal_contact_us_message_us_button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    style={{
                      width: "100%",
                      background: "var(--fourth)",
                      color: "var(--white)",
                      marginTop: "1rem",
                      padding: "1rem",
                      opacity: isLoading ? 0.7 : 1,
                      cursor: isLoading ? "not-allowed" : "pointer"
                    }}
                  >
                    {isLoading ? "Downloading..." : "Download Free Whitepaper"}
                  </Button>
                </div>
              </div>
            </SplitView>
          </div>
        </Section>
      </Group>
      <Group>
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Understanding 1031 Exchanges for STR Investments
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "center",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div>
              <Text style={{ fontSize: "1.5rem" }} block align="left">
                A 1031 exchange, also known as a like-kind exchange, allows real
                estate investors to defer capital gains taxes by reinvesting
                proceeds from the sale of an investment property into another
                qualifying property.
              </Text>
              <Text style={{ fontSize: "1.5rem" }} block align="left">
                Short-term rental properties can be excellent candidates for
                1031 exchanges{" "}
                {LiveMarkets[marketPathParam]
                  ? `in the ${LiveMarkets[marketPathParam]} market`
                  : ""}
                , offering:
              </Text>
              <ul style={{ marginLeft: "1.5rem" }}>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Potential for higher returns compared to traditional rentals
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Flexibility in property usage
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Portfolio diversification opportunities
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Tax advantages and appreciation potential
                </li>
              </ul>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/tax-guide.png`}
              alt="1031 Exchange Process Diagram"
              style={{
                borderRadius: "0.25rem",
                maxWidth: mobileSize ? "100%" : "400px",
                width: "100%"
              }}
              $mobileSize={mobileSize}
            />
          </SplitView>
        </Section>
      </Group>

      <Group style={{}}>
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%",
            background: "var(--lightPurple)"
          }}
        >
          <Header size={2}>Key Timeline Requirements for 1031 Exchanges</Header>
          <SplitView
            split="3:2"
            style={{
              alignItems: "center",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div>
              <Text
                block
                align="left"
                style={{ marginBottom: "1rem", fontSize: "1.5rem" }}
              >
                <strong>45-Day Identification Period:</strong> After selling
                your property, you have 45 days to identify potential
                replacement properties.
              </Text>
              <Text
                block
                align="left"
                style={{ marginBottom: "1rem", fontSize: "1.5rem" }}
              >
                <strong>180-Day Exchange Period:</strong> You must complete the
                purchase of your replacement property within 180 days of selling
                your original property.
              </Text>
              <Text block align="left" style={{ fontSize: "1.5rem" }}>
                Working with Chalet&apos;s experienced agents helps ensure you
                meet these crucial deadlines while finding the ideal STR
                investment property{" "}
                {LiveMarkets[marketPathParam]
                  ? `in the ${LiveMarkets[marketPathParam]} market`
                  : ""}
                .
              </Text>
            </div>
            <div style={{ padding: "2rem" }}>
              <Image
                src={`${process.env.PUBLIC_URL}/images/1031exchange-timeline.webp`}
                alt="1031 Exchange Timeline Requirements"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
          </SplitView>
        </Section>
      </Group>
      <Group id="section1">
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            maxWidth: "100%"
          }}
        >
          <Header size={2}>
            Why Choose Chalet for Your Short-Term Rental Investment{" "}
            {LiveMarkets[marketPathParam]
              ? `in ${LiveMarkets[marketPathParam]}`
              : ""}
            ?
          </Header>
          <SplitView
            split="2:3"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                display: "flex",
                // justifyContent: "left",
                // alignItems: "right",
                gridRowStart: mobileSize ? "1" : "inherit"
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/images/invest-with-experts.png`}
                alt="Buy your Airbnb rental with Chalet"
                style={{
                  borderRadius: "0.25rem",
                  maxWidth: mobileSize ? "100%" : "100%",
                  width: "100%"
                }}
                $mobileSize={mobileSize}
              />
            </div>
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <Text
                block
                align="left"
                style={{ fontSize: "1.5rem" }}
                // style={{ lineHeight: "2rem" }}
                // lineHeight="4.5rem"
              >
                At Chalet, we are committed to enabling everyone to create
                wealth with short-term rentals. Our team of real estate agents
                are experienced working with 1031 exchange buyers and understand
                the benefits of working with cash buyers.
              </Text>
              <Text block align="left" style={{ fontSize: "1.5rem" }}>
                They are also local experts who have a well-connected network of
                partners and 1031 exchange intermediaries{" "}
                {marketPathParam && "in"} {LiveMarkets[marketPathParam]}.
              </Text>
              {marketPathParam && (
                <Text
                  align="left"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "var(--quinary)",
                    fontSize: "1.5rem"
                  }}
                  onClick={handleInvestorGuideClick}
                >
                  Read our Investor Guide To Airbnb Rental In{" "}
                  {LiveMarkets[marketPathParam]}
                </Text>
              )}
            </div>
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group
        style={{
          background: "var(--sixth)",
          maxWidth: "100%"
        }}
      >
        <Section
          style={{
            boxShadow: "none",
            background: "none",
            maxWidth: "100%"
          }}
        >
          <Header size={2} color={Color.white}>
            Benefits of Working with Our Real Estate Agents
          </Header>
          <SplitView
            split="2:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto"
            }}
          >
            <div
              style={{
                gridRowStart: mobileSize ? "2" : "inherit"
              }}
            >
              <ul>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 300,
                    paddingBottom: "2rem"
                  }}
                >
                  Expert knowledge of the local short-term rental market
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 300,
                    paddingBottom: "2rem"
                  }}
                >
                  Experience working with 1031 exchange buyers
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 300,
                    paddingBottom: "2rem"
                  }}
                >
                  An understanding of the benefits of working with cash buyers
                </li>
                <li
                  style={{
                    color: "var(--white)",
                    fontSize: "1.5rem",
                    fontWeight: 300,
                    paddingBottom: "2rem"
                  }}
                >
                  A well-connected network of partners and 1031 exchange
                  intermediaries
                </li>
              </ul>
            </div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/1031-exchange.webp`}
              alt="Local STR Experts With a Proven Track Record"
              $mobileSize={mobileSize}
              style={{
                display: "flex",
                gridRowStart: mobileSize ? "1" : "inherit",
                justifyContent: "right",
                margin: "0 auto",
                maxWidth: mobileSize ? "100vw" : "100%"
              }}
            />
          </SplitView>
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>

      <Group
        style={{
          position: "relative"
        }}
      >
        <Section
          style={{
            maxWidth: "100%",
            background: "var(--primary)",

            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)", fontSize: "1.2rem", fontWeight: 200 }}
            size={2}
            color={Color.white}
            style={{ zIndex: 200, position: "relative" }}
          >
            Get Started Today
          </Header>
          <Image
            src={`${process.env.PUBLIC_URL}/images/vacation_home.webp`}
            alt="Airbnb Rental Investor Guides. Free Market Reports. Median Revenue, ADR, Occupacny rates"
            style={{
              padding: "0rem",
              position: "absolute",
              left: 0,
              top: 0,
              opacity: "0",
              // zIndex: "0",
              // right: 0,
              // borderRadius: "2rem",
              maxWidth: mobileSize ? "100%" : "100%",
              width: "100%"
            }}
            $mobileSize={mobileSize}
          />
          <div
            style={{
              gridRowStart: mobileSize ? "2" : "inherit",
              paddingBottom: mobileSize ? "inherit" : "0",
              paddingTop: mobileSize ? "inherit" : "0",
              position: "relative",
              zIndex: 1
            }}
          >
            <Text
              block
              align="center"
              color={Color.white}
              style={{ fontSize: "1.5rem" }}
            >
              Ready to maximize your returns with a short-term rental investment
              for your 1031 exchange?
            </Text>
            <Text
              block
              align="center"
              color={Color.white}
              style={{ fontSize: "1.2rem" }}
            >
              Contact us today to speak with one of our experienced real estate
              agents and learn how we can help you find the right property to
              meet your needs.
            </Text>
            <PopupButton
              id="G3A8zRSV"
              hidden={{
                market_of_interest:
                  LiveMarkets[marketPathParam] || "Not Specified"
              }}
              shareGaInstance="true"
              className="connect-with-realtor_popup_bottom"
              style={{
                // [mobileSize ? "marginTop" : "marginLeft"]: "1rem",
                // backgroundColor: "var(--primary)",
                background: "var(--linearGradient)",
                textTransform: "uppercase",
                display: "block",
                color: "var(--white)",
                padding: "1rem",
                border: 0,
                width: mobileSize ? "100%" : "initial",
                textDecoration: "none",
                // display: mobileSize ? "block" : "initial",
                fontSize: "1.2rem",
                cursor: "pointer",
                textAlign: "center",
                boxShadow: "0 8px 16px rgb(0 0 0 / 40%)",
                borderRadius: "0.25rem",
                // display: mobileSize ? "block" : "initial",
                margin: mobileSize ? "1rem auto" : "1rem auto"
              }}
            >
              Talk to STR Expert
            </PopupButton>
          </div>
        </Section>
      </Group>
      <Group>
        <Section
          style={{
            // maxWidth: "100%",
            background: "none",
            boxShadow: "none"
          }}
        >
          <Header
            // style={{ fontWeight: 400, color: "var(--white)" }}
            size={2}
            color={Color.primary}
          >
            Additional Resources for 1031 Exchange Buyers
          </Header>
          <Header
            size={4}
            style={{ paddingTop: 0, color: "var(--sixth)" }}
            color={Color.primary}
          >
            Looking to 1031 exchange into a short-term rental? Chalet offers a
            suite of resources to help you every step of the way.
          </Header>
          <List
            type="disclosure-closed"
            items={[
              <Link
                to="/blog/how-to-use-a-1031-exchange-to-transition-from-long-term-rentals-to-short-term-rentals/"
                target="_blank"
              >
                How to Use a 1031 Exchange to Transition from Long-Term Rentals
                to Short-Term Rentals?
              </Link>,
              <Link
                to="/blog/5-common-mistakes-to-avoid-in-1031-exchanges-and-short-term-rental-investments/"
                target="_blank"
              >
                5 Common Mistakes to Avoid in 1031 Exchanges and Short-Term
                Rental Investments
              </Link>,
              <Link to="/analytics/overview" target="_blank">
                Chalet Short-Term Rental Market Analytics
              </Link>,
              <Link to="/analytics/str-calculator" target="_blank">
                Airbnb Calculator
              </Link>
            ]}
          />
          <SectionFooter $mobileSize={mobileSize} />
        </Section>
      </Group>
      <Group>
        <Section
          style={{
            boxShadow: mobileSize ? "none" : "inherit",
            background: "var(--lightPurple)",
            maxWidth: "100%",
            padding: "4rem 1rem"
          }}
        >
          <Header size={2} style={{ marginBottom: "2rem" }}>
            Common Questions About 1031 Exchanges
          </Header>
          <SplitView
            split="1:1"
            style={{
              alignItems: "start",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto",
              gap: "3rem"
            }}
          >
            <div>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.7rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "0rem"
                }}
              >
                What qualifies as a like-kind property?
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.3rem",
                  lineHeight: "1.6",
                  marginBottom: "0rem"
                }}
              >
                For 1031 exchanges, most real estate properties held for
                investment or business purposes qualify as
                &quot;like-kind.&quot; This includes:
              </Text>
              <ul
                style={{
                  marginTop: "0",
                  marginLeft: "1.5rem",
                  fontSize: "1.1rem",
                  lineHeight: "1.8",
                  color: "var(--sixth)",
                  marginBottom: "2rem"
                }}
              >
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Single-family homes
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Multi-family properties
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Vacation rentals
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Commercial properties
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Vacant land
                </li>
              </ul>
            </div>
            <div>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.7rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "0rem"
                }}
              >
                What are the key rules to follow?
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.5rem",
                  lineHeight: "1.6",
                  marginBottom: "0rem"
                }}
              >
                To successfully complete a 1031 exchange, you must follow these
                essential rules:
              </Text>
              <ul
                style={{
                  marginLeft: "1.5rem",
                  fontSize: "1.1rem",
                  lineHeight: "1.8",
                  color: "var(--sixth)",
                  marginBottom: "0rem"
                }}
              >
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Use a qualified intermediary
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Identify replacement property within 45 days
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Complete the exchange within 180 days
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Reinvest all proceeds
                </li>
                <li style={{ fontSize: "1.5rem", fontWeight: 300 }}>
                  Maintain or increase debt liability
                </li>
              </ul>
            </div>
          </SplitView>
        </Section>
      </Group>

      <Group style={{ background: "var(--lightPink)" }}>
        <Section
          style={{
            boxShadow: "none",
            maxWidth: "100%",
            padding: "4rem 0"
          }}
        >
          <Header size={2} style={{ marginBottom: "2rem" }}>
            Why Choose Short-Term Rentals for Your 1031 Exchange?
          </Header>
          <SplitView
            split="1:1"
            style={{
              alignItems: "center",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "0 auto",
              gap: "3rem"
            }}
          >
            <div
              style={{
                background: "var(--white)",
                padding: "2rem",
                borderRadius: "0.5rem",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "1rem"
                }}
              >
                Higher Revenue Potential
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                  marginBottom: "1rem"
                }}
              >
                STRs typically generate 2-3x more revenue than traditional
                long-term rentals in the same market.
              </Text>
            </div>
            <div
              style={{
                background: "var(--white)",
                padding: "2rem",
                borderRadius: "0.5rem",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "1rem"
                }}
              >
                Property Appreciation
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                  marginBottom: "1rem"
                }}
              >
                Popular STR markets often see faster property value appreciation
                compared to traditional residential areas.
              </Text>
            </div>
          </SplitView>
          <SplitView
            split="1:1"
            style={{
              alignItems: "center",
              maxWidth: mobileSize ? "100%" : "85vw",
              margin: "2rem auto 0",
              gap: "3rem"
            }}
          >
            <div
              style={{
                background: "var(--white)",
                padding: "2rem",
                borderRadius: "0.5rem",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "1rem"
                }}
              >
                Personal Use Flexibility
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                  marginBottom: "1rem"
                }}
              >
                Enjoy your investment property for personal vacations while
                maintaining its status as an investment property.
              </Text>
            </div>
            <div
              style={{
                background: "var(--white)",
                padding: "2rem",
                borderRadius: "0.5rem",
                border: "1px solid var(--lightPurple)",
                boxShadow: "0px 1.4rem 6rem -2rem rgba(108,129,171,0.3)"
              }}
            >
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: 500,
                  color: "var(--primary)",
                  marginBottom: "1rem"
                }}
              >
                Market Resilience
              </Text>
              <Text
                block
                align="left"
                style={{
                  fontSize: "1.1rem",
                  lineHeight: "1.6",
                  marginBottom: "1rem"
                }}
              >
                STRs often perform well even during economic downturns, as
                travelers seek cost-effective alternatives to hotels.
              </Text>
            </div>
          </SplitView>
        </Section>
      </Group>
    </>
  );
};

export default Service1031Exchange;
