import styled, { css } from "styled-components";
import Flex from "./Flex";

const SectionFooter = styled(Flex)<{ $mobileSize: boolean }>`
  justify-content: center;
  width: 100%;
  padding: 0rem;

  ${(props) =>
    props.$mobileSize &&
    css`
      flex-direction: column;
      text-align: center;
    `};
`;

export default SectionFooter;
