import React from "react";
import { useParams } from "react-router-dom";
import RentalRegulations from "../RentalRegulations";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import AnalyticsHeader from "./components/AnalyticsHeader";

export default function AnalyticsRentalRegulations() {
  const { market } = useParams<any>();
  return (
    <AnalyticsWrapper style={{ minHeight: "100vh" }}>
      <AnalyticsHeader />
      <RentalRegulations hideHeader isAnalytics slug={market} />
    </AnalyticsWrapper>
  );
}
