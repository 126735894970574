import React from "react";
import Text from "../../../components/Text";
import Field from "../../../components/Field";
import Button from "../../../components/Button";
import Flex from "../../../components/Flex";
import LinkButton from "../../../components/LinkButton";
import { AppContext } from "../../../components/AppContext";

const ConfirmSignup = ({
  email,
  isLoading,
  verificationcode,
  onChange,
  setSubmitConfirmation,
  resendCode
}: any) => {
  const { mobileSize } = React.useContext(AppContext);
  return (
    <div
      style={{
        padding: "2rem",
        maxWidth: mobileSize ? "100%" : "600px",
        margin: "0 auto"
      }}
    >
      <div style={{ marginBottom: "1rem" }}>
        <Text>Check your email </Text>
        <Text weight={600}>{` ${email} `}</Text>
        <Text>for a verification code and provide it below</Text>
      </div>
      <Field
        name="verificationcode"
        placeholder="Verification Code"
        value={verificationcode}
        onChange={onChange}
      />
      <Button
        isLoading={isLoading}
        style={{ margin: "1rem 0 0.5rem", width: "100%" }}
        onClick={() => {
          setSubmitConfirmation(true);
        }}
      >
        Confirm Sign up
      </Button>
      <Flex style={{ justifyContent: "center" }}>
        <LinkButton onClick={resendCode} style={{ fontSize: "1rem" }}>
          Resend code?
        </LinkButton>
      </Flex>
    </div>
  );
};

export default ConfirmSignup;
